import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent, useCallback } from 'react';
import { PlusIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { ESelectedLibraryItemType, ILibraryAuthor } from '../../interfaces';
import { insertBook } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../domain/library-provider';
import { useLibraryStore } from '../../data/library-store';

enum AddBookMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface IAddBookProps {
    author: ILibraryAuthor;
    serie_id?: number;
}

const AddBook = ({ author, serie_id }: IAddBookProps) => {

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    const [addBookMode, setAddBookMode] = useState(AddBookMode.DEFAULT);

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);
    const [numberInSerie, setNumberInSerie] = useState<number|undefined>(undefined);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [addBookMode]);

    const startAddingBook = () => {
        setAddBookMode(AddBookMode.ADDING);
    };

    const stopAddingBook = () => {
        setAddBookMode(AddBookMode.DEFAULT);
        setTitle('');
        setTitleTouched(false);
        setNumberInSerie(undefined);
    };

    const save = useCallback(async () => {

        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const desc = descriptionEditorRef.current?.getMarkdown() || '';

        const response = await insertBook(
            author.author_id,
            title.trim(),
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
            desc.trim(),
            serie_id,
            numberInSerie,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert book error.');
            return;
        }

        stopAddingBook();

        setLibraryData(formatLibraryData(response || []));
    }, [
        author.author_id,
        setLibraryData,
        title,
        selectedLibraryItem?.id,
        selectedLibraryItem?.type,
        serie_id,
        numberInSerie,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                descriptionEditorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onNumberInSerieChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const number = Number((evt.target as HTMLInputElement).value) || undefined;
        setNumberInSerie(number);
    };

    return (
        <div className="my-4">
            {
                addBookMode === AddBookMode.DEFAULT &&
                <button
                    className={ `library__add-book flex items-center text-sm text-slate-400` }
                    onClick={ startAddingBook }
                    type="button">
                    <div className="mr-2"><PlusIcon /></div>
                    <div>Add Book</div>
                </button>
            }

            {
                addBookMode === AddBookMode.ADDING &&
                <div className="border border-slate-200 text-slate-800 bg-slate-50 rounded p-4 text-sm flex flex-col">

                    <label className="flex flex-col mb-4">
                        <div className="font-bold text-slate-500 mb-2">Book Title</div>
                        <input
                            ref={ titleRef }
                            onInput={ onTitleChange }
                            onKeyDown={ onKeyDown }
                            value={ title }
                            className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                            type="text"
                        />

                        {
                            !isTitleValid && <div className="text-red-700 text-xs mt-1">The book title is required.</div>
                        }
                    </label>

                    <label className="font-bold flex flex-col text-slate-500 mb-2">Description</label>
                    <div className="markdown-editor border rounded-lg mb-4 bg-white">
                        <MDXEditor
                            ref={ descriptionEditorRef }
                            markdown={ '' }
                            plugins={ getCompactMarkdownEditorPlugins() }
                        />
                    </div>

                    {
                        !!serie_id &&
                        <>
                            <label className="font-bold flex flex-col text-slate-500 mb-2">Number in serie</label>
                            <input
                                className="border rounded px-4 py-2 outline-stone-200"
                                type="number"
                                min={ 0 }
                                value={ numberInSerie }
                                onChange={ onNumberInSerieChange }
                            />
                        </>
                    }

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopAddingBook }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default AddBook;