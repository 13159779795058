import Popup from '../../../../../common/ui/popup';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';
import { ChangeEvent, useEffect, useState } from 'react';
import { ESelectedLibraryItemType, ILibraryTag } from '../../../interfaces';
import { getAllTags, updateBooksTags } from '../../../data/library-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import Preloader from '../../../../../common/ui/preloader';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { formatLibraryData } from '../../../domain/library-provider';
import { useLibraryStore } from '../../../data/library-store';

const AddTagPopup = () => {

    const addTagPopupShown = useLibraryStore(state => state.addTagPopupShown);
    const setAddTagPopupShown = useLibraryStore(state => state.setAddTagPopupShown);
    const addTagPopupBook = useLibraryStore(state => state.addTagPopupBook);
    const setAddTagPopupBook = useLibraryStore(state => state.setAddTagPopupBook);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const [tags, setTags] = useState<ILibraryTag[]>([]);
    const [loading, setLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<Set<number>>(new Set());
    const [newTag, setNewTag] = useState('');

    const close = () => {
        setAddTagPopupBook(null);
        setAddTagPopupShown(false);
        setNewTag('');
        setTags([]);
    };

    useEffect(() => {
        const bookTagsSet = new Set(addTagPopupBook?.tags?.map(tag => tag.tag_id) || []);
        setSelectedTags(bookTagsSet);
    }, [addTagPopupBook?.tags]);

    useEffect(() => {
        if(!addTagPopupShown) return;

        (async () => {
            setLoading(true);
            const response = await getAllTags();
            setLoading(false);

            if(!response) {
                showToast('Get tags error.');
            }

            setTags(response || []);
        })();
    }, [addTagPopupShown]);

    const updateHandler = async () => {
        if(!addTagPopupBook) {
            showToast('An error has occurred.');
            return;
        }

        setUpdateLoading(true);
        const response = await updateBooksTags(
            addTagPopupBook.book_id,
            selectedTags,
            newTag,
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
        );
        setUpdateLoading(false);

        if(!response) {
            showToast('Insert tag error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        close();
    };

    const onCheckboxChange = (isChecked: boolean, tag_id: number) => {
        const _selectedTags = new Set(selectedTags);
        if(isChecked) {
            _selectedTags.add(tag_id);
        }
        else{
            _selectedTags.delete(tag_id);
        }
        setSelectedTags(_selectedTags);
    };

    const onNewTagChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setNewTag(evt.target.value ?? '');
    };

    return (
        <>
            {
                addTagPopupShown &&
                <>
                    <Popup onClose={ close } width={ 650 } height={ 550 }>
                        <div className="p-4 flex flex-col overflow-auto text-left font-normal text-gray-900">

                            <div
                                className="text-2xl text-center my-4 text-slate-800"
                                dangerouslySetInnerHTML={{__html: markdown2Html(addTagPopupBook?.book_title || '') }}
                            />

                            {
                                loading &&
                                <Preloader size={ 30 } color={ '#1E293B' } />
                            }

                            {
                                !loading &&
                                <>
                                    <div className="grid grid-cols-1 md:grid-cols-2 border-t border-l border-slate-100">
                                        {
                                            tags.map(tag => {
                                                const booksHasTag = selectedTags.has(tag.tag_id);

                                                return (
                                                    <label key={ tag.tag_id } className={ `flex items-center border-b border-r border-slate-100 px-4 py-2 ${ booksHasTag ? 'text-sky-700' : '' }` }>
                                                        <input
                                                            type="checkbox"
                                                            checked={ booksHasTag }
                                                            className="mr-4"
                                                            onChange={ (evt) => {
                                                                onCheckboxChange(evt.target.checked, tag.tag_id);
                                                            }}
                                                        />
                                                        { tag.tag_title}
                                                    </label>
                                                )
                                            })
                                        }

                                        <label className="flex border-b border-r border-slate-100 px-4 py-2">
                                            <input
                                                type="text"
                                                className="border border border-slate-200 px-4 py-2 rounded w-full h-full bg-slate-100"
                                                placeholder="New Tag"
                                                onInput={ onNewTagChange }
                                                value={ newTag }
                                            />
                                        </label>
                                    </div>

                                    <div className="flex items-center justify-center text-sm mt-4">
                                        <button
                                            className="bg-stone-400 text-slate-100 rounded px-6 py-2 mr-2"
                                            onClick={ close }
                                            type="button">Cancel</button>

                                        {
                                            updateLoading &&
                                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                                        }

                                        {
                                            !updateLoading &&
                                            <button
                                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                                onClick={ updateHandler }
                                                type="button">Update</button>
                                        }
                                    </div>
                                </>
                            }

                        </div>
                    </Popup>
                </>
            }
        </>
    )
};

export default AddTagPopup;