import { ITodo } from '../../planner-interfaces';

interface ITodoIdealFinalResult {
    todo: ITodo;
}

const TodoIdealFinalResult = ({ todo }: ITodoIdealFinalResult) => {

    const hasNestedTodos = todo.nested?.length > 0;

    return (
        <div className={ `text-xs flex items-center my-1 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
            <span className="bg-emerald-100 mr-2 whitespace-nowrap rounded px-4 py-1 mb-1">{ todo.ideal_final_result }</span>
        </div>
    )
};

export default TodoIdealFinalResult;