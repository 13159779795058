import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType, ILibraryBookFile } from '../../../interfaces';
import { deleteBookFile } from '../../../data/library-data-service';
import { useLibraryStore } from '../../../data/library-store';

interface IDeleteBookFileButton {
    author_id: number;
    bookFile: ILibraryBookFile;
}

const DeleteBookFileButton = ({ author_id, bookFile }: IDeleteBookFileButton) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const deleteBookHandler = async () => {

        if(!confirm(`Are you sure you want to delete this file?`)) return;

        setLoading(true);
        const response = await deleteBookFile(
            bookFile.book_file_id,
            bookFile.book_file_ext,
            ESelectedLibraryItemType.AUTHOR,
            author_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete book file error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.AUTHOR,
            id: author_id,
        });
    };

    return (
        <div className="mr-2">
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Book File"
                    className="book-file__delete flex items-center justify-center"
                    onClick={ deleteBookHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </div>
    )
};

export default DeleteBookFileButton;