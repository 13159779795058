import { ILibraryBookData } from '../../interfaces';

interface ITopTitle {
    data: ILibraryBookData;
}

const TopTitle = ({ data }: ITopTitle) => {
    return (
        <a
            href={ `/library/a-${ data.author.author_id }` }
            className="text-center">
            {
                data.book &&
                <div className="underline text-sky-600">
                    { data.book.book_title }
                </div>
            }

            {
                data.author &&
                <div className="underline text-sky-600">
                    { data.author.author_first_name } { data.author.author_last_name }
                </div>
            }
        </a>
    )
};

export default TopTitle;