import { ILibraryBookFile } from '../../interfaces';
import { filesize } from 'filesize';
import { DownloadIcon } from '../../../../common/ui/icons';
import DeleteBookFileButton from './actions/book-file-delete-btn';

interface IBookFileProps {
    author_id: number;
    bookFile: ILibraryBookFile;
}

const Spacer = () => {
    return <span className="mx-2">&mdash;</span>;
};

const BookFile = ({ author_id, bookFile }: IBookFileProps) => {
    return (
        <div className="mb-2 flex items-center px-1 text-sm" data-file-id={ bookFile.book_file_id } data-book-file-name={ bookFile.book_file_url }>
            <a
                className="underline text-sky-600"
                target="_blank"
                rel="noreferrer"
                href={ bookFile.book_file_ext.endsWith('pdf') ? `/api/library/book-file/open?id=${ bookFile.book_file_id }.pdf` : `/library/read/${ bookFile.book_file_id }` }>
                { bookFile.book_file_ext.replace('.', '') }
            </a>

            <Spacer />

            <DeleteBookFileButton
                author_id={ author_id }
                bookFile={ bookFile }
            />

            <a
                href={ `/api/library/book-file/download?id=${ bookFile.book_file_url }` }
                target="_blank"
                rel="noreferrer"
                className="mr-4">
                <DownloadIcon
                    size={ 20 }
                />
            </a>

            <span className="mr-4">
                 ({ filesize(bookFile.archive_size) })
            </span>
        </div>
    )
};

export default BookFile;