import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { IFlashCard } from '../../flashcards-interfaces';
import { updateCard } from '../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import { CardDescriptionMode } from './description/card-description';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IEditCardProps {
    card: IFlashCard;
    setMode: (mode: CardDescriptionMode) => void;
}

const EditCardForm = ({ card, setMode }: IEditCardProps) => {

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [isLoading, setLoading] = useState(false);

    const frontEditorRef = useRef<MDXEditorMethods>(null);
    const backEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        frontEditorRef.current?.setMarkdown(card.card_front || '');
    }, [card.card_front]);

    useEffect(() => {
        backEditorRef.current?.setMarkdown(card.card_back || '');
    }, [card.card_back]);

    const setLongMode = useCallback(() => {
        setMode(CardDescriptionMode.DEFAULT_SHORT);
    }, [setMode]);

    const save = useCallback(async () => {
        setLoading(true);

        const card_front = frontEditorRef.current?.getMarkdown() || '';
        const card_back = backEditorRef.current?.getMarkdown() || '';

        const response = await updateCard(
            flashCardsListType,
            card.card_id,
            card.card_name,
            card_front.trim(),
            card_back.trim(),
            card.card_interval,
            card.deck_id,
        );

        setLoading(false);

        if(!response) {
            showToast('Update card error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, card.deck_id));
    }, [
        card.card_id,
        card.card_interval,
        card.card_name,
        card.deck_id,
        flashCardsListType,
        setFlashCardsTree,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                setLongMode();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save, setLongMode]);

    return (
        <div className={ `flex flex-col` }>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Edit Front</label>

            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ frontEditorRef }
                    markdown={ '' }
                    plugins={ getMarkdownEditorPlugins() }
                />
            </div>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Edit Back</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ backEditorRef }
                    markdown={ '' }
                    plugins={ getMarkdownEditorPlugins() }
                />
            </div>

           <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setLongMode }
                    type="button">Close</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
           </div>
        </div>
    )
};

export default EditCardForm;