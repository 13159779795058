import { ChangeEvent, useState } from 'react';
import { ITodo } from '../../../planner-interfaces';
import { completeTodo } from '../../../data/planner-data-service';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ICompleteRadioProps {
    todo: ITodo;
}

const CompleteCheckbox = (props: ICompleteRadioProps) => {

    const { todo } = props;

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [isLoading, setLoading] = useState(false);

    const onChange = async (evt: ChangeEvent<HTMLInputElement>) => {

        const isCompleted = (evt.target as HTMLInputElement).checked ? 1 : 0;

        setLoading(true);
        const response = await completeTodo(todo.todo_id, isCompleted, todo.todo_type);
        setLoading(false);

        if(!response) {
            showToast('Complete task error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <>
            {
                isLoading &&
                <div className="mr-3"><PreloaderIcon size={ 15 } color={ '#717985' } /></div>
            }

            {
                !isLoading &&
                <input
                    type="checkbox"
                    className={ `todo-complete-check mr-3 accent-stone-500` }
                    checked={ todo.is_completed === 1 }
                    onChange={ onChange }
                />
            }
        </>
    )
};

export default CompleteCheckbox;