import { DragEvent } from 'react';
import { DragIcon } from '../../../../../common/ui/icons';
import { ISection } from '../../../planner-interfaces';

interface IDragSectionButtonProps {
    section: ISection;
}

const DRAGGING_CLASS = 'section-dragging';

const DragSectionButton = ({ section }: IDragSectionButtonProps) => {

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', section.section_id.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(DRAGGING_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(DRAGGING_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };


    return (
        <button
            type="button"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            title="Drag Section"
            className="section__drag-btn cursor-move flex items-center justify-center mx-1 bg-purple-100 rounded action-btn">
            <DragIcon color={ '#6B22A8' } />
        </button>
    )
};

export default DragSectionButton;