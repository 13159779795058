import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { ILibraryFolder } from '../../interfaces';
import EditFolder from './folder-edit';

export const ADD_NEW_FOLDER_DESCRIPTION_EVENT = 'add-new-folder-desc';

export enum FolderDescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IFolderDescriptionProps {
    folder: ILibraryFolder;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const FolderDescription = ({ folder }: IFolderDescriptionProps) => {

    const [mode, setMode] = useState<FolderDescriptionMode>(FolderDescriptionMode.DEFAULT);

    const descExists = isDescExists(folder.folder_desc);

    const parsedDesc = descExists ? markdown2Html(folder.folder_desc) : '';

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setDefaultMode = () => {
        setMode(FolderDescriptionMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(FolderDescriptionMode.EDIT);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const folder_id = Number(evt?.detail) || 0;
            if(folder_id !== folder.folder_id) return;
            setMode(FolderDescriptionMode.EDIT);
        };

        document.addEventListener(ADD_NEW_FOLDER_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_FOLDER_DESCRIPTION_EVENT, handler);
        };
    }, [folder.folder_id]);

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setDefaultMode();
        }
    };

    return (
        <>
            {
                (descExists && mode === FolderDescriptionMode.DEFAULT) &&
                <div className="flex flex-col">
                    <div
                        ref={ contentRef }
                        tabIndex={ 0 }
                        className="text-xs overflow-auto max-h-[500px] rounded my-1 outline-none"
                        onClick={ onClick }
                        onKeyDown={ onKeyDown }>
                        <div
                            className="markdown whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{__html: parsedDesc }}
                        />
                    </div>
                </div>
            }

            {
                (mode === FolderDescriptionMode.EDIT) &&
                <EditFolder
                    folder={ folder }
                    setMode={ setDefaultMode }
                />
            }
        </>
    )
};

export default FolderDescription;