import { TagIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { ILibraryBook } from '../../../interfaces';
import { useLibraryStore } from '../../../data/library-store';

interface IAddTagActionButton {
    book: ILibraryBook;
}

const AddTagActionButton = ({ book }: IAddTagActionButton) => {

    const setAddTagPopupShown = useLibraryStore(state => state.setAddTagPopupShown);
    const setAddTagPopupBook = useLibraryStore(state => state.setAddTagPopupBook);

    const onClick = async () => {
        setAddTagPopupBook(book);
        setAddTagPopupShown(true);

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Add Tag"
            className="book__add-tag flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ onClick }
            type="button">
            <TagIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default AddTagActionButton;