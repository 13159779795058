import { useState } from 'react';
import { deleteTodo } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface IDeleteTodoProps {
    todo: ITodo;
}

const DeleteTodoButton = (props: IDeleteTodoProps) => {

    const { todo } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const deleteTodoHandler = async () => {

        if(!confirm(`Are you sure you want to delete task "${ todo.todo_title }" and all its nested tasks?`)) return;

        setLoading(true);
        const response = await deleteTodo(
            todo.todo_id,
            todo.todo_type,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete task error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Task"
                    className="todo__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteTodoHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteTodoButton;