import Actions, { sendCloseActionsEvent } from '../../../../common/ui/actions';
import DeleteTodoVariantBtn from '../actions/delete-todo-variant-btn';
import { ITodoVariant } from '../../../planner/planner-interfaces';
import { useState } from 'react';
import EditVariantForm from '../actions/edit-variant-form';
import { EditIcon } from '../../../../common/ui/icons';
import VariantTitle from './variant-title';

interface ITodoVariantProps {
    variant: ITodoVariant;
    isLast: boolean;
}

enum TodoVariantMode {
    Default = 0,
    Edit = 1,
}

const TodoVariant = ({ variant, isLast }: ITodoVariantProps) => {

    const [mode, setMode] = useState<TodoVariantMode>(TodoVariantMode.Default);

    const startEdit = () => {
        setMode(TodoVariantMode.Edit);
        sendCloseActionsEvent();
    };

    const stopEdit = () => {
        setMode(TodoVariantMode.Default);
    };

    return (
        <>
            <div className={ `pb-1 mb-1 flex flex-col ${ (isLast || (mode === TodoVariantMode.Edit)) ? '' : 'border-b border-slate-100' }` }>

                <div className="flex items-center">
                    <VariantTitle variant={ variant } />

                    <Actions classes="todo-variant__actions">
                        <DeleteTodoVariantBtn todo_variant_id={ variant.todo_variant_id } />

                        <button
                            onClick={ startEdit }
                            className="flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                            type="button">
                            <EditIcon color={ '#6e7781' } size={ 20 } />
                        </button>
                    </Actions>
                </div>

                {
                    variant.todo_variant_desc &&
                    <div className="text-sm mb-1">
                        { variant.todo_variant_desc }
                    </div>
                }
            </div>

            {
                (mode === TodoVariantMode.Edit) &&
                <EditVariantForm
                    todo_variant={ variant }
                    cancel={ stopEdit }
                />
            }
        </>
    )
};

export default TodoVariant;