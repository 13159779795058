import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { useParams } from 'react-router-dom';
import { MouseEvent, useEffect } from 'react';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import { getDescartesSquareData } from './data/descartes-square-data-service';
import { showToast } from '../../common/domain/toast-provider';
import { formatDescartesSquareData } from './domain/descartes-square-provider';
import SquareView from './ui/square/square-view';
import DescartesSquareTitle from './ui/descartes-square-title';
import DescartesSquareTopBar from './ui/descartes-square-top-bar';
import { getTextFromMarkdown } from '../../common/markdown/markdown-provider';
import { useDescartesSquareStore } from './data/descartes-square-store';

const DescartesSquareHome = () => {

    const { loading } = useAuthStatus();

    const { todo_id } = useParams();
    const _todo_id = Number(todo_id) || 0;

    const descartesSquare = useDescartesSquareStore(store => store.descartesSquare);
    const setDescartesSquare = useDescartesSquareStore(store => store.setDescartesSquare);
    const setTodoParentId = useDescartesSquareStore(store => store.setTodoParentId);

    useEffect(() => {
        (async () => {
            setTodoParentId(_todo_id);
            const response = await getDescartesSquareData(_todo_id);

            if(!response?.isValid) {
                showToast('Get descartes square data error.');
            }

            setDescartesSquare(formatDescartesSquareData(response));
        })();
    }, [_todo_id, setDescartesSquare, setTodoParentId]);

    useEffect(() => {
        document.title = getTextFromMarkdown(descartesSquare?.parentTodo?.todo_title) || 'Descartes Square | Productivity';
    }, [descartesSquare?.parentTodo?.todo_title]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading && descartesSquare && _todo_id &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">

                        <DescartesSquareTopBar />

                        <div className="overflow-auto px-6 pt-4 pb-24 md:pt-10 bg-slate-50 min-h-screen">

                            <button
                                type="button"
                                onClick={ goBack }
                                className="mb-6 md:hidden">
                                &laquo; Back
                            </button>

                            <div className="xl:w-[1100px] max-w-full mx-auto">

                                <DescartesSquareTitle />
                                <SquareView />

                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default DescartesSquareHome;