import { useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import { FlashCardsListView, FlashCardsStudyMode, IDeck } from '../../flashcards-interfaces';
import { updateDeckName } from '../../data/flashcards-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import Actions from '../../../../common/ui/actions';
import DeleteDeckButton from './actions/deck-delete-btn';
import DeckEditButton from './actions/deck-edit-btn';
import StartStudyBtn from './actions/start-study-btn';
import ProgressInfo from '../study/progress-info';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

export enum DeckTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IDeckTitleProps {
    deck: IDeck;
}

const DeckTitle = ({ deck }: IDeckTitleProps) => {

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const flashCardsStudyMode = useFlashCardsStore(state => state.flashCardsStudyMode);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<DeckTitleMode>(DeckTitleMode.DEFAULT);

    const startEdit = () => {
        setMode(DeckTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(DeckTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateDeckName(flashCardsListType, deck.deck_id, updatedText.trim());
        setUpdateTitleLoading(false);

        if(!response?.isValid) {
            showToast('Update deck name error.');
            return;
        }

        cancelEdit();

        setFlashCardsTree(formatFlashCardsTree(response, deck.deck_id));
    };

    const prefix = flashCardsListType === FlashCardsListView.StudyView ? '📚 Study: ' : '';
    const showProgress = flashCardsListType === FlashCardsListView.StudyView &&
                         flashCardsStudyMode !== FlashCardsStudyMode.StudyEnd;

    return (
        <h1 className={ `deck__title flex items-center text-xl xl:text-3xl mt-6 mb-2 pb-2 ${ mode === DeckTitleMode.DEFAULT ? 'border-b' : '' }` }>
            {
                mode === DeckTitleMode.DEFAULT &&
                <>
                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(prefix + (deck?.deck_name ?? '')) }} />

                        {
                            !showProgress &&
                            <span className="text-sm font-normal ml-4">{ deck.cards_count }</span>
                        }

                        {
                            showProgress &&
                            <ProgressInfo />
                        }

                    </button>

                    {
                        (flashCardsListType === FlashCardsListView.DeckView) &&
                        <StartStudyBtn
                            deck_id={ deck.deck_id }
                            classes="mx-4 hidden md:flex"
                        />
                    }

                    <Actions classes="deck__actions" gridSize={ 2 }>
                        <DeckEditButton
                            deck_id={ deck.deck_id }
                        />

                        <DeleteDeckButton
                            deck_id={ deck.deck_id }
                            deck_name={ deck.deck_name }
                        />
                    </Actions>
                </>
            }

            {
                mode === DeckTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ deck?.deck_name ?? '' }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h1>
    )
};

export default DeckTitle;