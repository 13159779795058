import { deleteJson, get, postJson, putJson } from '../../../common/data/common-data-service';
import { FlashCardsListView, FlashCardsPerformance, IFlashCardsResponse } from '../flashcards-interfaces';
import { INITIAL_EASE_FACTOR } from '../domain/flashcards-provider';

// --------------- GETTERS -----------------------

export const getFlashCardsData = async (listType: FlashCardsListView, deck_id?: number) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/get-data`, {
        listType,
        deck_id,
    });
};

export const getFlashCardsLearningData = async (listType: FlashCardsListView, deck_id: number) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/get-learning-data`, {
        listType,
        deck_id,
    });
};

// --------------- DECKS -----------------------

export const insertDeck = async (
    listType: FlashCardsListView,
    deck_name: string,
    deck_desc?: string,
    folder_id?: number
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/deck/insert`, {
        listType,
        deck_name,
        deck_desc: deck_desc ?? '',
        folder_id,
    });
};

export const deleteDeck = async (listType: FlashCardsListView, deck_id: number) : Promise<IFlashCardsResponse> => {
    return await deleteJson(`/api/flashcards/deck/delete`, {
        listType,
        deck_id,
    });
};

export const updateDeck = async (listType: FlashCardsListView, deck_id: number, deck_name: string, deck_desc?: string) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/deck/update`, {
        listType,
        deck_id,
        deck_name,
        deck_desc: deck_desc ?? '',
    });
};

export const updateDeckName = async (listType: FlashCardsListView, deck_id: number, deck_name: string) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/deck/update-name`, {
        listType,
        deck_id,
        deck_name,
    });
};

// --------------- CARDS -----------------------

export const insertCard = async (
    listType: FlashCardsListView,
    card_name: string,
    card_front: string,
    card_back: string,
    deck_id: number
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/card/insert`, {
        listType,
        card_name,
        card_front,
        card_back,
        deck_id,
        ease_factor: INITIAL_EASE_FACTOR,
    });
};

export const deleteCard = async (listType: FlashCardsListView, card_id: number, deck_id: number) : Promise<IFlashCardsResponse> => {
    return await deleteJson(`/api/flashcards/card/delete`, {
        listType,
        card_id,
        deck_id,
    });
};

export const updateCard = async (
    listType: FlashCardsListView,
    card_id: number,
    card_name: string,
    card_front: string,
    card_back: string,
    card_interval: number,
    deck_id: number
) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/card/update`, {
        listType,
        card_id,
        card_name,
        card_front,
        card_back,
        card_interval,
        deck_id,
    });
};

export const updateCardName = async (listType: FlashCardsListView, card_id: number, card_name: string, deck_id: number) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/card/update-title`, {
        listType,
        card_id,
        card_name,
        deck_id,
    });
};

export const setEaseFactor = async (listType: FlashCardsListView, card_id: number, ease_factor: number) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/card/set-ease-factor`, {
        listType,
        card_id,
        ease_factor,
    });
};

export const updateCardDeck = async (listType: FlashCardsListView, card_id: number, new_deck_id: number) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/card/update-deck`, {
        listType,
        card_id,
        new_deck_id,
    });
};

export const duplicateCard = async (
    initial_card_id: number,
    deck_id: number,
    listType: FlashCardsListView
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/card/duplicate`, {
        initial_card_id,
        ease_factor: INITIAL_EASE_FACTOR,
        deck_id,
        listType,
    });
};

export const dragCard = async (
    sourceCardId: number,
    targetCardId: number,
    isBefore: boolean,
    deck_id: number,
    listType: FlashCardsListView
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/card/drag`, {
        sourceCardId,
        targetCardId,
        isBefore,
        deck_id,
        listType,
    });
};

export const moveCardToAnotherDeck = async (
    deck_id: number,
    new_deck_id: number,
    card_id: number,
    listType: FlashCardsListView
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/card/drag-card-to-another-deck`, {
        deck_id,
        new_deck_id,
        card_id,
        listType,
    });
};

// --------------- REVIEWS -----------------------

export const insertReview = async (
    listType: FlashCardsListView,
    review_date: number,
    performance: FlashCardsPerformance,
    next_review_date: number,
    card_id: number
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/review/insert`, {
        listType,
        review_date,
        performance,
        next_review_date,
        card_id,
    });
};

export const deleteReview = async (listType: FlashCardsListView, review_id: number) : Promise<IFlashCardsResponse> => {
    return await deleteJson(`/api/flashcards/review/delete`, {
        listType,
        review_id,
    });
};

export const updateReview = async (
    listType: FlashCardsListView,
    review_id: number,
    review_date: number,
    performance: FlashCardsPerformance,
    next_review_date: number
) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/review/update`, {
        listType,
        review_id,
        review_date,
        performance,
        next_review_date,
    });
};

// --------------- FOLDER -----------------------

export const insertFolder = async (
    listType: FlashCardsListView,
    deck_id: number,
    folder_title: string,
    folder_desc: string,
    parent_folder_id?: null|number
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/folder/insert`, {
        listType,
        deck_id,
        folder_title,
        folder_desc,
        parent_folder_id,
    });
};

export const deleteFolder = async (listType: FlashCardsListView, folder_id: number, deck_id: number) : Promise<IFlashCardsResponse> => {
    return await deleteJson(`/api/flashcards/folder/delete`, {
        listType,
        folder_id,
        deck_id,
    });
};

export const updateFolder = async (
    listType: FlashCardsListView,
    folder_id: number,
    folder_desc: string
) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/folder/update`, {
        listType,
        folder_id,
        folder_desc,
    });
};

export const updateFolderTitle = async (
    listType: FlashCardsListView,
    folder_id: number,
    folder_title: string,
    deck_id: number
) : Promise<IFlashCardsResponse> => {
    return await putJson(`/api/flashcards/folder/update-title`, {
        listType,
        folder_id,
        folder_title,
        deck_id,
    });
};

export const dragDeckToAnotherFolder = async (
    listType: FlashCardsListView,
    folder_id: number,
    deck_id: number
) => {
    return await putJson(`/api/flashcards/folder/drag-deck-to-folder`, {
        listType,
        folder_id,
        deck_id,
    });
};

// --------------- MIXED -----------------------

export const updateCardStatus = async (
    listType: FlashCardsListView,
    review_date: number,
    performance: FlashCardsPerformance,
    next_review_date: number,
    card_id: number,
    new_ease_factor: number
) : Promise<IFlashCardsResponse> => {
    return await postJson(`/api/flashcards/update-card-status`, {
        listType,
        review_date,
        performance,
        next_review_date,
        card_id,
        new_ease_factor,
    });
};

// ------ SEARCH ----------------------------

export const searchFlashCards = async (term: string) => {
    return await get(`/api/flashcards/search?term=${ term }`);
};