import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { handleWiziwig } from '../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { showToast } from '../../../../common/domain/toast-provider';
import { insertTodoVariant } from '../../data/descartes-square-data-service';
import { formatDescartesSquareData } from '../../domain/descartes-square-provider';
import { DescartesSquareType } from '../../descartes-square-interfaces';
import { useDescartesSquareStore } from '../../data/descartes-square-store';

interface IAddVariantForm {
    descartes_square_type: DescartesSquareType;
    cancel: () => void;
}

const AddVariantForm = ({ descartes_square_type, cancel }: IAddVariantForm) => {

    const todo_parent_id = useDescartesSquareStore(store => store.todo_parent_id);
    const setDescartesSquare = useDescartesSquareStore(store => store.setDescartesSquare);

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, []);

    const save = useCallback(async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';

        const response = await insertTodoVariant(
            todo_parent_id,
            title.trim(),
            desc.trim(),
            descartes_square_type,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert variant error.');
            return;
        }

        cancel();

        setDescartesSquare(formatDescartesSquareData(response));
    }, [
        title, todo_parent_id,
        setDescartesSquare, descartes_square_type,
        cancel,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            cancel();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div className="border border-slate-200 text-slate-800 rounded p-4 text-sm flex flex-col bg-stone-50 mb-2">

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-500 mb-2">Variant Title</div>
                <input
                    ref={ titleRef }
                    onInput={ onTitleChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isTitleValid && <div className="text-red-700 text-xs mt-1">The title is required.</div>
                }
            </label>

            <label className="font-bold flex flex-col text-slate-500 mb-2">Description</label>
            <div className="markdown-editor border rounded-lg mb-4 bg-white">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ cancel }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default AddVariantForm;