import { deleteTag } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { formatTagsData } from '../../../domain/tags-provider';
import { usePlannerStore } from '../../../data/planner-store';
import { useState } from 'react';

interface IDeleteTagButton {
    tag: string;
}

const DeleteTagButton = ({ tag }: IDeleteTagButton) => {

    const [isLoading, setLoading] = useState(false);

    const setTagsData = usePlannerStore(state => state.setTagsData);

    const deleteTagHandler = async () => {

        if(!confirm(`Are you sure you want to delete tag "${ tag }"?`)) return;

        setLoading(true);
        const response = await deleteTag(tag);
        setLoading(false);

        if(!response) {
            showToast('Delete tag error.');
            return;
        }

        setTagsData(formatTagsData(response));
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Tag"
                    className="todo__tag flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteTagHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteTagButton;