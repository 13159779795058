import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent, useCallback } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { getMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { insertCard } from '../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import { handleWiziwig } from '../../../planner/domain/wiziwig-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IAddCardFormProps {
    deck_id: number;
    cancel: () => void;
}

const AddCardForm = (props: IAddCardFormProps) => {

    const { cancel, deck_id } = props;

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [isLoading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;

    const nameRef = useRef<HTMLInputElement>(null);
    const frontEditorRef = useRef<MDXEditorMethods>(null);
    const backEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    const save = useCallback(async () => {
        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        setLoading(true);

        const cardFront = frontEditorRef.current?.getMarkdown() || '';
        const cardBack = backEditorRef.current?.getMarkdown() || '';

        const response = await insertCard(
            flashCardsListType,
            name,
            cardFront,
            cardBack,
            deck_id
        );

        setLoading(false);

        if(!response) {
            showToast('Insert task error.');
            return;
        }

        cancel();

        setFlashCardsTree(formatFlashCardsTree(response, deck_id));
    }, [
        cancel, deck_id, name,
        flashCardsListType,
        setFlashCardsTree,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                // frontEditorRef.current?.setMarkdown('');
                // backEditorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            cancel();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div className="border border-slate-200 text-slate-800 rounded p-4 text-sm flex flex-col">

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Card Name</div>
                <input
                    ref={ nameRef }
                    onInput={ onNameChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The card name is required.</div>
                }
            </label>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Card Front</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ frontEditorRef }
                    markdown={ '' }
                    plugins={ getMarkdownEditorPlugins() }
                />
            </div>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Card Back</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ backEditorRef }
                    markdown={ '' }
                    plugins={ getMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ cancel }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default AddCardForm;