import Preloader from '../../../common/ui/preloader';
import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import Menu from '../../../menu/menu';
import { useAuthStatus } from '../../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect } from 'react';
import LibraryMenu from './menu/library-menu';
import { ESelectedLibraryItemType } from '../interfaces';
import FolderView from './folders/folder-view';
import { useParams } from 'react-router-dom';
import EmptyScreen from './empty-screen';
import AuthorView from './authors/author-view';
import LibrarySearchView from './search/search-view';
import { useLibraryStore } from '../data/library-store';

const LibraryHome = () => {

    const { loading } = useAuthStatus();

    const { path } = useParams();

    const selectedLibraryItem = useLibraryStore(store => store.selectedLibraryItem);
    const setSelectedLibraryItem = useLibraryStore(state => state.setSelectedLibraryItem);

    useEffect(() => {
        if(!path) return;

        const _path = path || '';

        if(_path === 'search') {
            setSelectedLibraryItem({
                type: ESelectedLibraryItemType.SEARCH,
                id: 0,
            });
            return;
        }

        if(_path.startsWith('f-')) {
            const folder_id = Number(_path.replace('f-', '')) || 0;
            if(!folder_id) return;

            setSelectedLibraryItem({
                type: ESelectedLibraryItemType.FOLDER,
                id: folder_id,
            });
            return;
        }

        if(_path.startsWith('a-')) {
            const author_id = Number(_path.replace('a-', '')) || 0;
            if(!author_id) return;

            setSelectedLibraryItem({
                type: ESelectedLibraryItemType.AUTHOR,
                id: author_id,
            });
        }

    }, [path, setSelectedLibraryItem]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <LibraryMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 py-10 flex-1 flex flex-col">

                            {
                                (!selectedLibraryItem || selectedLibraryItem?.type === ESelectedLibraryItemType.ROOT) &&
                                <EmptyScreen />
                            }

                            {
                                selectedLibraryItem?.type === ESelectedLibraryItemType.FOLDER &&
                                <FolderView />
                            }

                            {
                                selectedLibraryItem?.type === ESelectedLibraryItemType.AUTHOR &&
                                <AuthorView />
                            }

                            {
                                selectedLibraryItem?.type === ESelectedLibraryItemType.SEARCH &&
                                <LibrarySearchView />
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default LibraryHome;