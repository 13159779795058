import Cell from './cell';
import { PROJECT_TITLE_SIZE, TODO_TITLE_SIZE } from '../domain/blocks-overview-provider';
import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { useBlocksOverviewStore } from '../data/blocks-overview-store';

const Rows = () => {

    const blocksOverviewData = useBlocksOverviewStore(store => store.blocksOverviewData);

    return (
        <>
            {
                blocksOverviewData.projects.map(projectBox => {
                    return (
                        <div
                            className="mb-2 flex"
                            key={ `project-${ projectBox.project.project_id }` }>

                            <div
                                className="text-xs pr-4 border-t border-l border-b border-slate-200 flex items-center text-slate-800 px-4 py-2"
                                dangerouslySetInnerHTML={{__html: markdown2Html(projectBox.project.project_title)
                                }}
                                style={{
                                    width: PROJECT_TITLE_SIZE,
                                }}
                            />

                            <div className="flex flex-col">
                                {
                                    projectBox.rows.map((row, ri) => {

                                        const isFirstRow = ri === 0;
                                        const isLastRow = ri === projectBox.rows.length - 1;
                                        const className = `text-xs pr-4 border-t border-l ${ isLastRow ? 'border-b' : '' } border-slate-200 flex items-center bg-slate-100 text-slate-800 px-4 py-2`;

                                        return (
                                            <div
                                                className="flex"
                                                key={ `row-${ row.todo.todo_id }` }>

                                                <div
                                                    className={ className }
                                                    dangerouslySetInnerHTML={{__html: markdown2Html(row.todo.todo_title) }}
                                                    style={{
                                                        width: TODO_TITLE_SIZE,
                                                    }}
                                                />

                                                {
                                                    row.cells.map((cell, i) => {

                                                        return (
                                                            <Cell
                                                                cell={ cell }
                                                                key={ `cell-${ cell.index }` }
                                                                isLastCell={ i === row.cells.length - 1 }
                                                                isFirstRow={ isFirstRow }
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
};

export default Rows;