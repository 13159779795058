import { ILibraryBook } from '../../../interfaces';
import BookTag from './book-tag';

interface IBookTags {
    book: ILibraryBook;
}

const BookTags = ({ book }: IBookTags) => {

    return (
        <>
            {
                book.tags?.length > 0 &&
                <div className="flex items-center flex-wrap text-sm text-slate-400">
                    {
                        book.tags.map(tag => {
                            return (
                                <BookTag
                                    key={ tag.tag_id }
                                    tag={ tag }
                                    book_id={ book.book_id }
                                />
                            )
                        })
                    }
                </div>
            }
        </>
    )
};

export default BookTags;