import { useEffect, useState } from 'react';
import { IDeck } from '../../flashcards-interfaces';
import DeckFullDescription from './deck-full-description';
import EditDeck from './deck-edit';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';

export const ADD_NEW_DECK_DESCRIPTION_EVENT = 'add-new-deck-desc';

export enum DeckDescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IDeckDescriptionProps {
    deck: IDeck;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const DeckDescription = (props: IDeckDescriptionProps) => {

    const { deck } = props;

    const [mode, setMode] = useState<DeckDescriptionMode>(DeckDescriptionMode.DEFAULT);

    const descExists = isDescExists(deck.deck_desc);

    const parsedDesc = descExists ? markdown2Html(deck.deck_desc) : '';

    const updateMode = (_mode: DeckDescriptionMode) => {
        setMode(_mode);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const deck_id = Number(evt?.detail) || 0;
            if(deck_id !== deck.deck_id) return;
            setMode(DeckDescriptionMode.EDIT);
        };

        document.addEventListener(ADD_NEW_DECK_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_DECK_DESCRIPTION_EVENT, handler);
        };
    }, [deck.deck_id]);

    return (
        <>
            {
                (descExists && mode === DeckDescriptionMode.DEFAULT) &&
                <DeckFullDescription
                    parsedDesc={ parsedDesc as string }
                    setMode={ updateMode }
                />
            }

            {
                (mode === DeckDescriptionMode.EDIT) &&
                <EditDeck
                    deck={ deck }
                    setMode={ updateMode }
                />
            }
        </>
    )
};

export default DeckDescription;