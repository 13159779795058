import { ITodoVariant } from '../../../planner/planner-interfaces';
import { useCallback, useState } from 'react';
import { updateTodoVariantTitle } from '../../data/descartes-square-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatDescartesSquareData } from '../../domain/descartes-square-provider';
import TextBox from '../../../../common/ui/forms/text-box';
import { useDescartesSquareStore } from '../../data/descartes-square-store';

interface IVariantTitle {
    variant: ITodoVariant;
}

export enum VariantTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}


const VariantTitle = ({ variant }: IVariantTitle) => {

    const setDescartesSquare = useDescartesSquareStore(store => store.setDescartesSquare);
    const todo_parent_id = useDescartesSquareStore(store => store.todo_parent_id);

    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<VariantTitleMode>(VariantTitleMode.DEFAULT);

    const startEdit = () => {
        setMode(VariantTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(VariantTitleMode.DEFAULT);
    };

    const save = useCallback(async (updatedText: string) => {

        setLoading(true);

        const response = await updateTodoVariantTitle(
            todo_parent_id,
            updatedText.trim(),
            variant.todo_variant_id,
        );

        setLoading(false);

        if(!response) {
            showToast('Update variant title error.');
            return;
        }

        cancelEdit();

        setDescartesSquare(formatDescartesSquareData(response));
    }, [
        setDescartesSquare, todo_parent_id,
        variant.todo_variant_id,
    ]);

    return (
        <div className="w-full">
            {
                (mode === VariantTitleMode.DEFAULT) &&
                <button
                    className="text-left leading-normal"
                    type="button"
                    onClick={ startEdit }>
                    { variant.todo_variant_title }
                </button>
            }

            {
                (mode === VariantTitleMode.EDIT) &&
                <TextBox
                    isMultiLine={ false }
                    text={ variant.todo_variant_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ loading }
                />
            }
        </div>
    )
};

export default VariantTitle;