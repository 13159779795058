import { ITodo } from '../../planner-interfaces';
import TrackHabitBtn from './actions/track-habit-btn';
import OpenHabitChartBtn from './actions/open-habit-chart-btn';
import { HabitTrackerIntervalType, shouldShowHabitSection } from '../../../habit-tracker/domain/habit-tracker-provider';
import { deserializeWeekDaysSet, getWeekDayName } from '../../../habit-tracker/domain/week-days-provider';

interface ITodoHabitActions {
    todo: ITodo;
}

const TodoHabitActions = ({ todo }: ITodoHabitActions) => {

    const weekDays = [...deserializeWeekDaysSet(todo.habit_week_days)].sort((day1, day2) => day1 - day2);

    return (
        <>
            {
                shouldShowHabitSection(todo) &&
                <>
                    {
                        todo.habit_interval === HabitTrackerIntervalType.Week &&
                        weekDays.length > 0 &&
                        <div className="flex items-center mr-1 text-xs italic">
                            {
                                weekDays.map(day => {
                                    return (
                                        <div
                                            className="mr-1"
                                            key={ day }>
                                            { getWeekDayName(day) }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    <OpenHabitChartBtn todo={ todo } />
                    <TrackHabitBtn todo={ todo } />
                </>
            }
        </>
    )
};

export default TodoHabitActions;