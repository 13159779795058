import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { updateSection } from '../../data/planner-data-service';
import { ISection } from '../../planner-interfaces';
import { SectionDescriptionMode } from './description/section-description';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { usePlannerStore } from '../../data/planner-store';

interface IEditSectionProps {
    section: ISection;
    setMode: (mode: SectionDescriptionMode) => void;
}

const EditSection = (props: IEditSectionProps) => {

    const { section, setMode } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        editorRef?.current?.focus();
    }, []);

    useEffect(() => {
        editorRef.current?.setMarkdown(section.section_desc || '');
    }, [section.section_desc]);

    const setDefaultMode = useCallback(() => {
        setMode(SectionDescriptionMode.DEFAULT);
    }, [setMode]);

    const save = useCallback(async () => {
        const updatedText = editorRef.current?.getMarkdown() || '';

        setLoading(true);
        const response = await updateSection(
            section.section_id,
            updatedText,
        );
        setLoading(false);

        if(!response) {
            showToast('Update section error.');
            return;
        }

        setDefaultMode();

        if(!response) {
            showToast('Section edit is failed.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    }, [section.section_id, setCombinedProject, setDefaultMode]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div className={ `flex flex-col` }>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Project Description</label>
            <div className="markdown-editor border rounded-xl whitespace-pre-wrap text-sm text-slate-800 overflow-auto max-h-[500px] outline-stone-200 mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setDefaultMode }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default EditSection;