const DescartesSquareTopBar = () => {
    return (
        <div className="bg-slate-900 text-slate-100 px-4 py-3 flex items-center">
            <span className="mr-10">Descartes Square: A Decision Making Technique</span>

            <span className="hidden xl:block text-xs ml-auto text-slate-300">questioning approach for critical examination</span>
        </div>
    )
};

export default DescartesSquareTopBar;