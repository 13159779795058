import { Dictionary } from '../../../../common/ui/icons';
import ProjectView from '../projects/project-view';
import { useEffect } from 'react';
import { usePlannerStore } from '../../data/planner-store';

const SummaryView = () => {

    const combinedProject = usePlannerStore(state => state.combinedProject);

    useEffect(() => {
        document.title = `Summary | Productivity`;
    }, []);

    return (
        <div className="xl:w-[800px] max-w-full mx-auto">
            <div className="mb-4 w-full flex flex-col">

                <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center">
                    <Dictionary classes="mr-4" size={ 35 } color={ '#2E766E' } />
                    Summary
                </h1>

                {
                    /**
                     * Optional project description here.
                     */
                }
            </div>

            {
                combinedProject?.projects.map(project => {

                    return (
                        <ProjectView
                            key={`project-${ project.project_id }`}
                            project={ project}
                        />
                    )
                })
            }

        </div>
    )
};

export default SummaryView;