import { useEffect, useState } from 'react';
import { showToast } from '../../../common/domain/toast-provider';
import Preloader from '../../../common/ui/preloader';
import { getBlocksOverviewData } from '../data/blocks-overview-data-service';
import { formatBlocksOverviewData } from '../domain/blocks-overview-provider';
import DayTitles from './day-titles';
import Rows from './rows';
import { lastDayOfMonth, startOfDay } from 'date-fns';
import Title from './title';
import { useBlocksOverviewStore } from '../data/blocks-overview-store';

const BlocksTable = () => {

    const [loading, setLoading] = useState(true);

    const blocksOverviewData = useBlocksOverviewStore(store => store.blocksOverviewData);
    const setBlocksOverviewData = useBlocksOverviewStore(store => store.setBlocksOverviewData);

    const currentMonth = useBlocksOverviewStore(store => store.currentMonth);
    const currentYear = useBlocksOverviewStore(store => store.currentYear);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const startDate = startOfDay(new Date(currentYear, currentMonth, 1));
            const endDate = startOfDay(lastDayOfMonth(startDate));

            const response = await getBlocksOverviewData(startDate.getTime(), endDate.getTime());
            setLoading(false);

            if(!response) {
                showToast('Get block overview data error.');
                return;
            }

            setBlocksOverviewData(formatBlocksOverviewData(response));
        })();
    }, [setBlocksOverviewData, currentMonth, currentYear]);

    return (
        <div className="p-4 flex justify-center">
            {
                loading &&
                <Preloader size={ 20} />
            }

            {
                !loading && blocksOverviewData &&
                <div className="text-sm mx-auto">

                    <Title />

                    <div className="flex flex-col">
                        <DayTitles />
                        <Rows />
                    </div>
                </div>
            }
        </div>
    )
};

export default BlocksTable;