import { ChangeEvent, useCallback, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { TodoDescriptionMode } from './description/todo-description';
import { CalendarIcon2, PreloaderIcon, ResetIcon, SunIcon } from '../../../../common/ui/icons';
import { updateTodo } from '../../data/planner-data-service';
import { ITodo, TodoType } from '../../planner-interfaces';
import { format, addDays, startOfDay } from 'date-fns';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { getTodoTypeSelectOptions } from '../../domain/todo-type-provider';
import TodoTagsSuggestion from './todo-tags-suggestion';
import TodoHabitTrackerForm from '../habit-tracker/forms/todo-habit-tracker-form';
import { HabitTrackerIntervalType } from '../../../habit-tracker/domain/habit-tracker-provider';
import { WeekDaysEnum } from '../../../../common/domain/date-time-provider';
import { deserializeWeekDaysSet, serializeWeekDaysSet } from '../../../habit-tracker/domain/week-days-provider';
import { usePlannerStore } from '../../data/planner-store';

interface IEditTodoProps {
    todo: ITodo;
    setMode: (mode: TodoDescriptionMode) => void;
}

const EditTodo = (props: IEditTodoProps) => {

    const { todo, setMode } = props;

    const [isLoading, setLoading] = useState(false);
    const [date, setDate] = useState<number|null>(todo.todo_date);
    const [hasTime, setHasTime] = useState<boolean>(todo.todo_date_has_time === 1);
    const [isTodoActive, setIsTodoActive] = useState<boolean>(todo.todo_is_active === 1);
    const [type, setType] = useState<TodoType>(todo.todo_type);
    const [updateNested, setUpdateNested] = useState(false);
    const [tags, setTags] = useState(todo.todo_tags || '');
    const [idealFinalResult, setIdealFinalResult] = useState(todo.ideal_final_result || '');
    const [address, setAddress] = useState(todo.todo_address);

    const [isHabitTrackerActive, setIsHabitTrackerActive] = useState<boolean>(Number(todo.habit_active) === 1);
    const [habitInterval, setHabitInterval] = useState<HabitTrackerIntervalType>(todo.habit_interval);
    const [habitQtyPerInterval, setHabitQtyPerInterval] = useState<TodoType>(todo.habit_qty_per_interval);
    const [habitWeekDays, setHabitWeekDays] = useState<Set<WeekDaysEnum>>(deserializeWeekDaysSet(todo.habit_week_days));

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const contentRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if(vw <= 1280) return;

        contentRef?.current?.focus();
    }, []);

    useEffect(() => {
        editorRef.current?.setMarkdown(todo.todo_desc || '');
    }, [todo.todo_desc]);

    const setLongMode = useCallback(() => {
        setMode(TodoDescriptionMode.DEFAULT_LONG);
    }, [setMode]);

    const save = useCallback(async () => {
        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';

        const response = await updateTodo(
            todo.todo_id,
            desc.trim(),
            address,
            date,
            hasTime ? 1 : 0,
            isTodoActive ? 1 : 0,
            type,
            updateNested,
            tags,
            idealFinalResult,
            isHabitTrackerActive,
            habitInterval,
            habitQtyPerInterval,
            serializeWeekDaysSet(habitWeekDays),
        );

        setLoading(false);

        if(!response) {
            showToast('Update task error.');
            return;
        }

        setLongMode();

        setCombinedProject(combineProjectData(response));
    }, [
        date, hasTime, isTodoActive,
        setCombinedProject, setLongMode,
        todo.todo_id, type, updateNested,
        tags, idealFinalResult,
        habitInterval, habitQtyPerInterval,
        isHabitTrackerActive, habitWeekDays,
        address,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLDivElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Escape') {
            evt.stopPropagation();
            setLongMode();
            return;
        }
    };

    const onTodoActiveChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _isActive = (evt.target as HTMLInputElement).checked;
        setIsTodoActive(_isActive);
    };

    const onChangeTodoType = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _type: TodoType = Number((evt.target as HTMLSelectElement).value) || TodoType.Default;
        setType(_type);
    };

    const onUpdateNestedChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _updateNested = (evt.target as HTMLInputElement).checked;
        setUpdateNested(_updateNested);
    };

    const onTagsChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _tags = (evt.target as HTMLInputElement).value;
        setTags(_tags);
    };

    const onIdealFinalResultChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _idealFinalResult = (evt.target as HTMLInputElement).value;
        setIdealFinalResult(_idealFinalResult);
    };

    const onAddressChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _address = (evt.target as HTMLInputElement).value;
        setAddress(_address);
    };

    // ---- DATE / TIME CHANGES ---------------------------------------

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = hasTime ? new Date(evt.target.value) : startOfDay(new Date(evt.target.value)); // 2023-12-07T13:21
        setDate(_date.getTime());
    };

    const onHasTimeChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        const _hasTime = (evt.target as HTMLInputElement).checked;
        setHasTime(_hasTime);
    };

    const setToday = () => {
        const today = startOfDay(new Date());
        setDate(hasTime ? today.getTime() : today.setHours(0,0,0,0));
    };

    const setTomorrow = () => {
        const today = startOfDay(new Date());

        if(!hasTime) {
            today.setHours(0,0,0,0);
        }

        const tomorrow = addDays(today, 1);
        setDate(tomorrow.getTime());
    };

    const setNoDate = () => {
        setDate(null);
    };

    const onTagSuggestionClick = (tag_title: string) => {
        if(tags.trim() === '') {
            setTags(tag_title);
            return;
        }

        setTags(`${ tags }, ${ tag_title }`);
    };

    return (
        <div className={ `flex flex-col ${ todo.nested?.length > 0 ? 'ml-8 xl:ml-14' : 'ml-6' }` }>

            <div className="flex flex-col xl:flex-row mt-2">
                <div className="flex items-center">
                    <div className="flex flex-col mr-4">
                        <label className="text-xs font-bold mb-1 text-slate-400">Edit Date</label>
                        {
                            hasTime &&
                            <input
                                ref={ contentRef }
                                type="datetime-local"
                                className="border rounded text-slate-800 outline-stone-200 mb-4 px-4 py-2 text-sm"
                                onKeyDown={ onKeyDown }
                                onChange={ onDateChange }
                                value={ date ? format(date, `yyyy-MM-dd'T'HH:mm`) : '' }
                            />
                        }

                        {
                            !hasTime &&
                            <input
                                ref={ contentRef }
                                type="date"
                                className="border rounded text-slate-800 outline-stone-200 mb-4 px-4 py-2 text-sm"
                                onKeyDown={ onKeyDown }
                                onChange={ onDateChange }
                                value={ date ? format(date, `yyyy-MM-dd`) : '' }
                            />
                        }
                    </div>

                    <label className="text-xs flex items-center mr-10">
                        <div className="mb-1">&nbsp;</div>
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={ hasTime }
                            onChange={ onHasTimeChange }
                        /> Has Time?
                    </label>
                </div>

                <div className="flex items-center mb-4 xl:mb-0">
                   <button
                       onClick={ setToday }
                       className="text-xs flex items-center mr-4"
                       type="button">
                       <CalendarIcon2 size={ 16 } color={ '#42a378' } classes="mr-1" />
                       Today
                   </button>

                   <button
                       onClick={ setTomorrow }
                       className="text-xs flex items-center mr-4 "
                       type="button">
                       <SunIcon size={ 16 } color={ '#e18601' } classes="mr-1" />
                       Tomorrow
                   </button>

                   <button
                       onClick={ setNoDate }
                       className="text-xs flex items-center mr-4"
                       type="button">
                       <ResetIcon size={ 16 } classes="mr-1" />
                       Reset
                   </button>
               </div>
            </div>

            {
                !todo.is_completed && todo.nested?.length > 0 &&
                <label className="text-xs flex items-center mb-2">
                    <input
                        className="mr-2"
                        type="checkbox"
                        checked={ updateNested }
                        onChange={ onUpdateNestedChange }
                    />
                    Update date for all nested non-completed tasks.
                </label>
            }

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Edit Description</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            {
                type !== TodoType.RegularTaskLog &&
                <>
                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Address</label>
                        <input
                            type="text"
                            placeholder="Address"
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ address }
                            onInput={ onAddressChange }
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Tags</label>
                        <input
                            type="text"
                            placeholder="Tag 1, Tag 2, ..."
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ tags }
                            onInput={ onTagsChange }
                        />

                        {
                            combinedProject?.tags?.length > 0 &&
                            <TodoTagsSuggestion
                                tags={ combinedProject.tags }
                                toFilter={ tags.split(',').map(_tag => _tag.trim()) }
                                onClick={ onTagSuggestionClick }
                            />
                        }
                    </div>

                    <TodoHabitTrackerForm
                        type={ type }
                        isHabitTrackerActive={ isHabitTrackerActive }
                        setIsHabitTrackerActive={ setIsHabitTrackerActive }
                        habitInterval={ habitInterval }
                        setHabitInterval={ setHabitInterval }
                        habitQtyPerInterval={ habitQtyPerInterval }
                        setHabitQtyPerInterval={ setHabitQtyPerInterval }
                        habitWeekDays={ habitWeekDays }
                        setHabitWeekDays={ setHabitWeekDays }
                    />

                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Ideal Final Result</label>
                        <input
                            type="text"
                            placeholder=""
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ idealFinalResult }
                            onInput={ onIdealFinalResultChange }
                        />
                    </div>
                </>
            }

            <div className="flex items-center mb-4">

                {
                    type !== TodoType.RegularTaskLog &&
                    <label className="text-xs flex items-center mr-4">
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={ isTodoActive }
                            onChange={ onTodoActiveChange }
                        /> Is Active?
                    </label>
                }

               <label className="text-xs flex items-center">
                   <select
                       value={ type }
                       onChange={ onChangeTodoType }
                       className="outline-none">
                       { getTodoTypeSelectOptions() }
                   </select>
               </label>
            </div>

           <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setLongMode }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
           </div>
        </div>
    )
};

export default EditTodo;