import { useFlashCardsStore } from '../../data/flashcards-store';

const ProgressInfo = () => {
    const learningFlowIndex = useFlashCardsStore(store => store.learningFlowIndex) ?? 0;
    const learningFlowCards = useFlashCardsStore(store => store.learningFlowCards) || [];

    return (
        <>
            {
                learningFlowCards.length > 0 &&
                <span className="text-xs mx-4 bg-sky-100 rounded px-2 py-1">{ learningFlowIndex + 1 } / { learningFlowCards.length }</span>
            }
        </>
    )
};

export default ProgressInfo;