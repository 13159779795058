import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { deleteLibraryFolder } from '../../../data/library-data-service';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType } from '../../../interfaces';
import { useLibraryStore } from '../../../data/library-store';

interface IDeleteFolderProps {
    folder_id: number;
    folder_title: string;
}

const DeleteFolderButton = ({ folder_id, folder_title }: IDeleteFolderProps) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const navigate = useNavigate();

    const deleteFolderHandler = async () => {

        if(!confirm(`Are you sure you want to delete the folder "${ folder_title }"?`)) return;

        setLoading(true);
        const response = await deleteLibraryFolder(
            folder_id,
            ESelectedLibraryItemType.ROOT,
            0,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete folder error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.ROOT,
            id: 0,
        });

        navigate('/library');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Folder"
                    className="folder__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteFolderHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default DeleteFolderButton;