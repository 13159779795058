import { IMetaData, IDiagram, IDiagramResponse } from '../diagram-interfaces';
import { NODE_PADDING } from '../ui/diagram/diagram-node';
import { ITodo } from '../../planner/planner-interfaces';
import { getDateData } from '../../planner/ui/todo/todo-date';
import { markdown2Html } from '../../../common/markdown/markdown-provider';

const SPLIT_TEXT_PIVOT = 20;
const LETTER_WIDTH = 10;
export const LETTER_HEIGHT = 15;
export const LETTER_VERTICAL_PADDING = 5;
const MARGIN_BETWEEN_NODES = 25;
const DIAGRAM_WIDTH_PADDING = 150;

/**
 * Split text to 1 or more rows
 * for the diagram node.
 */
const splitText = (text: string) : string[] => {
    const words = text.split(' ');

    const splitted: string[][] = [];

    let row: string[] = [];
    let rowLength = 0;
    splitted.push(row);

    for(const word of words) {
        if(rowLength > SPLIT_TEXT_PIVOT) {
            row = [];
            rowLength = 0;
            splitted.push(row);
        }

        row.push(word);
        rowLength += word.length;
    }

    const result: string[] = [];
    for(const row of splitted) {
        result.push(row.join(' '));
    }

    return result;
};

const getTodoMeta = (todo: ITodo) : IMetaData => {
    const parsed = markdown2Html(todo.todo_title);
    const $div = document.createElement('div');
    $div.innerHTML = parsed;

    const texts = splitText($div.textContent);
    let dateColor = '#000';

    if(todo.todo_date) {
        const dateData = getDateData(todo) ?? null;
        texts.push(dateData.dateString);
        dateColor = dateData.color;
    }

    const maxLength = texts.reduce((prev, curr) => {
        return Math.max(prev, curr.length)
    }, 0);

    return {
        x: 0,
        y: 0,
        w: maxLength * LETTER_WIDTH + NODE_PADDING * 2,
        h: LETTER_HEIGHT * texts.length + NODE_PADDING * 2 + LETTER_VERTICAL_PADDING * (texts.length - 1),
        texts,
        dateColor,
    };
};

export const formatDiagramData = async (root_todo_id: number, response: IDiagramResponse) : Promise<IDiagram|null> => {

    if(!root_todo_id || !response.todos) return null;

    // Find the main (root) _todo.
    const root_todo = response.todos.find(todo => todo.todo_id === root_todo_id);
    if(!root_todo || !response.todos) return null;

    if(root_todo.ideal_final_result) {
        root_todo.todo_title = `${ root_todo.todo_title } | ${ root_todo.ideal_final_result }`;
    }

    const nestedTodosMap = new Map<number, ITodo[]>(); // todo_id --> todos
    for(const todo of response.todos) {
        if(!todo.parent_todo_id) continue;
        const _todos = nestedTodosMap.get(todo.parent_todo_id) || [];
        _todos.push(todo);
        nestedTodosMap.set(todo.parent_todo_id, _todos);
    }

    // Find all other todos.
    const todos: ITodo[] = response.todos.filter(todo => todo.parent_todo_id == root_todo_id);
    let diagramWidth = 0;

    // Calculate metadata of every _todo node.
    const metaData = new Map<number, IMetaData>(); // todo_id ---> node size and position
    const rootMeta = getTodoMeta(root_todo);
    rootMeta.isRoot = true;
    metaData.set(root_todo.todo_id, rootMeta);
    diagramWidth = Math.max(diagramWidth, rootMeta.w);

    for(const todo of todos) {
        todo.nested = nestedTodosMap.get(todo.todo_id) || [];
        const todoMeta = getTodoMeta(todo);
        metaData.set(todo.todo_id, todoMeta);
        diagramWidth = Math.max(diagramWidth, todoMeta.w);
    }

    diagramWidth += DIAGRAM_WIDTH_PADDING * 2;

    // Calculate x and y coordinates of every _todo node.
    let heightSum = 0;
    for(const [todo_id, meta] of metaData) {
        const metaHeight = meta.h + MARGIN_BETWEEN_NODES;

        meta.x = (diagramWidth - meta.w) / 2;
        meta.y = heightSum;

        heightSum += metaHeight;
        metaData.set(todo_id, meta);
    }

    return {
        root_todo_id,
        root_todo,
        todos,
        metaData,
        width: diagramWidth,
        height: heightSum,
    };
};