import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { ADD_NEW_DECK_DESCRIPTION_EVENT } from '../deck-description';

interface IDeckEditButtonProps {
    deck_id: number;
}

const DeckEditButton = (props: IDeckEditButtonProps) => {

    const { deck_id } = props;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_DECK_DESCRIPTION_EVENT, {
            detail: deck_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Deck"
            className="deck__add-desc flex bg-slate-300 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default DeckEditButton;