import { deleteJson, get, postJson, putJson, upload } from '../../../common/data/common-data-service';
import { ESelectedLibraryItemType } from '../interfaces';

// -------------------- COMMON ---------------------------------

export const getLibraryData = async (selectedItemType: ESelectedLibraryItemType, selectedItemId: number) => {
    return await get(`/api/library/common/index?selectedItemType=${ selectedItemType }&selectedItemId=${ selectedItemId }`);
};

// -------------------- FOLDERS ------------------------------

export const getLibraryFolder = async (folder_id: number, selectedItemType: ESelectedLibraryItemType, selectedItemId: number) => {
    return await get(`/api/library/folder/index?folder_id=${ folder_id }&selectedItemType=${ selectedItemType }&selectedItemId=${ selectedItemId }`);
};

export const insertLibraryFolder = async (
    folder_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    folder_desc?: string,
    parent_folder_id?: number,
) => {
    return await postJson(`/api/library/folder/insert`, {
        folder_title,
        folder_desc,
        parent_folder_id,
        selectedItemType,
        selectedItemId,
    });
};

export const updateLibraryFolder = async (
    folder_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    folder_desc?: string,
) => {
    return await putJson(`/api/library/folder/update`, {
        folder_id,
        folder_desc,
        selectedItemType,
        selectedItemId,
    });
};

export const updateLibraryFolderTitle = async (
    folder_id: number,
    folder_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    folder_desc?: string,
) => {
    return await putJson(`/api/library/folder/update-title`, {
        folder_id,
        folder_title,
        folder_desc,
        selectedItemType,
        selectedItemId,
    });
};

export const deleteLibraryFolder = async (
    folder_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/folder/delete`, {
        folder_id,
        selectedItemType,
        selectedItemId,
    });
};

// -------------------- AUTHORS ------------------------------

export const getAllAuthors = async () => {
    return await get('/api/library/authors/index');
};

export const searchAuthors = async (term: string) => {
    return await get(`/api/library/authors/search?term=${ term }`);
};

export const getAuthor = async (author_id: number, selectedItemType: ESelectedLibraryItemType, selectedItemId: number) => {
    return await get(`/api/library/author/index?author_id=${ author_id }&selectedItemType=${ selectedItemType }&selectedItemId=${ selectedItemId }`);
};

export const insertAuthor = async (
    folder_id: number,
    author_first_name: string,
    author_last_name?: string,
    author_description?: string,
) => {
    return await postJson(`/api/library/author/insert`, {
        folder_id,
        author_first_name,
        author_last_name,
        author_description,
    });
};

export const updateAuthor = async (
    author_id: number,
    author_first_name: string,
    author_last_name: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    author_description?: string,
) => {
    return await putJson(`/api/library/author/update`, {
        author_id,
        author_first_name,
        author_last_name,
        author_description,
        selectedItemType,
        selectedItemId
    });
};

export const deleteAuthor = async (
    author_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/author/delete`, {
        author_id,
        selectedItemType,
        selectedItemId,
    });
};

// -------------------- BOOKS ------------------------------

export const searchBook = async (term: string) => {
    return await get(`/api/library/search/index?term=${ term }`);
};

export const getBook = async (book_id: number) => {
    return await get(`/api/library/book/index?author_id=${ book_id }`);
};

export const insertBook = async (
    author_id: number,
    book_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    book_desc?: string,
    serie_id?: number,
    number_in_serie?: number
) => {
    return await postJson(`/api/library/book/insert`, {
        author_id,
        book_title,
        book_desc,
        serie_id,
        number_in_serie,
        selectedItemType,
        selectedItemId,
    });
};

export const updateBook = async (
    author_id: number,
    book_id: number,
    book_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    book_desc?: string,
    book_image?: string,
    serie_id?: number,
    number_in_serie?: number,
) => {
    return await putJson(`/api/library/book/update`, {
        author_id,
        book_id,
        book_title,
        book_desc,
        book_image,
        serie_id,
        number_in_serie,
        selectedItemType,
        selectedItemId,
    });
};

export const deleteBook = async (
    author_id: number,
    book_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/book/delete`, {
        author_id,
        book_id,
        selectedItemType,
        selectedItemId,
    });
};

export const dragBookToSerie = async (
    book_id: number,
    target_serie_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await putJson(`/api/library/book/drag-to-serie`, {
        book_id,
        target_serie_id,
        selectedItemType,
        selectedItemId,
    });
};

// -------------------- BOOK FILES --------------------------

export const uploadBookFile = async (formData: FormData) => {
    return await upload('/api/library/book-file/upload', formData);
};

export const deleteBookFile = async (
    book_file_id: number,
    original_ext: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/book-file/delete`, {
        book_file_id,
        original_ext,
        selectedItemType,
        selectedItemId,
    });
};

export const getBookFileData = async (book_file_id: number) => {
    return await get(`/api/library/book-file/get-file-data?book_file_id=${ book_file_id }`);
};

// -------------------- SERIES ------------------------------

export const insertSerie = async (
    author_id: number,
    serie_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    serie_desc?: string,
    serie_image?: string,
) => {
    return await postJson(`/api/library/serie/insert`, {
        author_id,
        serie_title,
        serie_desc,
        serie_image,
        selectedItemType,
        selectedItemId,
    });
};

export const updateSerie = async (
    author_id: number,
    serie_id: number,
    serie_title: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
    serie_desc?: string,
    serie_image?: string,
) => {
    return await putJson(`/api/library/serie/update`, {
        author_id,
        serie_id,
        serie_title,
        serie_desc,
        serie_image,
        selectedItemType,
        selectedItemId,
    });
};

export const deleteSerie = async (
    author_id: number,
    serie_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/serie/delete`, {
        author_id,
        serie_id,
        selectedItemType,
        selectedItemId,
    });
};

// -------------------- TAGS ------------------------------

export const getAllTags = async () => {
    return await get(`/api/library/tag/get-all`);
};

export const getTagsView = async () => {
    return await get(`/api/library/tag/get-tags-view`);
};

export const getTagBooks = async (tag_id: number) => {
    return await get(`/api/library/tag/index?tag_id=${ tag_id }`);
};

export const insertTag = async (
    tagTitle: string,
    book_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await postJson(`/api/library/tag/insert`, {
        tagTitle,
        book_id,
        selectedItemType,
        selectedItemId,
    });
};

export const removeTagFromBook = async (
    tag_id: number,
    book_id: number,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await deleteJson(`/api/library/tag/remove-from-book`, {
        tag_id,
        book_id,
        selectedItemType,
        selectedItemId,
    });
};

export const deleteTag = async (
    tag_id: number
) => {
    return await deleteJson(`/api/library/tag/delete`, {
        tag_id,
    });
};

export const updateTag = async (
    tag_id: number,
    tag_title: string,
) => {
    return await putJson(`/api/library/tag/update`, {
        tag_id,
        tag_title,
    });
};

export const updateBooksTags = async (
    book_id: number,
    selectedTags: Set<number>,
    newTag: string,
    selectedItemType: ESelectedLibraryItemType,
    selectedItemId: number,
) => {
    return await putJson(`/api/library/tag/update-book-tags`, {
        book_id,
        selectedTags: Array.from(selectedTags) || [],
        newTag: newTag.trim(),
        selectedItemType,
        selectedItemId,
    });
};