import { SquareIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';

interface IDescartesSquareBtn {
    todo: ITodo;
}

const DescartesSquareBtn = ({ todo }: IDescartesSquareBtn) => {

    return (
        <a
            className="todo__descartes-square-btn flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
            target="_blank"
            rel="noreferrer"
            href={ `/descartes-square/${ todo.todo_id }` }
            title="Open Descartes Square/">
            <SquareIcon color={ '#586180' } />
        </a>
    )
};

export default DescartesSquareBtn;