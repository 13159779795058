import { useAuthStatus } from '../../../auth/hooks/auth-status-hook';
import { useParams } from 'react-router-dom';
import Preloader from '../../../../common/ui/preloader';
import MobileMenuBtn from '../../../../menu/ui/mobile-menu-btn';
import Menu from '../../../../menu/menu';
import { MouseEvent, useEffect, useState } from 'react';
import { ILibraryBookData } from '../../interfaces';
import { getBookFileData } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import TopTitle from './top-title';
import Fb2View from './viewers/fb2/fb2-view';

const LibraryReadView = () => {

    const { loading } = useAuthStatus();

    const { book_file_id } = useParams();

    const _book_file_id = Number(book_file_id) || 0;

    const [data, setData] = useState<ILibraryBookData|null>(null);
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        if(!loading) return;

        (async () => {
            if(!_book_file_id) {
                showToast('book_file_id is not provided or not correct.');
                return;
            }

            const response = await getBookFileData(_book_file_id);
            if(!response) {
                showToast('Get book file data error.');
                return;
            }

            setData(response);
            setDataLoading(false);
        })();
    }, [loading, _book_file_id]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu
                            showIcons={ true }
                            hideSideBarToggle={ true }
                        />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto p-6 flex-1 flex flex-col">

                            {
                                dataLoading &&
                                <Preloader size={ 30 } />
                            }

                            {
                                data && data.bookFile &&
                                <>
                                    <TopTitle data={ data } />

                                    {
                                        data.bookFile.book_file_ext === '.fb2' &&
                                        <Fb2View xml={ data.content || '' } />
                                    }
                                </>
                            }
                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default LibraryReadView;