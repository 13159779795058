import { useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import { updateTagTitle } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatTagsData } from '../../domain/tags-provider';
import Actions from '../../../../common/ui/actions';
import DeleteTagButton from './actions/tag-delete-btn';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import { ITag } from '../../planner-interfaces';
import { closeTag, openTag } from '../../domain/planner-storage';
import { usePlannerStore } from '../../data/planner-store';

enum TagTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ITagTitle {
    tag: ITag;
}

const TagTitle = ({ tag }: ITagTitle) => {

    const tagsData = usePlannerStore(state => state.tagsData);
    const setTagsData = usePlannerStore(state => state.setTagsData);

    const [mode, setMode] = useState<TagTitleMode>(TagTitleMode.DEFAULT);
    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);

    const startEdit = () => {
        setMode(TagTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(TagTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateTagTitle(tag.title, updatedText.trim());
        setUpdateTitleLoading(false);

        if(!response?.isValid) {
            showToast('Update tag title error.');
            return;
        }

        cancelEdit();

        setTagsData(formatTagsData(response));
    };

    const toggleTodo = () => {
        const isClosed = tag.isClosed;

        if(isClosed) {
            openTag(tag.title);
        }
        else{
            closeTag(tag.title);
        }

        tag.isClosed = !isClosed;

        setTagsData({ ...tagsData });
    };

    return (
        <div
            className={ `tag__title text-sm flex items-center flex-1 py-1 ${ mode === TagTitleMode.EDIT ? 'flex-1' : '' }` }>
            {
                mode === TagTitleMode.DEFAULT &&
                <div className="flex items-center flex-1">

                    <div className="flex items-center">
                        <button
                            type="button"
                            className={ `markdown inline-flex text-left mr-2 whitespace-pre-wrap ml-1` }
                            onClick={ startEdit }>
                            { tag.title }
                        </button>

                        {
                            tag.todos?.length > 0 &&
                            <button
                                type="button"
                                onClick={ toggleTodo }
                                className="w-[24px] h-[24px] mr-2">
                                { !tag.isClosed && <ArrowDownIcon color="#000" /> }
                                { tag.isClosed && <ArrowRightIcon color="#000" /> }
                            </button>
                        }
                    </div>

                    {
                        tag.todos?.length > 0 &&
                        <div className="text-xs bg-indigo-500 text-white font-normal text-xs px-[5px] rounded ml-auto whitespace-nowrap">
                            { tag.todos.length - (tag.futureTodosCount ?? 0) } / { tag.todos.length }
                        </div>
                    }

                    <Actions classes="tag__actions" style={{
                        marginLeft: tag.todos?.length > 0 ? '0' : 'auto',
                    }}>

                        <DeleteTagButton tag={ tag.title } />

                    </Actions>
                </div>
            }

            {
                mode === TagTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ tag.title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </div>
    )
};

export default TagTitle;