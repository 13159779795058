import { ITodo } from '../../planner-interfaces';

interface ITodoTagsProps {
    todo: ITodo;
}

const TodoTags = ({ todo }: ITodoTagsProps) => {

    const hasNestedTodos = todo?.nested?.length > 0;

    return (
        <div className={ `text-xs flex items-center ml-6 md:mt-1 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
            {
                todo.tags.map(tag => {
                    return (
                        <div
                            className="mr-2 bg-slate-100 whitespace-nowrap rounded px-4 py-1 mb-1"
                            key={ tag.code }>
                            { tag.title }
                        </div>
                    )
                })
            }
        </div>
    )
};

export default TodoTags;