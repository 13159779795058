import { CELL_WIDTH, CELL_HEIGHT, PROJECT_TITLE_SIZE, TODO_TITLE_SIZE } from '../domain/blocks-overview-provider';
import { useBlocksOverviewStore } from '../data/blocks-overview-store';

const DayTitles = () => {

    const blocksOverviewData = useBlocksOverviewStore(store => store.blocksOverviewData);
    const daysInMonth = blocksOverviewData?.daysInMonth ?? 0;

    const getDays = () => {
        const days = [];

        for(let i=1; i<=daysInMonth; i++) {
            days.push({
                index: i,
            });
        }

        return days;
    };

    const days = getDays();

    return (
        <div className="flex">

            <div style={{
                width: PROJECT_TITLE_SIZE,
            }}>&nbsp;</div>

            <div style={{
                width: TODO_TITLE_SIZE,
            }}>&nbsp;</div>

            {
                days.map((day, i) => {
                    return (
                        <div
                            className={ `border-t border-l border-slate-200 bg-slate-100 border-b-slate-400 text-xs flex justify-center items-center ${ i === days.length - 1 ? 'border-r' : ''} ` }
                            key={ day.index }
                            style={{
                                width: CELL_WIDTH,
                                height: CELL_HEIGHT,
                            }}>
                            { day.index }
                        </div>
                    )
                })
            }
        </div>
    )
};

export default DayTitles;