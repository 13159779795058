import { FlashCardsPerformance, IFlashCard } from '../../flashcards-interfaces';
import { ReactNode } from 'react';
import { updateCardStatus } from '../../data/flashcards-data-service';
import { calculateNextReviewDate } from '../../domain/flashcards-provider';
import { showToast } from '../../../../common/domain/toast-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IStudyBtn {
    performance: FlashCardsPerformance;
    text: string;
    icon: ReactNode;
    classes: string;
    card: IFlashCard;

    showQuestion: () => void;
    endStudy: () => void;
}

const StudyBtn = ({ performance, text, icon, classes, card, showQuestion, endStudy }: IStudyBtn) => {

    const learningFlowCards = useFlashCardsStore(state => state.learningFlowCards) || [];
    const learningFlowIndex = useFlashCardsStore(state => state.learningFlowIndex);
    const setLearningFlowIndex = useFlashCardsStore(state => state.setLearningFlowIndex);
    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);

    const updateCardStatusHandler = async () => {

        const [newReviewDate, newEaseFactor] = calculateNextReviewDate(
            performance,
            card.ease_factor,
            card.card_interval,
            card.last_review_date,
        );

        const response = await updateCardStatus(flashCardsListType, Date.now(), performance, newReviewDate, card.card_id, newEaseFactor);
        if(!response?.isValid) {
            showToast('Update cart status error.');
            return;
        }

        // Go to the next card
        if(learningFlowIndex === learningFlowCards.length - 1) {
            setLearningFlowIndex(0);
            endStudy();
        }
        else{
            setLearningFlowIndex(learningFlowIndex + 1);
            showQuestion();
        }
    };

    return (
        <button
            className={ `pr-4 pl-6 py-2 rounded-lg text-sm md:mr-4 flex items-center justify-center mb-4 md:mb-0 w-full md:w-auto ${ classes }` }
            onClick={ updateCardStatusHandler }
            type="button">
            <span className="mr-2">{ text }</span>
            { icon }
        </button>
    )
};

export default StudyBtn;