import { DragEvent } from 'react';
import { DragIcon } from '../../../../../common/ui/icons';
import { IDeck } from '../../../flashcards-interfaces';

const DRAG_CLASS = 'flashcards-deck-dragging';

interface IDragDeckBtnProps {
    deck: IDeck;
}

const DragDeckBtn = ({ deck }: IDragDeckBtnProps) => {

    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', deck.deck_id.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return(
        <button
            type="button"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            className="flash-cards-menu__drag-deck-btn cursor-move rounded-full">
            <DragIcon
                size={ 20 }
                color={ '#88a3d0' }
            />
        </button>
    )
};

export default DragDeckBtn;