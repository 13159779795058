import { ArrowLeftIcon } from '../../../../common/ui/icons';
import { addMonths, startOfMonth } from 'date-fns';
import { useHabitTrackerStore } from '../../data/habit-tracker-store';

const PrevMonthButton = () => {

    const habitTrackerStartDate = useHabitTrackerStore(store => store.habitTrackerStartDate);
    const setHabitTrackerStartDate = useHabitTrackerStore(store => store.setHabitTrackerStartDate);

    const jumpTPrevMonth = () => {
        setHabitTrackerStartDate(startOfMonth(addMonths(habitTrackerStartDate, -1)));
    };

    return (
        <button
            onClick={ jumpTPrevMonth }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevMonthButton;