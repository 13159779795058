import { saveHideFutureTagsTasks } from '../../../domain/planner-storage';
import { ChangeEvent } from 'react';
import { usePlannerStore } from '../../../data/planner-store';

const HideFutureTasks = () => {

    const hideFutureTagsTasks = usePlannerStore(store => store.hideFutureTagsTasks);
    const setHideFutureTagsTasks = usePlannerStore(store => store.setHideFutureTagsTasks);

    const hideFutureTasksHandler = (evt: ChangeEvent<HTMLInputElement>) => {
        const checked = !(evt.target.checked);
        setHideFutureTagsTasks(checked);
        saveHideFutureTagsTasks(checked);
    };

    return (
        <label className="flex items-center text-sm">
            <input
                checked={ hideFutureTagsTasks }
                className="mr-2"
                type="checkbox"
                onInput={ hideFutureTasksHandler }
            />
            <span>Hide Future Tasks</span>
        </label>
    )
};

export default HideFutureTasks;