import { create } from 'zustand';
import { IHabitTrackerChartData } from '../habit-tracker-interfaces';
import { startOfMonth } from 'date-fns';

interface HabitTrackerState {
    habitTrackerTodoParentId: number;
    setHabitTrackerTodoParentId: (_todo_parent_id: number) => void;

    chartData: IHabitTrackerChartData|null;
    setChartData: (_chartData: IHabitTrackerChartData|null) => void;

    habitTrackerStartDate: Date;
    setHabitTrackerStartDate: (_habitTrackerStartDate: Date) => void;
}

export const useHabitTrackerStore = create<HabitTrackerState>()((set) => ({
    habitTrackerTodoParentId: null,
    setHabitTrackerTodoParentId: (_habitTrackerTodoParentId: number) => {
        return set((state: HabitTrackerState) => {
            return {
                ...state,
                habitTrackerTodoParentId: _habitTrackerTodoParentId,
            };
        });
    },

    chartData: null,
    setChartData: (_chartData: IHabitTrackerChartData|null) => {
        return set((state: HabitTrackerState) => {
            return {
                ...state,
                chartData: _chartData,
            };
        });
    },

    habitTrackerStartDate: startOfMonth((new Date())),
    setHabitTrackerStartDate: (_habitTrackerStartDate: Date) => {
        return set((state: HabitTrackerState) => {
            return {
                ...state,
                habitTrackerStartDate: _habitTrackerStartDate,
            };
        });
    },
}))