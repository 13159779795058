import { TodoDescriptionMode } from './todo-description';

interface IDescriptionPreviewProps {
    parsedDesc: string;
    setMode: (mode: TodoDescriptionMode) => void;
}

const TodoDescriptionPreview = (props: IDescriptionPreviewProps) => {
    const { parsedDesc, setMode } = props;

    const setLongMode = () => {
        setMode(TodoDescriptionMode.DEFAULT_LONG);
    };

    return (
        <div
            className="text-sm leading-none overflow-hidden bg-slate-50 text-slate-600 rounded px-4 py-2 cursor-pointer h-[38px]"
            onClick={ setLongMode }>
            <div
                className="markdown whitespace-wrap leading-6"
                dangerouslySetInnerHTML={{__html: parsedDesc || '' }}
            />
        </div>
    )
};

export default TodoDescriptionPreview;