import { FolderIcon } from '../../../../../common/ui/icons';
import { IProject } from '../../../planner-interfaces';
import { closeSection } from '../../../domain/planner-storage';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { usePlannerStore } from '../../../data/planner-store';

interface ICloseProjectSectionsBtnProps {
    project: IProject;
}

const CloseProjectSectionsBtn = ({ project }: ICloseProjectSectionsBtnProps) => {

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const closeAllSections = () => {
        const sections = project.sections || [];
        for(const section of sections) {
            section.isOpened = false;
            closeSection(section.section.section_id);
        }

        setCombinedProject(JSON.parse(JSON.stringify(combinedProject)));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Close All Sections"
            className="flex bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ closeAllSections }
            type="button">
            <FolderIcon size={ 20 } />
        </button>
    )
};

export default CloseProjectSectionsBtn;