import { FolderIcon } from '../../../../../common/ui/icons';
import { closeTag } from '../../../domain/planner-storage';
import { usePlannerStore } from '../../../data/planner-store';

const CloseAllTagsBtn = () => {

    const tagsData = usePlannerStore(state => state.tagsData);
    const setTagsData = usePlannerStore(state => state.setTagsData);

    const closeAllTags = () => {
        if(!tagsData?.tags) return;

        for(const tag of tagsData.tags) {
            tag.isClosed = true;
            closeTag(tag.title);
        }

        setTagsData({ ...tagsData });
    };

    return (
        <button
            title="Close All Tags"
            className="flex bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ closeAllTags }
            type="button">
            <FolderIcon size={ 20 } />
        </button>
    )
};

export default CloseAllTagsBtn;