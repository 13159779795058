import { useParams } from 'react-router-dom';
import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import Preloader from '../../common/ui/preloader';
import { useEffect, useState } from 'react';
import { getTodo } from '../planner/data/planner-data-service';
import { ITodo } from '../planner/planner-interfaces';
import { marked } from 'marked';
import { format } from 'date-fns';

const PrintTodo = () => {

    const { loading } = useAuthStatus();

    const { todo_id } = useParams();

    const [todo, setTodo] = useState<ITodo|null>(null);

    useEffect(() => {
        (async () => {
            const todo = await getTodo(Number(todo_id) || 0);
            setTodo(todo ?? null);
        })();
    }, [todo_id]);

    return (
        <div className="text-slate-800 flex flex-col items-center print-box xl:max-w-[210mm] mx-auto">

            { loading && <Preloader/> }

            {
                !loading && todo &&
                <div className="m-6">
                    <div className="mb-6">
                        <h1
                            className="text-2xl font-bold"
                            dangerouslySetInnerHTML={{__html: marked.parse(todo?.todo_title) }}
                        />

                        {
                            todo.todo_date &&
                            <div className="mt-2 text-violet-800 text-sm">{ format(todo.todo_date, 'dd MMM yyyy (E)') }</div>
                        }
                    </div>

                    <div dangerouslySetInnerHTML={{__html: marked.parse(todo?.todo_desc) }} />
                </div>
            }
        </div>
    )
};

export default PrintTodo;