import { IBlocksOverviewData, IBlocksOverviewResponse, ICell, IProjectRows, IRow } from '../blocks-overview-interfaces';
import { getDaysInMonth, isWithinInterval, startOfDay } from 'date-fns';
import { IBlock, IProject, ITodo } from '../../planner/planner-interfaces';

export const PROJECT_TITLE_SIZE = '120px';
export const TODO_TITLE_SIZE = '250px';
export const CELL_WIDTH = '35px';
export const CELL_HEIGHT = '30px';

const getCell = (
    day: number,
    month: number,
    year: number,
    todo: ITodo,
    projectMap: Map<number, IProject>
) : ICell => {
    let hasBlock = false;
    let color = undefined;

    const date = startOfDay(new Date(year, month, day));

    for(const block of todo.blocks) {

        const startDate = startOfDay(new Date(block.start_date));
        const endDate = startOfDay(new Date(block.end_date));

        const isDateInRange = isWithinInterval(date, { start: startDate, end: endDate });
        if(isDateInRange) {
            hasBlock = true;
            const project = projectMap.get(todo.project_id);
            color = project?.project_color;
            break;
        }
    }

    const todos: ITodo[] = [];

    for(const nestedTodo of todo.nested) {
        if(!nestedTodo.todo_date) continue;
        if(startOfDay(new Date(nestedTodo.todo_date)).getDate() === date.getDate()) {
            todos.push(nestedTodo);
        }
    }

    return {
        index: day,
        hasBlock,
        color,
        todos,
    }
};


export const formatBlocksOverviewData = (response: IBlocksOverviewResponse|null) : IBlocksOverviewData|null => {
    if(!response) return null;

    const blocks = response.blocks || [];
    const todos = response.todos || [];
    const nestedTodos = response.nestedTodos || [];
    const projects = response.projects || [];

    const todoBlocksMap = new Map<number, IBlock[]>(); // todo_id ---> blocks
    for(const block of blocks) {
        const _blocks = todoBlocksMap.get(block.todo_id) || [];
        _blocks.push(block);
        todoBlocksMap.set(block.todo_id, _blocks);
    }

    const todoMap = new Map<number, ITodo>(); // todo_id ---> _todo
    for(const todo of todos) {
        todo.blocks = todoBlocksMap.get(todo.todo_id);
        todoMap.set(todo.todo_id, todo);
    }

    const nestedTodosMap = new Map<number, ITodo[]>(); // todo_id --> todos
    for(const todo of nestedTodos) {
        if(!todo.root_todo_id) continue;
        const _todos = nestedTodosMap.get(todo.root_todo_id) || [];
        _todos.push(todo);
        nestedTodosMap.set(todo.root_todo_id, _todos);
    }

    const projectMap = new Map<number, IProject>(); // project_id ---> project
    for(const project of projects) {
        projectMap.set(project.project_id, project);
    }

    for(const todo of todos) {
        todo.nested = nestedTodosMap.get(todo.todo_id) || [];
    }

    const now = new Date();
    const daysInMonth = getDaysInMonth(now);

    const projectsMap = new Map<number, IRow[]>(); // project_id ---> rows

    const _todos = [...todoMap.values()];
    for(const todo of _todos) {

        const cells: ICell[] = [];

        for(let i=1; i<=daysInMonth; i++) {
            cells.push(
                getCell(
                    i,
                    now.getMonth(),
                    now.getFullYear(),
                    todo,
                    projectMap
                )
            );
        }

        const _projectRows = projectsMap.get(todo.project_id) || [];

        _projectRows.push({
            todo,
            cells,
        });

        projectsMap.set(todo.project_id, _projectRows);
    }

    const projectRows: IProjectRows[] = [];

    for(const project of projects) {
        projectRows.push({
            project,
            rows: projectsMap.get(project.project_id) || [],
        });
    }

    return {
        blocks,
        projects: projectRows,
        daysInMonth,
    };
};