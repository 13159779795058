import { DiagramIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';

interface IDiagramBtn {
    todo: ITodo;
}

const DiagramBtn = ({ todo }: IDiagramBtn) => {

    return (
        <a
            className="todo__diagram-btn flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
            target="_blank"
            rel="noreferrer"
            href={ `/diagram/${ todo.todo_id }` }
            title="Open Diagram">
            <DiagramIcon color={ '#586180' } />
        </a>
    )
};

export default DiagramBtn;