import { CheckIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { getHabitLetter } from '../../../../habit-tracker/domain/habit-tracker-provider';
import { useState } from 'react';
import { insertTodo } from '../../../data/planner-data-service';
import { ITodo, TodoType } from '../../../planner-interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITrackHabitBtn {
    todo: ITodo;
}

const TrackHabitBtn = ({ todo }: ITrackHabitBtn) => {

    const [trackHabitLoading, setTrackHabitLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const trackHabit = async () => {
        if(trackHabitLoading || todo.habit_qty_per_interval === todo.habit_total) return;

        setTrackHabitLoading(true);
        const response = await insertTodo(
            todo.project_id,
            todo.section_id,
            todo.todo_id,
            `Habit Log: ${ todo.todo_title }`,
            null,
            todo.todo_address,
            0,
            (new Date).getTime(),
            1,
            TodoType.RegularTaskLog,
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            1,
        );
        setTrackHabitLoading(false);

        if(!response) {
            showToast('Track habit error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <button
            onClick={ trackHabit }
            className={ `mr-2 bg-sky-100 px-2 py-[2px] rounded-lg flex items-center justify-center ${ todo.habit_qty_per_interval === todo.habit_total ? 'cursor-default' : 'cursor-pointer' }` }
            type="button"
            title="Track Habit">

            {
                !trackHabitLoading &&
                <>
                    {
                        (todo.habit_qty_per_interval === todo.habit_total) &&
                        <CheckIcon
                            size={ 20 }
                            color={ '#2a8079' }
                        />
                    }

                    {
                        (todo.habit_qty_per_interval !== todo.habit_total) &&
                        <span className="text-xs whitespace-nowrap">
                            { todo.habit_total } / { todo.habit_qty_per_interval } { getHabitLetter(todo.habit_interval) }
                        </span>
                    }
                </>
            }

            {
                trackHabitLoading &&
                <PreloaderIcon size={ 18 } color={ '#2a8079' } />
            }
        </button>
    )
};

export default TrackHabitBtn;