import { useEffect, useState } from 'react';
import { IFlashCard } from '../../../flashcards-interfaces';
import EditCardForm from '../card-edit-form';
import CardDescriptionPreview from './card-description-preview';
import { getTextFromMarkdown } from '../../../../../common/markdown/markdown-provider';

export const EDIT_CARD_DESCRIPTION_EVENT = 'edit-card-desc';

export enum CardDescriptionMode {
    DEFAULT_SHORT = 0,
    EDIT = 1,
}

interface ICardDescriptionProps {
    card: IFlashCard;
}

export const isDescExists = (card: IFlashCard) => {
    const desc = card.card_front;
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const getDescription = (descExists: boolean, card_front?: string) => {
    if(!descExists) return '';

    const _card_front = card_front?.replaceAll('```', '') ?? '';
    return getTextFromMarkdown(_card_front);
}

const CardDescription = ({ card }: ICardDescriptionProps) => {

    const [mode, setMode] = useState<CardDescriptionMode>(CardDescriptionMode.DEFAULT_SHORT);

    const descExists = isDescExists(card);
    const description = getDescription(descExists, card.card_front);

    const updateMode = (_mode: CardDescriptionMode) => {
        setMode(_mode);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const card_id = Number(evt?.detail) || 0;
            if(card_id !== card.card_id) return;

            setMode(CardDescriptionMode.EDIT);
        };

        document.addEventListener(EDIT_CARD_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(EDIT_CARD_DESCRIPTION_EVENT, handler);
        };
    }, [card.card_id]);

    return (
        <>
            {
                (mode === CardDescriptionMode.DEFAULT_SHORT) &&
                <CardDescriptionPreview
                    description={ description }
                    setMode={ updateMode }
                />
            }

            {
                (mode === CardDescriptionMode.EDIT) &&
                <EditCardForm
                    card={ card }
                    setMode={ updateMode }
                />
            }
        </>
    )
};

export default CardDescription;