import { ILibraryAuthor } from '../../interfaces';
import { NavLink } from 'react-router-dom';

interface ILibraryMenuAuthor {
    author: ILibraryAuthor;
}

const LibraryMenuAuthor = ({ author }: ILibraryMenuAuthor) => {
    return (
        <NavLink
            to={ `/library/a-${ author.author_id }` }
            className="library-author list-disc list-item ml-7">
            { author.author_last_name } { author.author_first_name }
        </NavLink>
    )
};

export default LibraryMenuAuthor;