import { ITodo } from '../../planner-interfaces';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import ActiveTodoItem from './active-todo-item';
import { useState } from 'react';
import { getActiveTodoItemsStateFromStorage, saveActiveTodoItemsStateToStorage } from '../../domain/planner-storage';

interface IActiveTodosProps {
    activeTodos: ITodo[];
}

const ActiveTodos = ({ activeTodos }: IActiveTodosProps) => {
    const [isOpened, setIsOpened] = useState(getActiveTodoItemsStateFromStorage());

    const toggle = () => {
        const _isOpened = !isOpened;
        setIsOpened(_isOpened);
        saveActiveTodoItemsStateToStorage(_isOpened);
    };

    return (
        <>
            {
                activeTodos?.length > 0 &&
                <div className="text-sm leading-7 mb-10 border rounded p-4 bg-stone-100">
                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={ toggle }
                            className="w-[24px] h-[24px] bg-white transition-colors rounded mr-4">
                            { isOpened && <ArrowDownIcon /> }
                            { !isOpened && <ArrowRightIcon /> }
                        </button>

                        <div className="font-bold mr-4">Active Items</div>
                        <div className="text-xs">{ activeTodos.length }</div>
                    </div>

                    {
                        isOpened &&
                        <div className="mt-2">
                            {
                                activeTodos.map(todo => {
                                    return (
                                        <ActiveTodoItem
                                            key={ `active-todo-${ todo.todo_id }` }
                                            activeTodo={ todo }
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
};

export default ActiveTodos;