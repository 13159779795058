import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { login } from '../../data/auth-data-service';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { LoginMode } from '../login';

interface ILoginForm {
    setLoginMode: (loginMode: LoginMode) => void;
}

export const LoginForm = ({ setLoginMode }: ILoginForm) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [usernameTouched, setUsernameTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const isUsernameValid = !usernameTouched || username.trim().length > 0;
    const isPasswordValid = !passwordTouched || password.trim().length > 0;

    const performLogin = async () => {
        clearError();

        if(username.trim().length <= 0) {
            setUsernameTouched(true);
            return;
        }

        if(password.trim().length <= 0) {
            setPasswordTouched(true);
            return;
        }

        setLoading(true);
        const res = await login(username.trim(), password.trim());
        setLoading(false);

        if(!res.isValid) {
            setError(true);
            return;
        }

        setLoginMode(LoginMode.WAIT_FOR_DOUBLE_OPT_IN_CODE);
    };

    const clearError = () => {
        setError(false);
    };

    const onUsernameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setUsername(evt.target.value);
        setUsernameTouched(true);
    };

    const onPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setPassword(evt.target.value);
        setPasswordTouched(true);
    };

    const handleKeyUp = async (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await performLogin();
        }

        if(evt.code === 'Escape') {
            (evt.target as HTMLInputElement).value = '';
        }
    };

    return (
        <>
            <div className="mb-4 flex flex-col">
                <label htmlFor="username" className="block mb-2">Username</label>
                <input
                    type="text"
                    id="username"
                    autoComplete="username"
                    value={ username }
                    onChange={ onUsernameChange }
                    onKeyUp={ handleKeyUp }
                    onClick={ clearError }
                    className={ `border rounded px-4 py-2 block ${ isUsernameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                />

                {
                    !isUsernameValid && <div className="text-red-700 text-xs mt-1">Username is required.</div>
                }
            </div>

            <div className="mb-4 flex flex-col">
                <label htmlFor="password" className="block mb-2">Password</label>
                <input
                    type="password"
                    id="password"
                    value={ password }
                    autoComplete="current-password"
                    onChange={ onPasswordChange }
                    onKeyUp={ handleKeyUp }
                    onClick={ clearError }
                    className={ `border rounded px-4 py-2 block ${ isPasswordValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                />

                {
                    !isPasswordValid && <div className="text-red-700 text-xs mt-1">Password is required.</div>
                }
            </div>

            {
                loading && <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !loading &&
                <button
                    className="bg-slate-500 text-slate-100 rounded px-10 py-3"
                    type="button"
                    onClick={ performLogin }>Login</button>
            }

            {
                !loading && error &&
                <div className="bg-red-100 rounded py-3 px-6 mt-4">
                    <p>Username or password is incorrect.</p>
                </div>
            }
        </>
    )
};

export default LoginForm;
