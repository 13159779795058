interface INestedTodosCount {
    count: number;
    x: number;
    y: number;
    todo_id: number;
}

const TODOS_COUNT_LEFT_MARGIN = 15;
const FONT_SIZE = 12;

const NestedTodosCount = ({ count, x, y, todo_id }: INestedTodosCount) => {
    return (
        <a href={ `/diagram/${ todo_id }` } target="_blank" rel="noreferrer">
            <text
                data-type="nested-count"
                x={ x + TODOS_COUNT_LEFT_MARGIN }
                y={ y - FONT_SIZE/2 }
                fontSize={ FONT_SIZE }
                fill={ '#4aa292' }
                textDecoration="underline"
            >{ count }</text>
        </a>
    )
};

export default NestedTodosCount;