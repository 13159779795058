import { ICell } from '../blocks-overview-interfaces';
import { CELL_WIDTH, CELL_HEIGHT } from '../domain/blocks-overview-provider';

interface ICellProps {
    cell: ICell;
    isLastCell: boolean;
    isFirstRow: boolean;
}

const Cell = ({ cell, isLastCell, isFirstRow }: ICellProps) => {
    const hasNestedTodos = cell.todos.length > 0;

    let cellColor = '#fff';
    if(cell.hasBlock && cell.color) {
        const alpha = hasNestedTodos ? '0.4' : '0.6';
        cellColor = `linear-gradient(rgba(255, 255, 255, ${ alpha }), rgba(255, 255, 255, ${ alpha })), ${ cell.color }`;
    }

    const className = `border-b border-l border-slate-400 flex justify-center items-center ${ isLastCell ? 'border-r' : ''} ${ isFirstRow ? 'border-t' : '' } ${ hasNestedTodos ? 'cursor-pointer' : '' }`;

    const getTitle = () => {
        return hasNestedTodos ? cell.todos.map(todo => todo.todo_title).join('\n') : undefined;
    };

    const showTitle = () => {
        if(!hasNestedTodos) return;
        alert(getTitle() ?? '');
    };

    return (
        <div
            onClick={ showTitle }
            title={ getTitle() }
            className={ className }
            key={ cell.index }
            style={{
                background: cellColor,
                width: CELL_WIDTH,
                minHeight: CELL_HEIGHT,
            }}>
            &nbsp;
        </div>
    )
};

export default Cell;