import { ILibraryTag, ILibraryTagViewFormatted } from '../../../interfaces';
import TagTitle from './tag-title';
import { NavLink } from 'react-router-dom';

interface ITagItemProps {
    tag: ILibraryTag;
    setTagsView: (formattedData: ILibraryTagViewFormatted) => void;
}

const TagItem = ({ tag, setTagsView }: ITagItemProps) => {
    return (
        <div className="mb-1">
            <TagTitle
                tag={ tag }
                setTagsView={ setTagsView }
            />

            {
                tag.books?.map(book => {
                    return (
                        <NavLink
                            to={ `/library/a-${ book.author_id }` }
                            className="mt-2 mb-1 ml-4 block underline"
                            key={ `${ tag.tag_id }-${ book.book_id }` }>
                            { book.book_title }
                        </NavLink>
                    )
                })
            }
        </div>
    )
};

export default TagItem;