import { useEffect, useState } from 'react';
import { ITodo } from '../../../planner-interfaces';
import TodoDescriptionPreview from './todo-description-preview';
import TodoFullDescription from './todo-full-description';
import EditTodo from '../todo-edit';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';

export const ADD_NEW_TODO_DESCRIPTION_EVENT = 'add-new-todo-desc';

export enum TodoDescriptionMode {
    DEFAULT_SHORT = 0,
    DEFAULT_LONG = 1,
    EDIT = 2,
}

interface ITodoDescriptionProps {
    todo: ITodo;
}

export const isDescExists = (todo: ITodo) => {
    if(todo.attachments?.length > 0) return true;

    const desc = todo.todo_desc;
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const TodoDescription = ({ todo }: ITodoDescriptionProps) => {

    const [mode, setMode] = useState<TodoDescriptionMode>(TodoDescriptionMode.DEFAULT_SHORT);

    const descExists = isDescExists(todo);
    const todoIsOpened = todo.nested?.length > 0 ? todo.isOpened : true;

    const parsedDesc = descExists ? markdown2Html(todo.todo_desc?.replaceAll('```', '')) : '';

    const updateMode = (_mode: TodoDescriptionMode) => {
        setMode(_mode);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const todo_id = Number(evt?.detail) || 0;
            if(todo_id !== todo.todo_id) return;

            setMode(TodoDescriptionMode.EDIT);
        };

        document.addEventListener(ADD_NEW_TODO_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_TODO_DESCRIPTION_EVENT, handler);
        };
    }, [todo.todo_id]);

    return (
        <>
            {
                (descExists && todoIsOpened) &&
                <>
                    {
                        (mode === TodoDescriptionMode.DEFAULT_SHORT) &&
                        <TodoDescriptionPreview
                            parsedDesc={ parsedDesc as string }
                            setMode={ updateMode }
                        />
                    }

                    {
                        (mode === TodoDescriptionMode.DEFAULT_LONG) &&
                        <TodoFullDescription
                            parsedDesc={ parsedDesc as string }
                            setMode={ updateMode }
                            todosAttachments={ todo.attachments }
                        />
                    }
                </>
            }

            {
                (mode === TodoDescriptionMode.EDIT) &&
                <EditTodo
                    todo={ todo }
                    setMode={ updateMode }
                />
            }
        </>
    )
};

export default TodoDescription;