import { ITag } from '../../planner-interfaces';
import { Fragment, useState } from 'react';

interface ITodoTagsSuggestion {
    tags: ITag[];
    toFilter: string[];
    onClick: (title: string) => void;
}

const TodoTagsSuggestion = ({ tags, toFilter, onClick }: ITodoTagsSuggestion) => {

    const [visible, setVisible] = useState(false);

    const set = new Set(toFilter);

    const toggleVisibility = () => {
        setVisible(_visible => !_visible);
    };

    return (
        <div className="text-xs flex flex-col">
            <button
                className="underline text-right my-2"
                onClick={ toggleVisibility }
                type="button">
                Show Suggestions
            </button>

            {
                visible &&
                <div>
                    {
                        tags?.map(tag => {
                            if(set.has(tag.title)) return <Fragment key={ tag.id ?? tag.code }></Fragment>;

                            return (
                                <button
                                    key={ tag.id ?? tag.code }
                                    onClick={ () => {
                                        onClick(tag.title);
                                    }}
                                    className="mr-2 mb-2 bg-slate-100 rounded px-2 py-1"
                                    type="button">
                                    { tag.title }
                                </button>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
};

export default TodoTagsSuggestion;