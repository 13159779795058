import { ReloadIcon } from '../../../../common/ui/icons';

interface IStudyEndScreen {
    showQuestion: () => void;
    updateStartAgainIndex: () => void;
}

const StudyEndScreen = ({ updateStartAgainIndex, showQuestion }: IStudyEndScreen) => {

    const startAgain = () => {
        showQuestion();
        updateStartAgainIndex();
    };

    return (
        <div>
            <p className="mb-4">Congratulations! You have finished this deck for now 🏆</p>
            <button
                className={ `pr-4 pl-6 py-2 rounded-lg text-sm md:mr-4 flex items-center justify-center mb-4 md:mb-0 w-full md:w-auto bg-gray-100 text-gray-800` }
                onClick={ startAgain }
                type="button">
                <span className="mr-2">Start Again</span>
                <ReloadIcon size={ 20 } color={ '#919191' } />
            </button>
        </div>
    )
};

export default StudyEndScreen;