import { NavLink } from 'react-router-dom';
import { DragEvent } from 'react';
import { closeMobileMenu } from '../../../../menu/ui/mobile-menu-provider';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import {
    ESelectedLibraryItemType,
    ILibraryFolder,
} from '../../interfaces';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import { closeLibraryFolder, openLibraryFolder } from '../../domain/library-storage';
import LibraryDataAuthor from './library-menu-author';
import { useLibraryStore } from '../../data/library-store';

interface ILibraryDataItemProps {
    folder: ILibraryFolder;
}

const DRAG_CLASS_NAME = 'library-folder__drag-target__over';

const LibraryDataItem = ({ folder }: ILibraryDataItemProps) => {

    const libraryData = useLibraryStore(state => state.libraryData);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(state => state.setSelectedLibraryItem);

    const onClick = () => {
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.FOLDER,
            id: folder.folder_id,
        });

        closeMobileMenu();
    };

    const toggleFolder = () => {
        if(folder.isOpened) {
            closeLibraryFolder(folder.folder_id);
        }
        else{
            openLibraryFolder(folder.folder_id);
        }

        folder.isOpened = !folder.isOpened;
        setLibraryData(JSON.parse(JSON.stringify(libraryData)))
    };

    const targetOnDragOver = (evt: DragEvent<HTMLAnchorElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLAnchorElement>) => {
        evt.currentTarget.classList.add(DRAG_CLASS_NAME);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLAnchorElement>) => {
        evt.currentTarget.classList.remove(DRAG_CLASS_NAME);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (_evt: DragEvent<HTMLAnchorElement>) => {
        /*evt.currentTarget.classList.remove(DRAG_CLASS_NAME);
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data))) {
            showToast('Drop task error.');
            return;
        }

        const sourceTodoId = Number(data);
        const new_library_id = library.library_id;
        console.log(`todo (${ sourceTodoId }): to ${ new_library_id })`);

        const response = await dragTodoToLibrary(
            new_library_id,
            sourceTodoId,
        );

        if(!response) {
            showToast('Drag task to library error.');
            return;
        }

        setLibraryData(formatLibraryData(response));*/
    };

    const nestedFoldersCount = Number(folder.nestedFolders?.length) || 0;
    const nestedAuthorsCount = Number(folder.authors?.length) || 0;
    const nestedCount = nestedFoldersCount + nestedAuthorsCount;

    return (
        <div>
            <NavLink
                onClick={ onClick }
                onDragOver={ targetOnDragOver }
                onDragEnter={ targetOnDragEnter }
                onDragLeave={ targetOnDragLeave }
                onDrop={ targetOnDrop }
                to={ `/library/f-${ folder.folder_id }` }
                className="library-folder__menu-item border-b border-slate-700 flex py-2 flex items-center whitespace-pre-wrap">

                {/*<LibraryDataDragButton library={ library } />*/}

                <div className="flex items-center">

                    <button
                        onClick={ toggleFolder }
                        type="button">
                        {
                            folder.isOpened &&
                            <ArrowDownIcon
                                classes="mr-2"
                                style={{
                                    marginTop: '-6px',
                                }}
                                color="#64748B"
                                strokeWidth={ 1.5 }
                                size={ 20 }
                            />
                        }

                        {
                            !folder.isOpened &&
                            <ArrowRightIcon
                                classes="mr-2"
                                color="#64748B"
                                strokeWidth={ 1.5 }
                                size={ 20 }
                            />
                        }
                    </button>

                    <div className="flex items-center">
                        <div
                            className="flex"
                            dangerouslySetInnerHTML={{__html: markdown2Html(folder.folder_title) }}
                        />

                        {
                            (nestedCount > 0) &&
                            <div className="text-xs ml-2">({ nestedCount })</div>
                        }
                    </div>

                </div>
            </NavLink>

            {
                folder.isOpened && (
                    <div className={ `ml-4 ${ nestedAuthorsCount > 0 ? 'pb-2' : '' }` }>
                        {
                            nestedFoldersCount > 0 && folder.nestedFolders.map(nestedFolder => {
                                return (
                                    <LibraryDataItem
                                        key={ `folder-${ nestedFolder.folder_id }` }
                                        folder={ nestedFolder }
                                    />
                                )
                            })
                        }

                        {
                            nestedAuthorsCount > 0 && folder.authors.map(author => {
                                return (
                                    <LibraryDataAuthor
                                        key={ `author-${ author.author_id }` }
                                        author={ author }
                                    />
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
};

export default LibraryDataItem;