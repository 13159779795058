import StudyButtons from './study-buttons';
import { ArrowRightIcon } from '../../../../common/ui/icons';
import { useState } from 'react';
import StudyEndScreen from './study-end-screen';
import { CardDescriptionMode } from '../cards/description/card-description';
import EditCardForm from '../cards/card-edit-form';
import CardItemName from '../cards/card-item-title';
import Preview from './preview';
import { FlashCardsStudyMode } from '../../flashcards-interfaces';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface INextStudyCard {
    updateStartAgainIndex: () => void;
}

const NextStudyCard = ({ updateStartAgainIndex }: INextStudyCard) => {

    const learningFlowCards = useFlashCardsStore(state => state.learningFlowCards);
    const learningFlowIndex = useFlashCardsStore(state => state.learningFlowIndex);
    const flashCardsStudyMode = useFlashCardsStore(state => state.flashCardsStudyMode);
    const setFlashCardsStudyMode = useFlashCardsStore(state => state.setFlashCardsStudyMode);

    const nextCard = learningFlowCards ? learningFlowCards[learningFlowIndex] : undefined;
    const [editMode, setEditMode] = useState<CardDescriptionMode>(CardDescriptionMode.DEFAULT_SHORT);

    const showAnswer = () => {
        setFlashCardsStudyMode(FlashCardsStudyMode.Answer);
    };

    const showQuestion = () => {
        setFlashCardsStudyMode(FlashCardsStudyMode.Question);
    };

    const endStudy = () => {
        setFlashCardsStudyMode(FlashCardsStudyMode.StudyEnd);
    };

    const edit = () => {
        if(!nextCard) return;
        setEditMode(CardDescriptionMode.EDIT);
    };

    return (
        <>
            {
                nextCard &&
                <div>

                    {
                        flashCardsStudyMode !== FlashCardsStudyMode.StudyEnd &&
                        <>

                            {
                                editMode !== CardDescriptionMode.EDIT &&
                                <>
                                    <CardItemName
                                        card={ nextCard }
                                        index={ 0 }
                                        hideActions={ true }
                                        hideIndex={ true }
                                    />

                                    {
                                        (nextCard.card_front?.trim() !== '') &&
                                        <div
                                            className="border rounded-md p-4 bg-slate-50 border-slate-100 text-slate-800 markdown whitespace-pre-wrap"
                                            dangerouslySetInnerHTML={{__html: markdown2Html(nextCard.card_front) }}
                                        />
                                    }

                                    <button
                                        onClick={ edit }
                                        className="text-xs underline mb-6"
                                        type="button">
                                        Edit
                                    </button>
                                </>
                            }

                            {
                                editMode === CardDescriptionMode.EDIT &&
                                <>
                                    <EditCardForm
                                        card={ nextCard }
                                        setMode={ setEditMode }
                                    />

                                </>
                            }

                            {
                                flashCardsStudyMode === FlashCardsStudyMode.Question &&
                                <button
                                    className={ `pr-4 pl-6 py-2 rounded-lg text-sm md:mr-4 flex items-center justify-center mb-4 md:mb-0 w-full md:w-auto bg-gray-100 text-gray-800` }
                                    onClick={ showAnswer }
                                    type="button">
                                    <span className="mr-2">Show Answer</span>
                                    <ArrowRightIcon />
                                </button>
                            }

                            {
                                flashCardsStudyMode === FlashCardsStudyMode.Answer &&
                                <>
                                    <Preview html={ markdown2Html(nextCard.card_back) } />

                                    <StudyButtons
                                        card={ nextCard }
                                        showQuestion={ showQuestion }
                                        endStudy={ endStudy }
                                    />
                                </>
                            }
                        </>
                    }

                    {
                        flashCardsStudyMode === FlashCardsStudyMode.StudyEnd &&
                        <StudyEndScreen
                            showQuestion={ showQuestion }
                            updateStartAgainIndex={ updateStartAgainIndex }
                        />
                    }
                </div>
            }
        </>
    )
};

export default NextStudyCard;