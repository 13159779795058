import { useOverviewStore } from '../../data/overview-store';

const TopMetadata = () => {

    const overviewData = useOverviewStore(state => state.overviewData);

    return (
        <div className="text-sm text-right ml-auto">Total: <b>{ overviewData.total }</b></div>
    )
};

export default TopMetadata;