import { EditIcon } from '../../../../../common/ui/icons';
import { ADD_NEW_SECTION_DESCRIPTION_EVENT } from '../description/section-description';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface ISectionEditButtonProps {
    section_id: number;
}

const SectionEditButton = (props: ISectionEditButtonProps) => {

    const { section_id } = props;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_SECTION_DESCRIPTION_EVENT, {
            detail: section_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Section"
            className="section__add-desc flex bg-slate-300 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default SectionEditButton;