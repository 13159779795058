import { ESelectedLibraryItemType, ILibraryTag } from '../../../interfaces';
import { removeTagFromBook } from '../../../data/library-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../../domain/library-provider';
import { CloseIcon } from '../../../../../common/ui/icons';
import { useLibraryStore } from '../../../data/library-store';

interface IBookTagProps {
    tag: ILibraryTag;
    book_id: number;
}

const BookTag = ({ tag, book_id }: IBookTagProps) => {

    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const remove = async () => {
        const response = await removeTagFromBook(
            tag.tag_id,
            book_id,
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
        );

        if(!response) {
            showToast('Remove tag from book error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
    };

    return (
        <div
            className="mr-4 flex items-center"
            key={ tag.tag_id }>

            <span className="mr-1">{ tag.tag_title }</span>

            <button
                onClick={ remove }
                className="text-red-800"
                type="button">
                <CloseIcon
                    size={ 15 }
                    color={ `#E47D75` }
                />
            </button>
        </div>
    )
};

export default BookTag;