import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent, useCallback } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { ESelectedLibraryItemType, ILibraryAuthor, ILibraryBook } from '../../interfaces';
import { updateBook } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../domain/library-provider';
import { useLibraryStore } from '../../data/library-store';

interface IEditBookProps {
    author: ILibraryAuthor;
    book: ILibraryBook;
    close: () => void;
}

export const BOOK_EDIT_EVENT = 'BOOK_EDIT_EVENT';

const EditBook = ({ author, book, close }: IEditBookProps) => {

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState(book.book_title);
    const [titleTouched, setTitleTouched] = useState(false);
    const [numberInSerie, setNumberInSerie] = useState(book.number_in_serie);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, []);

    useEffect(() => {
        descriptionEditorRef.current?.setMarkdown(book.book_desc || '');
    }, [book.book_desc]);

    const save = useCallback(async () => {
        const desc = descriptionEditorRef.current?.getMarkdown() || '';

        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const response = await updateBook(
            author.author_id,
            book.book_id,
            title.trim(),
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
            desc.trim(),
            undefined,
            book.serie_id,
            numberInSerie,
        );

        setLoading(false);

        if(!response) {
            showToast('Update book error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));

        close();
    },
        // eslint-disable-next-line
        [
            author.author_id,
            setLibraryData,
            title,
            selectedLibraryItem?.id,
            selectedLibraryItem?.type,
            book.book_id,
            book.serie_id,
            numberInSerie,
            close,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                descriptionEditorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onNumberInSerieChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const number = Number((evt.target as HTMLInputElement).value) || undefined;
        setNumberInSerie(number);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div className="border border-slate-200 text-slate-800 bg-slate-50 rounded p-4 text-sm flex flex-col my-4">

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-500 mb-2">Book Title</div>
                <input
                    ref={ titleRef }
                    value={ title }
                    onInput={ onTitleChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isTitleValid && <div className="text-red-700 text-xs mt-1">The book title is required.</div>
                }
            </label>

            <label className="font-bold flex flex-col text-slate-500 mb-2">Description</label>
            <div className="markdown-editor border rounded-lg mb-4 bg-white">
                <MDXEditor
                    ref={ descriptionEditorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            {
                !!book.serie_id &&
                <>
                    <label className="font-bold flex flex-col text-slate-500 mb-2">Number in serie</label>
                    <input
                        className="border rounded px-4 py-2 outline-stone-200"
                        type="number"
                        min={ 0 }
                        value={ numberInSerie }
                        onChange={ onNumberInSerieChange }
                    />
                </>
            }

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ close }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default EditBook;