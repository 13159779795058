import { useState } from 'react';
import { ILibraryTag, ILibraryTagViewFormatted } from '../../../interfaces';
import Actions from '../../../../../common/ui/actions';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';
import TextBox from '../../../../../common/ui/forms/text-box';
import DeleteTag from './actions/delete-tag';
import { updateTag } from '../../../data/library-data-service';
import { formatTagsData } from '../../../domain/library-provider';
import { showToast } from '../../../../../common/domain/toast-provider';

interface ITagTitleProps {
    tag: ILibraryTag;
    setTagsView: (formattedData: ILibraryTagViewFormatted) => void;
}

export enum TagTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

const TagTitle = ({ tag, setTagsView }: ITagTitleProps) => {

    const [mode, setMode] = useState<TagTitleMode>(TagTitleMode.DEFAULT);
    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);

    const startEdit = () => {
        setMode(TagTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(TagTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);

        const response = await updateTag(tag.tag_id, updatedText.trim());
        setUpdateTitleLoading(false);

        if(!response) {
            showToast('Update tag title error.');
            return;
        }

        cancelEdit();

        setTagsView(formatTagsData(response));
    };

    return (
        <div>
            {
                mode === TagTitleMode.DEFAULT &&
                <div className="library-tag__title flex items-center bg-gray-50 text-md py-2 pl-4 rounded">
                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left font-semibold">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(tag.tag_title) }} />

                        <span className="text-sm font-normal ml-4">{ tag.books ? tag.books.length : 0 }</span>
                    </button>

                    <Actions classes="library-tag__actions" gridSize={ 1 }>

                        <DeleteTag
                            tag={ tag }
                            setTagsView={ setTagsView }
                        />

                    </Actions>
                </div>
            }

            {
                mode === TagTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ tag.tag_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </div>
    )
};

export default TagTitle;