import { MouseEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { ProjectDescriptionMode } from './project-description';

interface IProjectFullDescriptionProps {
    parsedDesc: string;
    setMode: (mode: ProjectDescriptionMode) => void;
}

const ProjectFullDescription = ({ parsedDesc, setMode }: IProjectFullDescriptionProps) => {

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setDefaultMode = () => {
        setMode(ProjectDescriptionMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(ProjectDescriptionMode.EDIT);
    };

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setDefaultMode();
        }
    };

    return (
        <div className="flex flex-col">
            <div
                ref={ contentRef }
                tabIndex={ 0 }
                className="text-xs overflow-auto max-h-[500px] rounded my-1 outline-none"
                onClick={ onClick }
                onKeyDown={ onKeyDown }>
                <div
                    className="markdown whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{__html: parsedDesc }}
                />
            </div>
        </div>
    )
};

export default ProjectFullDescription;