import Actions from '../../../../common/ui/actions';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { ILibraryAuthor } from '../../interfaces';
import DeleteAuthorButton from './actions/author-delete-btn';
import EditAuthorBtn from './actions/edit-author-btn';
import CloseAllSeriesBtn from './actions/author-close-series-btn';

interface IAuthorTitleProps {
    author: ILibraryAuthor;
    startEdit: () => void;
}

const AuthorTitle = ({ author, startEdit }: IAuthorTitleProps) => {

    return (
        <h1 className={ `library-author__title flex items-center text-xl xl:text-3xl border-b mb-4 pb-2` }>
            <button
                onClick={ startEdit }
                type="button">
                <span dangerouslySetInnerHTML={{__html: markdown2Html(`${ author.author_first_name } ${ author.author_last_name }`) }} />
            </button>

            <Actions classes="gallery__actions" gridSize={ 3 }>

                <DeleteAuthorButton author={ author } />
                <EditAuthorBtn startEdit={ startEdit } />
                <CloseAllSeriesBtn author={ author } />

            </Actions>
        </h1>
    )
};

export default AuthorTitle;