import { ChangeEvent, useState } from 'react';
import { uploadTodoAttachment } from '../../../data/planner-data-service';
import { AttachmentIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useParams } from 'react-router-dom';
import { combineProjectData } from '../../../domain/planner-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoUploadAttachmentButtonProps {
    todo: ITodo;
}

const TodoUploadAttachmentButton = (props: ITodoUploadAttachmentButtonProps) => {

    const { todo } = props;

    const [isLoading, setLoading] = useState(false);

    const { project_id: project_id_url, filter_id } = useParams();

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const uploadFileHandler = async (evt: ChangeEvent<HTMLInputElement>) => {

        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload error.');
            return;
        }

        setLoading(true);

        for(const file of evt.target.files) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('todo_id', todo.todo_id.toString());
            formData.append('project_id_url', project_id_url ?? filter_id);
            formData.append('now', Date.now().toString());
            const response = await uploadTodoAttachment(formData as FormData);

            if(!response || !response.isValid) {
                showToast('Upload error.');
                setLoading(false);

                setCombinedProject(combineProjectData(response));
                return;
            }

            setCombinedProject(combineProjectData(response));
        }

        setLoading(false);
        showToast('Upload OK.', false);

        sendCloseActionsEvent();
    };

    return (
        <label
            title="Upload File"
            className="todo__attachment-btn flex bg-sky-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">

            {
                !isLoading &&
                <AttachmentIcon size={ 20 } color="#204A6E" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#204A6E' } />
            }

            <input
                onChange={ uploadFileHandler }
                className="hidden"
                type="file"
                multiple={ true }
            />
        </label>
    )
};

export default TodoUploadAttachmentButton;