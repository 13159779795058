import { useEffect, useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import Actions from '../../../../common/ui/actions';
import { getTextFromMarkdown, markdown2Html } from '../../../../common/markdown/markdown-provider';
import { ESelectedLibraryItemType, ILibraryFolder } from '../../interfaces';
import { updateLibraryFolderTitle } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../domain/library-provider';
import EditFolderDescriptionBtn from './actions/edit-folder-description-btn';
import DeleteFolderButton from './actions/folder-delete-btn';
import { useLibraryStore } from '../../data/library-store';

export enum FolderTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IFolderTitleProps {
    folder: ILibraryFolder;
    startEditDesc: () => void;
}

const FolderTitle = ({ folder, startEditDesc }: IFolderTitleProps) => {

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<FolderTitleMode>(FolderTitleMode.DEFAULT);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    useEffect(() => {
        document.title = getTextFromMarkdown(folder.folder_title);
    }, [folder.folder_title]);

    const startEdit = () => {
        setMode(FolderTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(FolderTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateLibraryFolderTitle(
            folder.folder_id,
            updatedText.trim(),
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
        );

        setUpdateTitleLoading(false);

        if(!response) {
            showToast('Update folder title error.');
            return;
        }

        cancelEdit();

        setLibraryData(formatLibraryData(response || []));
    };

    return (
        <h1 className={ `library-folder__title flex items-center text-xl xl:text-3xl border-b mb-2 pb-2` }>
            {
                mode === FolderTitleMode.DEFAULT &&
                <>
                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(folder.folder_title) }} />

                        {/*<span className="text-sm font-normal ml-4">{ count }</span>*/}
                    </button>

                    <Actions classes="gallery__actions">

                        <DeleteFolderButton
                            folder_id={ folder.folder_id }
                            folder_title={ folder.folder_title }
                        />

                        <EditFolderDescriptionBtn
                            startEditDesc={ startEditDesc }
                        />
                    </Actions>
                </>
            }

            {
                mode === FolderTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ folder.folder_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h1>
    )
};

export default FolderTitle;