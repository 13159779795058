import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect } from 'react';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import FlashCardsMenu from './ui/menu/flashcards-menu';
import { getFlashCardsData } from './data/flashcards-data-service';
import { showToast } from '../../common/domain/toast-provider';
import EmptyScreen from './ui/empty-screen';
import { useLocation, useParams } from 'react-router-dom';
import DeckView from './ui/deck/deck-view';
import { formatFlashCardsTree } from './domain/flashcards-provider';
import { FlashCardsListView } from './flashcards-interfaces';
import FlashCardsFolderView from './ui/folders/folder-view';
import FlashCardsSearchView from './ui/search/search-view';
import { useFlashCardsStore } from './data/flashcards-store';

const FlashcardsHome = () => {

    const { loading } = useAuthStatus();

    const selectedDeck = useFlashCardsStore(state => state.selectedDeck);
    const selectedFolder = useFlashCardsStore(state => state.selectedFolder);

    const flashCardsListType = useFlashCardsStore(state => state.flashCardsListType);
    const setFlashCardsListType = useFlashCardsStore(state => state.setFlashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(state => state.setFlashCardsTree);

    const { selected_item_id } = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = 'Flashcards | Productivity';
    }, []);

    useEffect(() => {

        if(pathname === '/flashcards/search') {
            setFlashCardsListType(FlashCardsListView.SearchView);
            return;
        }

        if(!selected_item_id) {
            setFlashCardsListType(FlashCardsListView.ROOT);
            return;
        }

        if(selected_item_id?.startsWith('f-')) {
            setFlashCardsListType(FlashCardsListView.FolderView);
            return;
        }

        setFlashCardsListType(FlashCardsListView.DeckView);
    }, [setFlashCardsListType, selected_item_id, pathname]);

    useEffect(() => {
        if(loading) return;

        (async () => {
            let deck_id = undefined;
            let folder_id = undefined;

            if(selected_item_id) {
                if(selected_item_id.startsWith('f-')) {
                    folder_id = Number(selected_item_id.replace('f-', '')) || 0;
                }
                else{
                    deck_id = Number(selected_item_id) || 0;
                }
            }

            const response = await getFlashCardsData(flashCardsListType, deck_id);

            if(!response) {
                showToast('Get flashcards data error.');
                return;
            }

            setFlashCardsTree(formatFlashCardsTree(response, deck_id, folder_id));
        })();
    }, [
        loading,
        selected_item_id,
        flashCardsListType,
        setFlashCardsTree,
    ]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <FlashCardsMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 flex-1 flex">

                            <div className="xl:max-w-[1100px] max-w-full mx-auto flex-1 flex flex-col">
                                { flashCardsListType === FlashCardsListView.ROOT && <EmptyScreen /> }
                                { flashCardsListType === FlashCardsListView.DeckView && selectedDeck && <DeckView /> }
                                { flashCardsListType === FlashCardsListView.FolderView && selectedFolder && <FlashCardsFolderView /> }
                                { flashCardsListType === FlashCardsListView.SearchView && <FlashCardsSearchView /> }
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default FlashcardsHome;