import { useEffect, useState } from 'react';
import { getAuthStatus } from '../data/auth-data-service';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../../common/ui/preloader';
import DoubleOptIn from './forms/double-opt-in';
import LoginForm from './forms/login-form';

export enum LoginMode {
    INIT = 0,
    WAIT_FOR_DOUBLE_OPT_IN_CODE = 1,
}

export const Login = () => {

    const [loginMode, setLoginMode] = useState(LoginMode.INIT);
    const [generalLoading, setGeneralLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Login | Productivity`;
    }, []);

    // If user is already logged in ---> go to the home page.
    useEffect(() => {
        (async () => {
            const authStatus = await getAuthStatus();
            if(authStatus?.loggedIn) {
                navigate('/planner/today');
                return;
            }
            setGeneralLoading(false);
        })();
    }, [navigate]);

    return (
        <>
            {
                generalLoading && <Preloader size={ 40 } />
            }

            {
                !generalLoading &&
                <div className="mt-10 mx-4 text-slate-700">
                    <div className="w-[400px] max-w-full m-auto border rounded py-4 px-6">

                        <h1 className="mb-6 text-3xl">Login</h1>

                        {
                            (loginMode === LoginMode.INIT) &&
                            <LoginForm setLoginMode={ setLoginMode } />
                        }

                        {
                            (loginMode === LoginMode.WAIT_FOR_DOUBLE_OPT_IN_CODE) &&
                            <DoubleOptIn setLoginMode={ setLoginMode } />
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default Login;
