import { DeleteIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { useState } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatDescartesSquareData } from '../../domain/descartes-square-provider';
import { deleteTodoVariant } from '../../data/descartes-square-data-service';
import { useDescartesSquareStore } from '../../data/descartes-square-store';

interface IDeleteTodoVariantBtn {
    todo_variant_id: number;
}

const DeleteTodoVariantBtn = ({ todo_variant_id }: IDeleteTodoVariantBtn) => {

    const [isLoading, setLoading] = useState(false);

    const setDescartesSquare = useDescartesSquareStore(store => store.setDescartesSquare);
    const todo_parent_id = useDescartesSquareStore(store => store.todo_parent_id);

    const deleteSectionHandler = async () => {

        if(!confirm(`Are you sure you want to delete this variant?`)) return;

        setLoading(true);
        const response = await deleteTodoVariant(
            todo_variant_id,
            todo_parent_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete todo variant error.');
            return;
        }

        setDescartesSquare(formatDescartesSquareData(response));
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Section"
                    className="section__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteSectionHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteTodoVariantBtn;