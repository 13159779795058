import { ChangeEvent } from 'react';
import { useOverviewStore } from '../../data/overview-store';

export enum HasDateFilterEnum {
    NoDate = 0,
    HasDate = 1,
    Both = 2,
}

const HasDateFilter = () => {

    const hasDateFilter = useOverviewStore(state => state.hasDateFilter);
    const setHasDateFilter = useOverviewStore(state => state.setHasDateFilter);

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _hasDateFilter: HasDateFilterEnum = Number((evt.target as HTMLSelectElement).value) ?? HasDateFilterEnum.Both;
        setHasDateFilter(_hasDateFilter);
    };

    return (
        <label className="text-xs flex items-center mr-10">
            <select
                value={ hasDateFilter }
                onChange={ onChange }
                className="outline-none">
                <option value={ HasDateFilterEnum.HasDate }>Has Date</option>
                <option value={ HasDateFilterEnum.NoDate }>No Date</option>
                <option value={ HasDateFilterEnum.Both }>Both</option>
            </select>
        </label>
    )
};

export default HasDateFilter;