import { DragEvent } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { dragDeckToAnotherFolder } from '../../../data/flashcards-data-service';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDragDeckToFolderItemProps {
    folder_id: number;
}

const DRAG_CLASS = 'flash-cards__drag-deck-to-folder-target__over';

const DragDeckToFolderItem = ({ folder_id }: IDragDeckToFolderItemProps) => {

    // const navigate = useNavigate();

    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);
    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(DRAG_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(DRAG_CLASS);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '') {
            showToast('Drop deck error.');
            return;
        }

        const deck_id = Number(data);

        if(isNaN(Number(deck_id))) {
            showToast('Drop deck error.');
            return;
        }

        const response = await dragDeckToAnotherFolder(
            flashCardsListType,
            folder_id,
            deck_id,
        );

        if(!response) {
            showToast('Drag deck error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response));

        // navigate(`/gallery/${ album_id }`);

        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className="flash-cards__drag-deck-to-folder-target h-[5px] bg-slate-500 rounded my-1 hidden ml-2"
        ></div>
    )
};

export default DragDeckToFolderItem;