import PageButton from './page-btn';
import { createPage, HALF_RANGE } from '../../domain/pagination-provider';
import PaginationLabel from './pagination-label';
import { useOverviewStore } from '../../data/overview-store';

const Pagination = () => {

    const overviewData = useOverviewStore(state => state.overviewData);
    const currentPage = useOverviewStore(state => state.currentPage);

    const isFirstPage = currentPage <= 0;
    const isLastPage = currentPage >= overviewData.paging.pagesNumber - 1;

    const showPagination = overviewData.paging.pagesNumber > 1;

    return (
        <>
            {
                showPagination &&
                <div className="flex items-center justify-center my-10 text-sm">
                    <PaginationLabel />

                    <div className="flex items-center">
                        {
                            !isFirstPage &&
                            <PageButton page={ createPage(0, currentPage) } title="<<" />
                        }

                        {
                            (currentPage > HALF_RANGE) &&
                            <PageButton page={ createPage(currentPage - 1, currentPage) } title="<" />
                        }

                        {
                            overviewData.paging.pages.map(page => {
                                return (
                                    <PageButton key={ page.index } page={ page } />
                                )
                            })
                        }

                        {
                            !isLastPage &&
                            <PageButton page={ createPage(currentPage + 1, currentPage) } title=">" />
                        }

                        {
                            !isLastPage &&
                            <PageButton page={ createPage(overviewData.paging.pagesNumber - 1, currentPage) } title=">>" />
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default Pagination;