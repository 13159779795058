import { useEffect, useState } from 'react';
import { XSLT } from './fb2.xsl';
import '../../../../../../css/fb2.pcss';

interface IFB2View {
    xml: string;
}

const Fb2View = ({ xml }: IFB2View) => {

    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {

        try{
            // Parse XML and XSL into DOM documents
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xml, 'text/xml');
            const xslDoc = parser.parseFromString(XSLT, 'text/xml');

            // Perform the transformation
            const xsltProcessor = new XSLTProcessor();
            xsltProcessor.importStylesheet(xslDoc);
            const resultDocument = xsltProcessor.transformToFragment(xmlDoc, document);

            // Serialize the result document to a string
            const serializer = new XMLSerializer();
            const resultHtml = serializer.serializeToString(resultDocument);

            // Set the transformed HTML as state
            setHtmlContent(resultHtml);
        }
        catch (ex) {
            console.error(ex);
        }
    }, [xml]);

    return (
        <div
            className="fb2-box w-[50em] max-w-full mx-auto"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    )
};

export default Fb2View;