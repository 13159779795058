import { TodoType } from '../../../../planner/planner-interfaces';
import TodoTypeFilterCheckbox from './todo-type-filter-cb';

const TodoTypeFilter = () => {

    return (
        <div className="flex items-center text-xs">
            <TodoTypeFilterCheckbox todo_type={ TodoType.Problem } title="Problem" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Goal } title="Goal" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Idea } title="Idea" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Event } title="Event" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Task } title="Task" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.RegularTask } title="RegularTask" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Category } title="Category" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Summary } title="Summary" />
            <TodoTypeFilterCheckbox todo_type={ TodoType.Default } title="Default" />
        </div>
    )
};

export default TodoTypeFilter;