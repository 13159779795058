import { DragEvent } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { moveCardToAnotherDeck } from '../../../data/flashcards-data-service';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDragCardToAnotherDeckTarget {
    targetDeckId: number;
}

const DRAG_CLASS = 'drag-card-to-another-deck-target__over';

const DragCardToAnotherDeckTarget = ({ targetDeckId }: IDragCardToAnotherDeckTarget) => {

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const selectedDeck = useFlashCardsStore(state => state.selectedDeck);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(DRAG_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(DRAG_CLASS);
    };

    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data.trim()))) {
            showToast('Move flashcard to another deck error.');
            return;
        }

        const cardId = Number(data);

        const response = await moveCardToAnotherDeck(
            selectedDeck?.deck_id ?? 0,
            targetDeckId,
            cardId,
            flashCardsListType,
        );

        if(!response?.isValid) {
            showToast('Move flashcard to another deck error.');
            return;
        }

        if(selectedDeck) {
            setFlashCardsTree(formatFlashCardsTree(response, selectedDeck.deck_id));
        }

        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className="drag-card-to-another-deck-target w-full h-[5px] bg-slate-500 rounded my-1 hidden"
        ></div>
    )
};

export default DragCardToAnotherDeckTarget;