import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType, ILibraryAuthor } from '../../../interfaces';
import { deleteBook } from '../../../data/library-data-service';
import { useLibraryStore } from '../../../data/library-store';

interface IDeleteBookProps {
    author: ILibraryAuthor;
    book_id: number;
    book_title: string;
}

const DeleteBookButton = ({ author, book_id, book_title }: IDeleteBookProps) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const navigate = useNavigate();

    const deleteBookHandler = async () => {

        if(!confirm(`Are you sure you want to delete the book "${ book_title }"?`)) return;

        setLoading(true);
        const response = await deleteBook(
            author.author_id,
            book_id,
            ESelectedLibraryItemType.AUTHOR,
            author.author_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete book error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.AUTHOR,
            id: author.author_id,
        });

        navigate('/library');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Book"
                    className="book__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteBookHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default DeleteBookButton;