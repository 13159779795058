import { ArrowRightIcon } from '../../../../common/ui/icons';
import { addMonths, startOfMonth } from 'date-fns';
import { useHabitTrackerStore } from '../../data/habit-tracker-store';

const NextMonthButton = () => {

    const habitTrackerStartDate = useHabitTrackerStore(store => store.habitTrackerStartDate);
    const setHabitTrackerStartDate = useHabitTrackerStore(store => store.setHabitTrackerStartDate);

    const jumpToNextMonth = () => {
        setHabitTrackerStartDate(startOfMonth(addMonths(habitTrackerStartDate, 1)));
    };

    return (
        <button
            onClick={ jumpToNextMonth }
            type="button">
            <ArrowRightIcon />
        </button>
    )
};

export default NextMonthButton;