import { useState } from 'react';
import { deleteProject } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { usePlannerStore } from '../../../data/planner-store';

interface IDeleteProjectProps {
    project_id: number;
    project_title: string;
}

const DeleteProjectButton = ({ project_id, project_title }: IDeleteProjectProps) => {

    const [isLoading, setLoading] = useState(false);

    const setMenuProjects = usePlannerStore(state => state.setMenuProjects);

    const navigate = useNavigate();

    const deleteProjectHandler = async () => {

        if(!confirm(`Are you sure you want to delete the project "${ project_title }"?`)) return;

        setLoading(true);
        const response = await deleteProject(project_id);
        setLoading(false);

        if(!response.isValid) {
            showToast('Delete project error.');
            return;
        }

        setMenuProjects(response?.data || []);

        navigate('/planner/today');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Project"
                    className="project__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteProjectHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteProjectButton;