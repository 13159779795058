import { TodoType } from '../../../planner/planner-interfaces';
import { getTodoTypeColor, getTodoTypeTitle } from '../../../planner/domain/todo-type-provider';

interface INestedTodosCount {
    todo_type: TodoType;
    x: number;
    y: number;
}

const TODOS_COUNT_LEFT_MARGIN = 15;
const FONT_SIZE = 12;

const TodoTypeText = ({ todo_type, x, y }: INestedTodosCount) => {
    return (
        <text
            data-type="todo-type-text"
            x={ x + TODOS_COUNT_LEFT_MARGIN }
            y={ y - FONT_SIZE/2 }
            fontSize={ FONT_SIZE }
            fill={ getTodoTypeColor(todo_type) }
        >{ getTodoTypeTitle(todo_type) }</text>
    )
};

export default TodoTypeText;