import { format, isBefore, isToday, isTomorrow, isYesterday } from 'date-fns';
import { ITodo } from '../../planner-interfaces';
import { ADD_NEW_TODO_DESCRIPTION_EVENT } from './description/todo-description';

interface ITodoDate {
    todo: ITodo;
}

export interface IDateData {
    dateString: string;
    isOverdue: boolean;
    colorClass: string;
    color: string;
}

export const getDateData = (todo: ITodo) : IDateData => {
    if(!todo.todo_date) {
        return {
            dateString: '',
            isOverdue: false,
            colorClass: '',
            color: '',
        };
    }

    const today = new Date();
    const todo_date = new Date(todo.todo_date);

    const isOverdue = isBefore(todo_date, today);
    const colorClass = (isOverdue && !todo.is_completed) ? 'text-pink-800' : 'text-violet-800';
    const color = (isOverdue && !todo.is_completed) ? 'rgb(157 23 77)' : 'rgb(91 33 182)';

    if(todo.todo_date_has_time) {
        return {
            dateString: format(todo.todo_date, 'dd MMM yyyy HH:mm (E)'),
            isOverdue,
            colorClass,
            color,
        };
    }

    if(isToday(todo_date)) {
        return {
            dateString: 'Today',
            isOverdue,
            colorClass: 'text-emerald-600',
            color: 'rgb(5 150 105)',
        };
    }

    if(isYesterday(todo_date)) {
        return {
            dateString: 'Yesterday',
            isOverdue,
            colorClass,
            color,
        };
    }

    if(isTomorrow(todo_date)) {
        return {
            dateString: 'Tomorrow',
            isOverdue,
            colorClass,
            color,
        };
    }

    return {
        dateString: format(todo.todo_date, 'dd MMM yyyy (E)'),
        isOverdue,
        colorClass,
        color,
    };
};

const TodoDate = ({ todo }: ITodoDate) => {

    const dateData = getDateData(todo);

    const hasNestedTodos = todo.nested?.length > 0;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_DESCRIPTION_EVENT, {
            detail: todo.todo_id,
        }));
    };

    return (
        <button
            type="button"
            onClick={ sendEvent }
            className={ `todo-date flex text-xs leading-loose xl:leading-normal mr-4 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' } ${ dateData.colorClass }` }>
            { dateData.dateString }
        </button>
    )
};

export default TodoDate;