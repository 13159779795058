import { useAuthStatus } from '../../../../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect, useState } from 'react';
import Preloader from '../../../../../common/ui/preloader';
import MobileMenuBtn from '../../../../../menu/ui/mobile-menu-btn';
import Menu from '../../../../../menu/menu';
import LibraryMenu from '../../menu/library-menu';
import { getTagsView } from '../../../data/library-data-service';
import { ILibraryTagViewFormatted } from '../../../interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import TagItem from './tag-item';
import { formatTagsData } from '../../../domain/library-provider';

const LibraryTagsView = () => {

    const { loading } = useAuthStatus();

    const [tagsView, setTagsView] = useState<ILibraryTagViewFormatted|null>(null);

    useEffect(() => {
        document.title = 'Library Tags | Productivity';
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getTagsView();

            if(!response) {
                showToast('Get tags error.');
            }

            setTagsView(formatTagsData(response));
        })();
    }, []);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <LibraryMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 py-10 flex-1 flex flex-col">

                            <div className="w-full xl:w-[800px] max-w-full mx-auto flex-1 flex flex-col">
                                <div className="mb-6 w-full flex flex-col flex-1">

                                    <h1 className="text-xl xl:text-3xl border-b mb-2 pb-2">Tags</h1>

                                    {
                                        tagsView &&
                                        <>
                                            {
                                                tagsView.tags.map(tag => {
                                                    return (
                                                        <TagItem
                                                            setTagsView={ setTagsView }
                                                            key={ tag.tag_id }
                                                            tag={ tag }
                                                        />
                                                    )
                                                })
                                            }
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default LibraryTagsView;