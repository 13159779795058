import DiagramNode from './diagram-node';
import DiagramEdge from './diagram-edge';
import { useDiagramStore } from '../../data/diagram-store';

const DiagramView = () => {

    const diagram = useDiagramStore(store => store.diagram);

    return (
        <>
            {
                diagram &&
                <svg
                    id="diagram-svg"
                    width={ diagram.width }
                    height={ diagram.height }
                    viewBox={ `0 0 ${ diagram.width } ${ diagram.height }` }
                    className="max-w-full mx-auto overflow-visible"
                    xmlns="http://www.w3.org/2000/svg">

                    <DiagramNode
                        todo={ diagram.root_todo }
                        meta={ diagram.metaData.get(diagram.root_todo.todo_id) }
                    />

                    {
                        diagram.todos.length > 0 &&
                        <DiagramEdge
                            startMeta={ diagram.metaData.get(diagram.root_todo.todo_id) }
                            endMeta={ diagram.metaData.get(diagram.todos[0].todo_id) }
                        />
                    }

                    {
                        diagram.todos.map((todo, i) => {

                            return (
                                <>
                                    <DiagramNode
                                        key={ todo.todo_id }
                                        todo={ todo }
                                        meta={ diagram.metaData.get(todo.todo_id) }
                                    />

                                    {
                                        (i > 0) &&
                                        <DiagramEdge
                                            startMeta={ diagram.metaData.get(diagram.todos[i - 1].todo_id) }
                                            endMeta={ diagram.metaData.get(todo.todo_id) }
                                        />
                                    }
                                </>
                            )
                        })
                    }

                </svg>
            }
        </>
    )
};

export default DiagramView;