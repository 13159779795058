import Prism from 'prismjs';
import { useEffect } from 'react';
import 'prismjs/themes/prism-okaidia.min.css';

interface IPreview {
    html: string;
}

const Preview = ({ html }: IPreview) => {

    useEffect(() => {
        Prism.highlightAll();
    }, [html]);

    return (
        <div
            className="flashcards__learning-preview border rounded-md p-4 bg-slate-50 border-slate-100 text-slate-800 mb-6 markdown whitespace-pre-wrap"
            dangerouslySetInnerHTML={{__html: html }}
        />
    )
};

export default Preview;