import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { useHabitTrackerStore } from '../data/habit-tracker-store';

const HabitTrackerTitle = () => {

    const chartData = useHabitTrackerStore(store => store.chartData);

    return (
        <h1 className="text-xl xl:text-3xl flex items-center mb-6">
            <div dangerouslySetInnerHTML={{__html: markdown2Html(chartData?.parentTodo?.todo_title) }} />
        </h1>
    )
};

export default HabitTrackerTitle;