import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent, useCallback } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { ESelectedLibraryItemType, ILibraryAuthor, ILibrarySerie } from '../../interfaces';
import { updateSerie } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../domain/library-provider';
import SerieDescription from './serie-description';
import { useLibraryStore } from '../../data/library-store';

enum SerieMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IEditSerieProps {
    author: ILibraryAuthor;
    serie: ILibrarySerie;
}

export const SERIE_EDIT_EVENT = 'SERIE_EDIT_EVENT';

const EditSerie = ({ author, serie }: IEditSerieProps) => {

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    const [serieMode, setSerieMode] = useState(SerieMode.DEFAULT);

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState(serie.serie_title);
    const [titleTouched, setTitleTouched] = useState(false);

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [serieMode]);

    useEffect(() => {
        descriptionEditorRef.current?.setMarkdown(serie.serie_desc || '');
    }, [serie.serie_desc, serieMode]);

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const serie_id = Number(evt?.detail) || 0;
            if(serie_id !== serie.serie_id) return;

            setSerieMode(SerieMode.EDIT);
        };

        document.addEventListener(SERIE_EDIT_EVENT, handler);

        return () => {
            document.removeEventListener(SERIE_EDIT_EVENT, handler);
        };
    }, [serie.serie_id]);

    const startEditSerie = () => {
        setSerieMode(SerieMode.EDIT);
    };

    const stopEditSerie = () => {
        setSerieMode(SerieMode.DEFAULT);
    };

    const save = useCallback(async () => {
        const desc = descriptionEditorRef.current?.getMarkdown() || '';

        if(title.trim() === serie.serie_title.trim() && desc.trim() === (serie.serie_desc || '').trim()){
            stopEditSerie();
            return;
        }

        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const response = await updateSerie(
            author.author_id,
            serie.serie_id,
            title.trim(),
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
            desc.trim(),
        );

        setLoading(false);

        if(!response) {
            showToast('Update serie error.');
            return;
        }

        stopEditSerie();

        setLibraryData(formatLibraryData(response || []));
    },
        // eslint-disable-next-line
        [
            author.author_id,
            setLibraryData,
            title,
            selectedLibraryItem?.id,
            selectedLibraryItem?.type,
            serie.serie_id,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                descriptionEditorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div className="my-2">

            <SerieDescription
                serie={ serie }
                startEdit={ startEditSerie }
                close={ stopEditSerie }
            />

            {
                serieMode === SerieMode.EDIT &&
                <div className="border border-slate-200 text-slate-800 bg-slate-50 rounded p-4 text-sm flex flex-col">

                    <label className="flex flex-col mb-4">
                        <div className="font-bold text-slate-500 mb-2">Serie Title</div>
                        <input
                            ref={ titleRef }
                            onInput={ onTitleChange }
                            onKeyDown={ onKeyDown }
                            value={ title }
                            className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                            type="text"
                        />

                        {
                            !isTitleValid && <div className="text-red-700 text-xs mt-1">The serie title is required.</div>
                        }
                    </label>

                    <label className="font-bold flex flex-col text-slate-500 mb-2">Description</label>
                    <div className="markdown-editor border rounded-lg mb-4 bg-white">
                        <MDXEditor
                            ref={ descriptionEditorRef }
                            markdown={ '' }
                            plugins={ getCompactMarkdownEditorPlugins() }
                        />
                    </div>

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopEditSerie }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default EditSerie;