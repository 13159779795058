import { NavLink } from 'react-router-dom';
import { toggleMobileMenuBodyClass } from '../../../../menu/ui/mobile-menu-provider';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import { IFlashCardFolder } from '../../flashcards-interfaces';
import { closeFlashcardsFolder, openFlashcardsFolder } from '../../domain/flashcards-storage';
import { setFlashCardsFolderOpenedClosed } from '../../domain/flashcards-provider';
import FlashcardMenuItemDeck from './flashcard-menu-item-deck';
import DragDeckToFolderItem from './drag/drag-deck-to-folder-target';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IFlashcardMenuItem {
    folder: IFlashCardFolder;
}

const FlashcardMenuItemFolder = ({ folder }: IFlashcardMenuItem) => {

    const flashCardsTree = useFlashCardsStore(state => state.flashCardsTree);
    const setFlashCardsTree = useFlashCardsStore(state => state.setFlashCardsTree);

    const toggleFolder = () => {
        if(folder.isOpened) {
            closeFlashcardsFolder(folder.folder_id);
        }
        else{
            openFlashcardsFolder(folder.folder_id);
        }

        setFlashCardsTree(setFlashCardsFolderOpenedClosed(flashCardsTree));
    };

    const nestedFoldersCount = folder?.folders?.length ?? 0;
    const nestedDecksCount = folder?.decks?.length ?? 0;
    const count = nestedFoldersCount + nestedDecksCount;

    return (
        <div className="flex flex-col">

            <div className="flash-cards-menu-item-box flex items-center border-b border-slate-700 py-3">
                <button
                    onClick={ toggleFolder }
                    type="button">
                    {
                        folder.isOpened &&
                        <ArrowDownIcon
                            classes="mr-3"
                            style={{
                                transform: `translate(0, -1px)`,
                            }}
                            color="#64748B"
                            strokeWidth={ 1.5 }
                            size={ 20 }
                        />
                    }

                    {
                        !folder.isOpened &&
                        <ArrowRightIcon
                            classes="mr-3"
                            color="#64748B"
                            style={{
                                transformOrigin: `11px 11px`,
                            }}
                            strokeWidth={ 1.5 }
                            size={ 20 }
                        />
                    }
                </button>

                <NavLink
                    className={ `flashcard__menu-item flex flex items-center whitespace-pre-wrap` }
                    to={ `/flashcards/f-${ folder.folder_id }` }
                    onClick={ toggleMobileMenuBodyClass }>
                    { folder.folder_title }  ({ count })
                </NavLink>
            </div>

            <DragDeckToFolderItem folder_id={ folder.folder_id } />

            {
                count > 0 && folder.isOpened &&
                <div className="ml-4">
                    {
                        nestedFoldersCount > 0 &&
                        <div>
                            {
                                folder.folders.map((folder) => {
                                    return (
                                        <FlashcardMenuItemFolder
                                            key={ `folder-${ folder.folder_id }` }
                                            folder={ folder }
                                        />
                                    )
                                })
                            }
                        </div>
                    }

                    {
                        nestedDecksCount > 0 &&
                        <div>
                            {
                                folder.decks.map((deck) => {
                                    return (
                                        <FlashcardMenuItemDeck
                                            key={ `deck-${ deck.deck_id }` }
                                            deck={ deck }
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default FlashcardMenuItemFolder;