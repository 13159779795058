import ExportSvgBtn from './actions/export-svg-btn';
import { useDiagramStore } from '../../data/diagram-store';

const DiagramTopBar = () => {

    const diagram = useDiagramStore(store => store.diagram);

    return (
        <>
            {
                diagram &&
                <nav className="text-xs bg-cyan-800 border-l border-l-slate-500 px-4 py-4 xl:py-1 text-slate-100 flex items-center">
                    <h1>
                        Diagram for <span className="font-bold">{ diagram.root_todo.todo_title }</span>
                    </h1>

                    <ExportSvgBtn />
                </nav>
            }
        </>
    )
};

export default DiagramTopBar;