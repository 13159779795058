import { PlusIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface IAddNestedTodoButtonProps {
    startAddingNewTodo: () => void;
}

const AddNestedTodoButton = (props: IAddNestedTodoButtonProps) => {
    const { startAddingNewTodo } = props;

    const addNestedTodo = () => {
        startAddingNewTodo();
        sendCloseActionsEvent();
    };

    return (
        <button
            className="todo__add-nested-todo flex bg-stone-200 rounded mx-1 justify-center items-center action-btn"
            onClick={ addNestedTodo }
            type="button">
            <PlusIcon color={ '#885b57' } />
        </button>
    )
};

export default AddNestedTodoButton;