import { ITodo } from '../../planner-interfaces';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { useState } from 'react';
import { updateTodo } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import TodoTitle from '../todo/todo-title';
import { usePlannerStore } from '../../data/planner-store';

interface IActiveTodoItemProps {
    activeTodo: ITodo;
}

const ActiveTodoItem = ({ activeTodo }: IActiveTodoItemProps) => {

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const onChange = async () => {
        setLoading(true);
        const response = await updateTodo(
            activeTodo.todo_id,
            activeTodo.todo_desc,
            activeTodo.todo_address,
            activeTodo.todo_date,
            activeTodo.todo_date_has_time,
            0,
            activeTodo.todo_type,
            false,
            activeTodo.todo_tags,
            activeTodo.ideal_final_result,
        );
        setLoading(false);

        if(!response) {
            showToast('Uncheck active item error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <div className="active-todo-item flex items-start w-full">

            {
                isLoading &&
                <PreloaderIcon
                    size={ 16 }
                    color={ '#703505' }
                    classes="mr-4"
                />
            }

            {
                !isLoading &&
                <input
                    className="accent-stone-500 mr-4"
                    type="checkbox"
                    defaultChecked={ true }
                    onChange={ onChange }
                />
            }

            <div className="mt-[2px] w-full">
                <TodoTitle
                    todo={ activeTodo }
                    startAddingNewTodo={ () => { } }
                    showTodoLink={ true }
                />
            </div>

        </div>
    )
};

export default ActiveTodoItem;