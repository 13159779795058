import { MouseEvent } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';

interface ITodoAttachmentEditButtonProps {
    startEditDescription: () => void;
}

const TodoAttachmentEditButton = ({ startEditDescription }: ITodoAttachmentEditButtonProps) => {

    const handleClick = (evt: MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();
        startEditDescription();
    };

    return (
        <button
            onClick={ handleClick }
            type="button"
            title="Edit Attachment">
            <EditIcon size={ 20 } />
        </button>
    )
};

export default TodoAttachmentEditButton;