import { create } from 'zustand';
import { IDescartesSquare } from '../descartes-square-interfaces';

interface DescartesSquareState {
    todo_parent_id: number;
    setTodoParentId: (_todo_parent_id: number) => void;

    descartesSquare: IDescartesSquare|null;
    setDescartesSquare: (_descartesSquare: IDescartesSquare|null) => void;
}

export const useDescartesSquareStore = create<DescartesSquareState>()((set) => ({
    descartesSquare: null,
    setDescartesSquare: (_descartesSquare: IDescartesSquare|null) => {
        return set((state: DescartesSquareState) => {
            return {
                ...state,
                descartesSquare: _descartesSquare,
            };
        });
    },

    todo_parent_id: null,
    setTodoParentId: (_todo_parent_id: number) => {
        return set((state: DescartesSquareState) => {
            return {
                ...state,
                todo_parent_id: _todo_parent_id,
            };
        });
    },
}))