import { useEffect, useState } from 'react';
import AuthorTitle from './author-title';
import EditAuthor from './author-edit';
import AuthorDescription from './author-description';
import AddBook from '../books/book-add';
import Book from '../books/book';
import AddSerie from '../serie/serie-add';
import Serie from '../serie/serie';
import AddTagPopup from '../tags/book-tags/add-tag-popup';
import { useLibraryStore } from '../../data/library-store';
import DragBookToSerieTarget from '../serie/drag/grag-book-to-serie-target';

export const ADD_NEW_AUTHOR_DESCRIPTION_EVENT = 'add-new-author-desc';

export enum AuthorMode {
    DEFAULT = 0,
    EDIT = 1,
}

const AuthorView = () => {

    const libraryData = useLibraryStore(store => store.libraryData);

    const [mode, setMode] = useState<AuthorMode>(AuthorMode.DEFAULT);

    const setDefaultMode = () => {
        setMode(AuthorMode.DEFAULT);
    };

    const startAuthorEdit = () => {
        setMode(AuthorMode.EDIT);
    };

    useEffect(() => {
        let title = 'Library';

        if(libraryData?.selectedAuthor) {
            title = `${ libraryData.selectedAuthor.author_first_name } ${ libraryData.selectedAuthor.author_last_name }`;
        }

        document.title = `${ title } | Productivity`;
    }, [libraryData?.selectedAuthor]);

    return (
        <>
            {
                libraryData?.selectedAuthor &&
                <div className="w-full xl:w-[800px] max-w-full mx-auto flex-1 flex flex-col">
                    <div className="mb-6 w-full flex flex-col flex-1">

                        <AuthorTitle
                            author={ libraryData.selectedAuthor }
                            startEdit={ startAuthorEdit }
                        />

                        {
                            (mode === AuthorMode.DEFAULT) &&
                            <AuthorDescription
                                author={ libraryData.selectedAuthor }
                                close={ setDefaultMode }
                                startEdit={ startAuthorEdit }
                            />
                        }

                        {
                            (mode === AuthorMode.EDIT) &&
                            <EditAuthor
                                author={ libraryData.selectedAuthor }
                                close={ setDefaultMode }
                            />
                        }

                        {
                            libraryData.selectedAuthor.series?.map(serie => {
                                return (
                                    <Serie
                                        key={ `serie-${ serie.serie_id }` }
                                        serie={ serie }
                                        author={ libraryData.selectedAuthor }
                                    />
                                )
                            })
                        }

                        <AddSerie author={ libraryData.selectedAuthor } />

                        <div>
                            <div className="serie__title flex items-center bg-stone-100 text-md font-semibold py-2 px-4 rounded">Без Серии</div>

                            <DragBookToSerieTarget
                                serie_id={ 0 }
                                author_id={ libraryData.selectedAuthor.author_id }
                            />

                            <div className="ml-6">
                                {
                                    libraryData.selectedAuthor.booksWithoutSerie?.map(book => {
                                        return (
                                            <Book
                                                key={ `book-${ book.book_id }` }
                                                book={ book }
                                                author={ libraryData.selectedAuthor }
                                            />
                                        )
                                    })
                                }

                                <AddBook author={ libraryData.selectedAuthor } />
                            </div>
                        </div>
                    </div>

                    <AddTagPopup />
                </div>
            }
        </>
    )
};

export default AuthorView;