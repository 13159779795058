import { Fragment, useEffect } from 'react';
import AddFolder from '../folders/folder-add';
import { getLibraryData } from '../../data/library-data-service';
import { formatLibraryData } from '../../domain/library-provider';
import LibraryDataItem from './library-menu-item';
import { NavLink } from 'react-router-dom';
import { ESelectedLibraryItemType } from '../../interfaces';
import AddAuthor from '../authors/author-add';
import { SearchIcon, TagIcon } from '../../../../common/ui/icons';
import { useLibraryStore } from '../../data/library-store';

const LibraryData = () => {

    const libraryData = useLibraryStore(state => state.libraryData);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const selectedLibraryItem = useLibraryStore(store => store.selectedLibraryItem);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const setRoot = () => {
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.ROOT,
            id: 0,
        });
    };

    useEffect(() => {
        (async () => {
            const response = await getLibraryData(
                selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
                selectedLibraryItem?.id || 0,
            );
            if(!response) return;

            setLibraryData(formatLibraryData(response));
        })();
    }, [
        setLibraryData,
        selectedLibraryItem?.id,
        selectedLibraryItem?.type,
    ]);

    return (
        <div className="side-menu flex flex-col xl:w-[300px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3">

                <div className="text-sm pb-3 pt-3 text-slate-400">
                    Library
                </div>

                <div className="py-2 border-b border-slate-700">
                    <NavLink
                        className="flex items-center"
                        onClick={ setRoot }
                        to="/library/tags"
                        end={ true }>
                        <TagIcon color={ `#64748B` } classes="mr-1" />
                        Tags
                    </NavLink>
                </div>

                <div className="py-2 border-b border-slate-700">
                    <NavLink
                        className="flex items-center"
                        onClick={ setRoot }
                        to="/library/search"
                        end={ true }>
                        <SearchIcon color={ `#64748B` } classes="mr-1" />
                        Search Books
                    </NavLink>
                </div>

                <div className="text-sm pb-3 pt-3 text-slate-400">
                    <NavLink
                        onClick={ setRoot }
                        to="/library"
                        end={ true }>
                        Root
                    </NavLink>
                </div>

                {
                    libraryData?.menu?.map(folder => {
                        return (
                            <Fragment key={ `folder-${ folder.folder_id }` }>

                                <LibraryDataItem folder={ folder } />

                               {/* <ProjectMenuDragTarget
                                    targetProjectId={ project.project_id }
                                />*/}
                            </Fragment>
                        )
                    })
                }

                {
                    (!selectedLibraryItem ||
                        selectedLibraryItem.type === ESelectedLibraryItemType.ROOT ||
                        selectedLibraryItem.type === ESelectedLibraryItemType.FOLDER) &&
                    <AddFolder />
                }

                {
                    selectedLibraryItem &&
                    selectedLibraryItem.type === ESelectedLibraryItemType.FOLDER &&
                    <AddAuthor />
                }

            </div>
        </div>
    )
};

export default LibraryData;