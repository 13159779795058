import { ITodo } from '../../../planner-interfaces';
import { format } from 'date-fns';
import { ADD_NEW_TODO_BLOCKS_EVENT } from './todo-blocks';

interface ITodoBlocksPreview {
    todo: ITodo;
}

const TodoBlocksPreview = ({ todo }: ITodoBlocksPreview) => {

    const hasNestedTodos = todo?.nested?.length > 0;

    const startEdit = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_BLOCKS_EVENT, {
            detail: todo.todo_id,
        }));
    };

    const getDate = (start_date: number, end_date: number) => {
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);

        if(date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth()) {
            return `(${ format(start_date, 'dd')} - ${ format(end_date, 'dd')}).${ format(start_date, 'MM.yyyy') }`;
        }

        return `${ format(start_date, 'dd.MM.yyyy')} - ${ format(end_date, 'dd.MM.yyyy')}`; // dd.MM.yyyy E
    };

    return (
        <>
            {
                todo && todo.blocks?.length > 0 &&
                <div className={ `todo-blocks flex-col md:flex-row flex leading-loose xl:leading-normal mr-4 mt-1 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
                    {
                        todo.blocks.map(block => {
                            return (
                                <button
                                    type="button"
                                    onClick={ startEdit }
                                    className="mr-2 bg-slate-100 whitespace-nowrap rounded px-4 py-1 mb-2 md:mb-0"
                                    key={ block.block_id }>
                                    { getDate(block.start_date, block.end_date) }
                                </button>
                            )
                        })
                    }
                </div>
            }
        </>
    )
};

export default TodoBlocksPreview;