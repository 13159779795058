import { getJsonFromLocalStorage, saveJsonToLocalStorage } from '../../../common/data/storage-provider';

const FLASHCARDS_OPENED_FOLDERS_KEY = 'FLASHCARDS_OPENED_FOLDERS_KEY';

/**
 * All folders are closed by default.
 * Only opened folders are stored in the local storage.
 */
export const getOpenedFlashcardsFolders = () : Set<number> => {
    const folder_ids = getJsonFromLocalStorage(FLASHCARDS_OPENED_FOLDERS_KEY) || [];
    return new Set<number>(folder_ids);
};

export const openFlashcardsFolder = (folder_id: number) : number[] => {
    const folders = getOpenedFlashcardsFolders();
    folders.add(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(FLASHCARDS_OPENED_FOLDERS_KEY, list);
    return list;
};

export const closeFlashcardsFolder = (folder_id: number) : number[] => {
    const folders = getOpenedFlashcardsFolders();
    folders.delete(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(FLASHCARDS_OPENED_FOLDERS_KEY, list);
    return list;
};
