import { ILibraryAuthor, ILibrarySerie } from '../../interfaces';
import SerieTitle from './serie-title';
import EditSerie from './serie-edit';
import Book from '../books/book';
import AddBook from '../books/book-add';
import { useLibraryStore } from '../../data/library-store';
import DragBookToSerieTarget from './drag/grag-book-to-serie-target';

interface ISerieProps {
    serie: ILibrarySerie;
    author: ILibraryAuthor;
}

const Serie = ({ serie, author }: ISerieProps) => {

    const libraryData = useLibraryStore(store => store.libraryData);

    return (
        <div className="mb-2">

            <SerieTitle
                serie={ serie }
            />

            <DragBookToSerieTarget
                serie_id={ serie.serie_id }
                author_id={ author.author_id }
            />

            <EditSerie
                serie={ serie }
                author={ author }
            />

            {
                !serie.isClosed && (
                    <div className="ml-6">
                        {
                            serie.books?.map(book => {
                                return (
                                    <Book
                                        key={ `book-${ book.book_id }` }
                                        book={ book }
                                        author={ libraryData.selectedAuthor }
                                    />
                                )
                            })
                        }

                        <AddBook
                            author={ libraryData.selectedAuthor }
                            serie_id={ serie.serie_id }
                        />
                    </div>
                )
            }
        </div>
    )
};

export default Serie;