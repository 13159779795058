import { SquareIcon } from '../../../common/ui/icons';
import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { useDescartesSquareStore } from '../data/descartes-square-store';

const DescartesSquareTitle = () => {

    const descartesSquare = useDescartesSquareStore(store => store.descartesSquare);

    return (
        <h1 className="text-xl xl:text-3xl flex items-center mb-6">
            {/*<img
                className="overflow-hidden rounded-xl mr-4"
                src="/img/decision-making-pic.webp"
                style={{
                    height: '150px',
                }}
            />*/}
            <SquareIcon size={ 35 } classes="mr-4" color={ '#366d8c' } />
            <div dangerouslySetInnerHTML={{__html: markdown2Html(descartesSquare?.parentTodo?.todo_title) }} />
        </h1>
    )
};

export default DescartesSquareTitle;