import FlashCardsFolderTitle from './folder-title';
import { FolderIcon } from '../../../../common/ui/icons';
import FlashCardsFolderDescription from './folder-description';
import { useFlashCardsStore } from '../../data/flashcards-store';

const FlashCardsFolderView = () => {

    const selectedFolder = useFlashCardsStore(state => state.selectedFolder);

    return (
        <>
            {
                selectedFolder &&
                <div className="mb-6 w-full flex flex-col flex-1 my-6">

                    <FlashCardsFolderTitle
                        flashCardsFolder={ selectedFolder }
                    />

                    <FlashCardsFolderDescription
                        flashCardsFolder={ selectedFolder }
                    />

                    <div className="flex justify-center items-center py-10 flex-1">
                        <FolderIcon
                            size={ 200 }
                            strokeWidth={ 1 }
                            color={ '#d9d9d9' }
                        />
                    </div>

                </div>
            }
        </>
    )
};

export default FlashCardsFolderView;