import { ITodo } from '../../../planner-interfaces';
import { useEffect, useState } from 'react';
import TodoBlocksEdit from './todo-blocks-edit';
import TodoBlocksPreview from './todo-blocks-preview';

export const ADD_NEW_TODO_BLOCKS_EVENT = 'add-new-todo-blocks';

export enum TodoBlocksMode {
    DEFAULT = 0,
    EDIT = 1
}

interface ITodoBlocks {
    todo: ITodo;
}

const TodoBlocks = ({ todo }: ITodoBlocks) => {

    const [mode, setMode] = useState<TodoBlocksMode>(TodoBlocksMode.DEFAULT);

    const hasNestedTodos = todo?.nested?.length > 0;

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const todo_id = Number(evt?.detail) || 0;
            if(todo_id !== todo.todo_id) return;

            setMode(TodoBlocksMode.EDIT);
        };

        document.addEventListener(ADD_NEW_TODO_BLOCKS_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_TODO_BLOCKS_EVENT, handler);
        };
    }, [todo.todo_id]);

    const setDefaultMode = () => {
        setMode(TodoBlocksMode.DEFAULT);
    };

    return (
        <>
            {
                (mode === TodoBlocksMode.DEFAULT) &&
                <TodoBlocksPreview
                    todo={ todo }
                />
            }

            {
                (mode === TodoBlocksMode.EDIT) &&
                <div className={ `todo-blocks-edit flex mr-4 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
                    <TodoBlocksEdit
                        todo={ todo }
                        close={ setDefaultMode }
                    />
                </div>
            }
        </>
    )
};

export default TodoBlocks;