import { ILibraryAuthor, ILibraryBook } from '../../interfaces';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import Actions from '../../../../common/ui/actions';
import BookEditButton from './actions/book-edit-btn';
import { MouseEvent, useEffect, useState } from 'react';
import EditBook, { BOOK_EDIT_EVENT } from './book-edit';
import DeleteBookButton from './actions/book-delete-btn';
import AddBookFileBtn from './actions/add-book-file';
import BookFile from './book-file';
import AddTagBtn from '../tags/book-tags/actions/add-tag-btn';
import BookTags from '../tags/book-tags/book-tags';
import AddTagActionButton from './actions/add-tag-action-btn';
import DragBookToSerieBtn from '../serie/drag/drag-book-to-serie-btn';

interface IBookProps {
    author: ILibraryAuthor;
    book: ILibraryBook;
}

enum BookMode {
    DEFAULT = 0,
    EDIT = 1,
}

export const isDescExists = (book: ILibraryBook) => {
    const desc = book.book_desc;
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const Book = ({ book, author }: IBookProps) => {

    const descExists = isDescExists(book);
    const parsedDesc = descExists ? markdown2Html(book.book_desc?.replaceAll('```', '')) : '';

    const [mode, setMode] = useState<BookMode>(BookMode.DEFAULT);

    const setDefaultMode =() => {
        setMode(BookMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(BookMode.EDIT);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const book_id = Number(evt?.detail) || 0;
            if(book_id !== book.book_id) return;

            setMode(BookMode.EDIT);
        };

        document.addEventListener(BOOK_EDIT_EVENT, handler);

        return () => {
            document.removeEventListener(BOOK_EDIT_EVENT, handler);
        };
    }, [book.book_id]);

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const numberInSerie = book.serie_id ? (book.number_in_serie || '') : '';

    return (
        <div className="border-b mt-2 pb-2">
            {
                mode === BookMode.DEFAULT &&
                <>
                    <div className="flex flex-col">
                       <div className="flex items-center">
                           <button
                               className="text-left flex-1"
                               type="button"
                               onClick={ startEdit }>
                               <span className="mr-1">{ numberInSerie ? `${ numberInSerie }.` : '' }</span>
                               { book.book_title }
                           </button>

                           <Actions classes="book__actions" gridSize={ 3 }>
                               <BookEditButton book={ book } />

                               <DeleteBookButton
                                   book_id={ book.book_id }
                                   book_title={ book.book_title }
                                   author={ author }
                               />

                               <DragBookToSerieBtn
                                   book_id={ book.book_id }
                               />

                               <AddBookFileBtn
                                   book_id={ book.book_id }
                               />

                               <AddTagActionButton
                                   book={ book }
                               />
                           </Actions>
                       </div>

                        {
                            book.bookFiles?.length > 0 &&
                            <div className="text-sm">
                                {
                                    book.bookFiles.map(bookFile => {
                                        return (
                                            <BookFile
                                                key={ bookFile.book_file_id }
                                                bookFile={ bookFile }
                                                author_id={ author.author_id }
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>

                    {
                        descExists &&
                        <div
                            onClick={ onClick }
                            className="text-sm leading-none overflow-hidden bg-slate-50 text-slate-600 rounded px-4 py-2">
                            <div
                                className="markdown-editor whitespace-wrap leading-6"
                                dangerouslySetInnerHTML={{__html: parsedDesc || '' }}
                            />
                        </div>
                    }

                    {
                        book.tags?.length > 0 &&
                        <div className="mt-2 flex md:items-center flex-col md:flex-row">
                            <AddTagBtn book={ book } />
                            <BookTags book={ book } />
                        </div>
                    }
                </>
            }

            {
                mode === BookMode.EDIT &&
                <EditBook
                    book={ book }
                    author={ author }
                    close={ setDefaultMode }
                />
            }
        </div>
    )
};

export default Book;