import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent as ReactKeyboardEvent, useCallback } from 'react';
import { CalendarIcon2, PreloaderIcon, ResetIcon, SunIcon } from '../../../../common/ui/icons';
import { insertTodo } from '../../data/planner-data-service';
import { openTodo } from '../../domain/planner-storage';
import { addDays, format, startOfDay } from 'date-fns';
import { showToast } from '../../../../common/domain/toast-provider';
import { useParams } from 'react-router-dom';
import { combineProjectData } from '../../domain/planner-provider';
import { TodoType } from '../../planner-interfaces';
import { handleWiziwig } from '../../domain/wiziwig-provider';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getTodoTypeSelectOptions } from '../../domain/todo-type-provider';
import TodoTagsSuggestion from './todo-tags-suggestion';
import { HabitTrackerIntervalType } from '../../../habit-tracker/domain/habit-tracker-provider';
import TodoHabitTrackerForm from '../habit-tracker/forms/todo-habit-tracker-form';
import { WeekDaysEnum } from '../../../../common/domain/date-time-provider';
import { serializeWeekDaysSet } from '../../../habit-tracker/domain/week-days-provider';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { usePlannerStore } from '../../data/planner-store';

interface INewTodoProps {
    project_id: number;
    section_id?: number;
    parent_todo_id?: number;
    parent_todo_title?: string;
    order: number;
    cancel: () => void;
}

const NewTodo = (props: INewTodoProps) => {

    const { order, cancel, project_id } = props;

    const { project_id: project_id_url } = useParams();

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);
    const [date, setDate] = useState<number|null>(project_id_url === 'today' ? Date.now() : null);
    const [hasTime, setHasTime] = useState<boolean>(false);
    const [type, setType] = useState<TodoType>(TodoType.Default);
    const [isTodoActive, setIsTodoActive] = useState<boolean>(false);
    const [tags, setTags] = useState('');
    const [idealFinalResult, setIdealFinalResult] = useState('');
    const [address, setAddress] = useState('');

    const [isHabitTrackerActive, setIsHabitTrackerActive] = useState<boolean|null>(null);
    const [habitInterval, setHabitInterval] = useState<HabitTrackerIntervalType|null>(null);
    const [habitQtyPerInterval, setHabitQtyPerInterval] = useState<TodoType|null>(null);
    const [habitWeekDays, setHabitWeekDays] = useState<Set<WeekDaysEnum>>(new Set());

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const combinedProject = usePlannerStore(state => state.combinedProject);

    useEffect(() => {
        titleRef?.current?.focus();
    }, []);

    const save = useCallback(async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';

        const response = await insertTodo(
            project_id,
            props.section_id,
            props.parent_todo_id,
            title.trim(),
            desc.trim(),
            address,
            order,
            date,
            hasTime ? 1 : 0,
            type,
            isTodoActive ? 1 : 0,
            tags,
            idealFinalResult,
            isHabitTrackerActive,
            habitInterval,
            habitQtyPerInterval,
            serializeWeekDaysSet(habitWeekDays),
        );

        setLoading(false);

        if(!response) {
            showToast('Insert task error.');
            return;
        }

        cancel();

        // Open parent _todo if it exists and closed.
        if(props.parent_todo_id) {
            openTodo(props.parent_todo_id);
        }

        setCombinedProject(combineProjectData(response));
    }, [
        cancel, date, hasTime,
        isTodoActive, order, project_id, props.parent_todo_id,
        props.section_id, setCombinedProject, title, type,
        tags, idealFinalResult, habitInterval,
        habitQtyPerInterval, isHabitTrackerActive,
        habitWeekDays, address,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            cancel();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = hasTime ? new Date(evt.target.value) : startOfDay(new Date(evt.target.value)); // 2023-12-07T13:21
        setDate(_date.getTime());
    };

    const onHasTimeChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        const _hasTime = (evt.target as HTMLInputElement).checked;
        setHasTime(_hasTime);
    };

    const onChangeTodoType = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _type: TodoType = Number((evt.target as HTMLSelectElement).value) || TodoType.Default;
        setType(_type);
    };

    const onTagsChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _tags = (evt.target as HTMLInputElement).value;
        setTags(_tags);
    };

    const onTodoActiveChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _isActive = (evt.target as HTMLInputElement).checked;
        setIsTodoActive(_isActive);
    };

    const onIdealFinalResultChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _idealFinalResult = (evt.target as HTMLInputElement).value;
        setIdealFinalResult(_idealFinalResult);
    };

    const setToday = () => {
        const today = startOfDay(new Date());
        setDate(hasTime ? today.getTime() : today.setHours(0,0,0,0));
    };

    const setTomorrow = () => {
        const today = startOfDay(new Date());

        if(!hasTime) {
            today.setHours(0,0,0,0);
        }

        const tomorrow = addDays(today, 1);
        setDate(tomorrow.getTime());
    };

    const setNoDate = () => {
        setDate(null);
    };

    const onTagSuggestionClick = (tag_title: string) => {
        if(tags.trim() === '') {
            setTags(tag_title);
            return;
        }

        setTags(`${ tags }, ${ tag_title }`);
    };

    const onAddressChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _address = (evt.target as HTMLInputElement).value;
        setAddress(_address);
    };

    return (
        <div className="border border-slate-200 text-slate-800 rounded p-4 text-sm flex flex-col">
            {
                props.parent_todo_title &&
                <div className="text-lg mb-4 flex items-center flex-wrap">
                    <div className="mr-2">Add nested task to</div>
                    <div
                        className="inline-flex text-sky-800"
                        dangerouslySetInnerHTML={{__html: markdown2Html(props.parent_todo_title) }}
                    />
                </div>
            }

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Task Name</div>
                <input
                    ref={ titleRef }
                    onInput={ onTitleChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isTitleValid && <div className="text-red-700 text-xs mt-1">The title is required.</div>
                }
            </label>

            <div className="flex flex-col xl:flex-row mt-2">
                <div className="flex items-center">
                    <div className="flex flex-col mr-4">
                        <label className="text-xs font-bold mb-1 text-slate-400">Edit Date</label>
                        {
                            hasTime &&
                            <input
                                type="datetime-local"
                                className="border rounded text-slate-800 outline-stone-200 mb-4 px-4 py-2 text-sm"
                                onKeyDown={ onKeyDown }
                                onChange={ onDateChange }
                                value={ date ? format(date, `yyyy-MM-dd'T'HH:mm`) : '' }
                            />
                        }

                        {
                            !hasTime &&
                            <input
                                type="date"
                                className="border rounded text-slate-800 outline-stone-200 mb-4 px-4 py-2 text-sm"
                                onKeyDown={ onKeyDown }
                                onChange={ onDateChange }
                                value={ date ? format(date, `yyyy-MM-dd`) : '' }
                            />
                        }
                    </div>

                    <label className="text-xs flex items-center mr-10">
                        <div className="mb-1">&nbsp;</div>
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={ hasTime }
                            onChange={ onHasTimeChange }
                        /> Has Time?
                    </label>
                </div>

                <div className="flex items-center mb-4 xl:mb-0">
                    <button
                        onClick={ setToday }
                        className="text-xs flex items-center mr-4"
                        type="button">
                        <CalendarIcon2 size={ 16 } color={ '#42a378' } classes="mr-1" />
                        Today
                    </button>

                    <button
                        onClick={ setTomorrow }
                        className="text-xs flex items-center mr-4 "
                        type="button">
                        <SunIcon size={ 16 } color={ '#e18601' } classes="mr-1" />
                        Tomorrow
                    </button>

                    <button
                        onClick={ setNoDate }
                        className="text-xs flex items-center mr-4"
                        type="button">
                        <ResetIcon size={ 16 } classes="mr-1" />
                        Reset
                    </button>
                </div>
            </div>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            {
                type !== TodoType.RegularTaskLog &&
                <>
                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Address</label>
                        <input
                            type="text"
                            placeholder="Address"
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ address }
                            onInput={ onAddressChange }
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Tags</label>
                        <input
                            type="text"
                            placeholder="Tag 1, Tag 2, ..."
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ tags }
                            onInput={ onTagsChange }
                        />

                        {
                            combinedProject?.tags?.length > 0 &&
                            <TodoTagsSuggestion
                                tags={ combinedProject.tags }
                                toFilter={ tags.split(',').map(_tag => _tag.trim()) }
                                onClick={ onTagSuggestionClick }
                            />
                        }
                    </div>

                    <TodoHabitTrackerForm
                        type={ type }
                        isHabitTrackerActive={ isHabitTrackerActive }
                        setIsHabitTrackerActive={ setIsHabitTrackerActive }
                        habitInterval={ habitInterval }
                        setHabitInterval={ setHabitInterval }
                        habitQtyPerInterval={ habitQtyPerInterval }
                        setHabitQtyPerInterval={ setHabitQtyPerInterval }
                        habitWeekDays={ habitWeekDays }
                        setHabitWeekDays={ setHabitWeekDays }
                    />

                    <div className="flex flex-col mb-4">
                        <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Ideal Final Result</label>
                        <input
                            type="text"
                            placeholder=""
                            className="border rounded text-slate-800 outline-stone-200 px-4 py-2 text-sm"
                            value={ idealFinalResult }
                            onInput={ onIdealFinalResultChange }
                        />
                    </div>
                </>
            }

            <div className="flex items-center mb-4">
                {
                    type !== TodoType.RegularTaskLog &&
                    <label className="text-xs flex items-center mr-4">
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={ isTodoActive }
                            onChange={ onTodoActiveChange }
                        /> Is Active?
                    </label>
                }

                <label className="text-xs flex items-center">
                    <select
                        value={ type }
                        onChange={ onChangeTodoType }
                        className="outline-none">
                        { getTodoTypeSelectOptions() }
                    </select>
                </label>
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ cancel }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default NewTodo;