import { HabitTrackerIntervalType } from '../../../../habit-tracker/domain/habit-tracker-provider';
import { WeekDaysEnum } from '../../../../../common/domain/date-time-provider';

interface IWeekDays {
    habitInterval: HabitTrackerIntervalType;
    habitWeekDays: Set<WeekDaysEnum>;
    setHabitWeekDays: (value: (((prevState: Set<WeekDaysEnum>) => Set<WeekDaysEnum>) | Set<WeekDaysEnum>)) => void;
}

const WeekDays = ({ habitInterval, habitWeekDays, setHabitWeekDays }: IWeekDays) => {

    const onChange = (weekDay: WeekDaysEnum, isChecked: boolean) => {
        const copy = new Set(habitWeekDays);

        if(isChecked) {
            copy.add(weekDay);
        }
        else{
            copy.delete(weekDay);
        }

        setHabitWeekDays(copy);
    };

    return (
        <>
            {
                habitInterval === HabitTrackerIntervalType.Week &&
                <div className="flex items-center mr-4 mb-2 md:mb-0">
                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Sunday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Sunday, evt.target.checked);
                            }}
                        />
                        Su
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Monday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Monday, evt.target.checked);
                            }}
                        />
                        Mo
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Tuesday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Tuesday, evt.target.checked);
                            }}
                        />
                        Tu
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Wednesday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Wednesday, evt.target.checked);
                            }}
                        />
                        We
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Thursday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Thursday, evt.target.checked);
                            }}
                        />
                        Th
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Friday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Friday, evt.target.checked);
                            }}
                        />
                        Fr
                    </label>

                    <label className="mr-2 flex flex-col">
                        <input
                            className="mb-1"
                            type="checkbox"
                            checked={ habitWeekDays.has(WeekDaysEnum.Saturday) }
                            onChange={ (evt) => {
                                onChange(WeekDaysEnum.Saturday, evt.target.checked);
                            }}
                        />
                        Sa
                    </label>
                </div>
            }
        </>
    )
};

export default WeekDays;