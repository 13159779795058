import { CalendarIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { createGoogleCalendarEventLink } from '../../../../../common/domain/google-calendar-provider';

interface IAddTodoToGoogleCalendarBtn {
    todo: ITodo;
}

const AddTodoToGoogleCalendarBtn = ({ todo }: IAddTodoToGoogleCalendarBtn) => {

    const startDate = new Date(todo.todo_date);
    const endDate = todo.complete_date ? new Date(todo.complete_date) : new Date(todo.todo_date);

    const link = createGoogleCalendarEventLink(
        todo.todo_title,
        startDate,
        endDate,
        todo.todo_desc || '',
        todo.todo_address || ''
    );

    return (
        <a
            title="Print Task"
            className="todo__add-to-google-calendar flex bg-gray-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            href={ link }
            onClick={ sendCloseActionsEvent}
            target="_blank"
            rel="noreferrer">
            <CalendarIcon size={ 20 } color={ '#656565' } />
        </a>
    )
};

export default AddTodoToGoogleCalendarBtn;