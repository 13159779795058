import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface IEditFolderDescriptionBtnProps {
    startEditDesc: () => void;
}

const EditFolderDescriptionBtn = ({ startEditDesc }: IEditFolderDescriptionBtnProps) => {

    const handleClick = () => {
        startEditDesc();
        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ handleClick }
            className="flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default EditFolderDescriptionBtn;