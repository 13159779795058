import { useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import Actions from '../../../../common/ui/actions';
import { IFlashCard } from '../../flashcards-interfaces';
import { updateCardName } from '../../data/flashcards-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import DeleteCardButton from './actions/delete-card-btn';
import CardEditButton from './actions/card-edit-btn';
import DuplicateCardBtn from './actions/duplicate-card-btn';
import DragCardItemBtn from './drag/drag-card-item-btn';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

enum CardMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ICardNameProps {
    card: IFlashCard;
    index: number;
    hideActions?: boolean;
    hideIndex?: boolean;
}

const CardItemName = (props: ICardNameProps) => {

    const { card } = props;

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [mode, setMode] = useState<CardMode>(CardMode.DEFAULT);
    const [updateNameLoading, setUpdateNameLoading] = useState(false);

    const index = props.hideIndex ? '' : `${ props.index + 1 }. `;

    const startEdit = () => {
        setMode(CardMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(CardMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateNameLoading(true);
        const response = await updateCardName(flashCardsListType, card.card_id, updatedText.trim(), card.deck_id);
        setUpdateNameLoading(false);

        if(!response?.isValid) {
            showToast('Update task name error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, card.deck_id));

        cancelEdit();
    };

    return (
        <div
            className={ `card__name text-sm flex items-center py-1 ${ mode === CardMode.EDIT ? 'flex-1' : '' }` }>
            {
                mode === CardMode.DEFAULT &&
                <div className="flex flex-col justify-center flex-1">
                    <div className="flex items-center flex-1">

                        <button
                            id={ `card-${ card.card_id }` }
                            type="button"
                            className={ `flash-card-item markdown flex-1 inline-flex text-left mr-4 whitespace-pre-wrap font-semibold` }
                            onClick={ startEdit }
                            dangerouslySetInnerHTML={{__html: markdown2Html(`${ index }${ card?.card_name || '' } ${ card.card_back ? '' : '❗️' }`) }}
                        />

                        {
                            !props.hideActions &&
                            <Actions classes="card__actions">
                                <DeleteCardButton card={ card } />
                                <DuplicateCardBtn card={ card } />
                                <CardEditButton card={ card } />
                                <DragCardItemBtn card={ card } />
                            </Actions>
                        }
                    </div>

                </div>
            }

            {
                mode === CardMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ card.card_name }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateNameLoading }
                />
            }
        </div>
    )
};

export default CardItemName;