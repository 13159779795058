import { IFlashCard } from '../../flashcards-interfaces';
import CardItemName from './card-item-title';
import CardDescription from './description/card-description';
import DragCardItemTarget from './drag/drag-card-item-target'; // https://www.npmjs.com/package/marked

interface ICardProps {
    card: IFlashCard;
    index: number;
}

const CardItem = ({ card, index }: ICardProps) => {

    return (
        <div className={ `flash-card mb-2 relative` } id={ `card-${ card.card_id }` }>

            <CardItemName
                card={ card }
                index={ index }
            />

            <CardDescription
                card={ card }
            />

            <DragCardItemTarget
                targetCardId={ card.card_id }
                deck_id={ card.deck_id }
                isBefore={ false }
            />
        </div>
    )
};

export default CardItem;