import { DragEvent } from 'react';
import { dragSection } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { usePlannerStore } from '../../../data/planner-store';

interface ISectionDragTargetProps {
    targetSectionId: number;
    targetProjectId: number;
    targetOrder: number;
}

const SECTION_DRAG_OVER_CLASS = 'section__drag-target__over';

const SectionDragTarget = ({ targetSectionId, targetProjectId, targetOrder }: ISectionDragTargetProps) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(SECTION_DRAG_OVER_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(SECTION_DRAG_OVER_CLASS);
    };

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data))) {
            showToast('Drop section error.');
            return;
        }

        const sourceSectionId = Number(data);
        console.log(`${ sourceSectionId } ---> ${ targetSectionId } (order = ${ targetOrder })`);

        const response = await dragSection(
            targetProjectId,
            sourceSectionId,
            targetSectionId,
        );

        if(!response?.isValid) {
            showToast('Drag section error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className="section__drag-target w-full h-[5px] bg-stone-300 rounded my-4 hidden"
        ></div>
    )
};

export default SectionDragTarget;