import { useAuthStatus } from '../../auth/hooks/auth-status-hook';
import Preloader from '../../../common/ui/preloader';
import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import Menu from '../../../menu/menu';
import { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiagramData } from '../data/diagram-data-service';
import { showToast } from '../../../common/domain/toast-provider';
import { formatDiagramData } from '../domain/diagram-provider';
import DiagramTopBar from './top-bar/top-bar';
import DiagramView from './diagram/diagram-view';
import { useDiagramStore } from '../data/diagram-store';

const DiagramHome = () => {

    const { loading } = useAuthStatus();

    const { todo_id } = useParams();
    const _todo_id = Number(todo_id) || 0;

    const diagram = useDiagramStore(store => store.diagram);
    const setDiagram = useDiagramStore(store => store.setDiagram);

    useEffect(() => {
        (async () => {
            const response = await getDiagramData(_todo_id);

            if(!response?.isValid) {
                showToast('Get diagram data error.');
            }

            setDiagram(await formatDiagramData(_todo_id, response));
        })();
    }, [_todo_id, setDiagram]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading && diagram &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">

                        <DiagramTopBar />

                        <div className="overflow-auto px-6 py-4 flex-1 flex flex-col">
                            <DiagramView />
                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default DiagramHome;