import { PrintIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface IPrintTodoBtn {
    todo: ITodo;
}

const PrintTodoBtn = ({ todo }: IPrintTodoBtn) => {

    return (
        <a
            title="Print Task"
            className="todo__print flex bg-gray-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            href={ `/print/todo/${ todo.todo_id }` }
            onClick={ sendCloseActionsEvent}
            target="_blank"
            rel="noreferrer">
            <PrintIcon size={ 20 } color={ '#656565' } />
        </a>
    )
};

export default PrintTodoBtn;