import Preloader from '../../../../common/ui/preloader';
import MobileMenuBtn from '../../../../menu/ui/mobile-menu-btn';
import Menu from '../../../../menu/menu';
import FlashCardsMenu from '../menu/flashcards-menu';
import { useAuthStatus } from '../../../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect, useState } from 'react';
import { getFlashCardsLearningData } from '../../data/flashcards-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import { useParams } from 'react-router-dom';
import DeckTitle from '../deck/deck-title';
import NextStudyCard from './next-study-card';
import { FlashCardsListView } from '../../flashcards-interfaces';
import { useFlashCardsStore } from '../../data/flashcards-store';

const StudyView = () => {

    const { loading } = useAuthStatus();

    const { deck_id } = useParams();

    const selectedDeck = useFlashCardsStore(state => state.selectedDeck);
    const setLearningFlowIndex = useFlashCardsStore(state => state.setLearningFlowIndex);
    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsListType = useFlashCardsStore(state => state.setFlashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [startAgainIndex, setStartAgainIndex] = useState(0);

    useEffect(() => {
        document.title = 'Flashcards Learning | Productivity';
    }, []);

    useEffect(() => {
        setFlashCardsListType(FlashCardsListView.StudyView);
    }, [setFlashCardsListType]);

    const updateStartAgainIndex = () => {
        setLearningFlowIndex(0);
        setStartAgainIndex(index => index + 1);
    };

    useEffect(() => {
        if(loading) return;

        const _deck_id = Number(deck_id);
        if(isNaN(_deck_id)) return;

        (async () => {
            const response = await getFlashCardsLearningData(flashCardsListType, _deck_id);

            if(!response) {
                showToast('Get flashcards learning data error.');
                return;
            }

            setLearningFlowIndex(0);
            setFlashCardsTree(formatFlashCardsTree(response, _deck_id));
        })();
    },
        // don't update on "learning flow index" change
        // eslint-disable-next-line
        [
            deck_id, loading, startAgainIndex,
        ]
    );

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <FlashCardsMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6">

                            <div className="xl:max-w-[1100px] max-w-full mx-auto">
                                {
                                    selectedDeck &&
                                    <div>
                                        <DeckTitle deck={ selectedDeck } />

                                        <NextStudyCard updateStartAgainIndex={ updateStartAgainIndex } />
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default StudyView;