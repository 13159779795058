import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { FLASH_CARDS_FOLDER_DESCRIPTION_EVENT } from '../folder-description';

interface IEditFlashCardsFolderDescriptionBtn {
    folder_id: number;
}

const EditFlashCardsFolderDescriptionBtn = ({ folder_id }: IEditFlashCardsFolderDescriptionBtn) => {

    const handleClick = () => {

        document.dispatchEvent(new CustomEvent(FLASH_CARDS_FOLDER_DESCRIPTION_EVENT, {
            detail: folder_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ handleClick }
            className="flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default EditFlashCardsFolderDescriptionBtn;