import { IDeck } from '../../flashcards-interfaces';
import { NavLink } from 'react-router-dom';
import { toggleMobileMenuBodyClass } from '../../../../menu/ui/mobile-menu-provider';
import DragCardToAnotherDeckTarget from './drag/drag-card-to-another-deck-target';
import DragDeckBtn from './drag/drag-deck-btn';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IFlashcardMenuItem {
    deck: IDeck;
}

const FlashcardMenuItemDeck = ({ deck }: IFlashcardMenuItem) => {

    const selectedDeck = useFlashCardsStore(store => store.selectedDeck);

    return (
        <>
            <div className="flashcard__menu-item flex border-b border-slate-700 py-3 px-3 flex items-center whitespace-pre-wrap">
                <NavLink
                    className="flex items-center flex-1"
                    to={ `/flashcards/${ deck.deck_id }` }
                    onClick={ toggleMobileMenuBodyClass }>
                    <div className="w-[5px] h-[5px] rounded-full mr-4 bg-blue-500"></div>
                    { deck.deck_name } ({ deck.cards_count ?? 0 })
                </NavLink>

                {
                    selectedDeck?.deck_id === deck.deck_id &&
                    <DragDeckBtn deck={ deck } />
                }
            </div>


            <DragCardToAnotherDeckTarget
                targetDeckId={ deck.deck_id }
            />
        </>
    )
};

export default FlashcardMenuItemDeck;