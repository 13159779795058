import { MouseEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { TodoDescriptionMode } from './todo-description';
import { ITodoAttachment } from '../../../planner-interfaces';
import TodoAttachment from '../attachment/todo-attachment';
import { CloseIcon } from '../../../../../common/ui/icons';

interface ITodoFullDescriptionProps {
    parsedDesc: string;
    setMode: (mode: TodoDescriptionMode) => void;
    todosAttachments: ITodoAttachment[];
}

const TodoFullDescription = (props: ITodoFullDescriptionProps) => {
    const { parsedDesc, setMode, todosAttachments } = props;

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setShortMode = () => {
        setMode(TodoDescriptionMode.DEFAULT_SHORT);
    };

    const startEdit = () => {
        setMode(TodoDescriptionMode.EDIT);
    };

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setShortMode();
        }
    };

    return (
        <div className="flex flex-col">
            <div
                ref={ contentRef }
                tabIndex={ 0 }
                className="text-sm leading-normal overflow-auto bg-slate-50 text-slate-600 rounded px-4 py-2 outline-none"
                onClick={ onClick }
                onKeyDown={ onKeyDown }>
                <div
                    className="markdown whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{__html: parsedDesc }}
                />

                {
                    todosAttachments?.map(attachment => {
                        return (
                            <TodoAttachment
                                key={ attachment.attachment_id }
                                attachment={ attachment }
                            />
                        )
                    })
                }
            </div>

            <div className="flex justify-end my-2">
                <button
                    onClick={ setShortMode }
                    type="button">
                    <CloseIcon />
                </button>
            </div>
        </div>
    )
};

export default TodoFullDescription;