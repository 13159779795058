import { ITodo, TodoType } from '../../planner-interfaces';
import { AttachmentIcon, NestedIcon, SquareIcon, TagIcon } from '../../../../common/ui/icons';
import { ADD_NEW_TODO_DESCRIPTION_EVENT } from './description/todo-description';
import { getTodoTypeTextWithClass } from '../../domain/todo-type-provider';
import TodoHabitActions from '../habit-tracker/todo-habit-actions';
import { shouldShowHabitSection } from '../../../habit-tracker/domain/habit-tracker-provider';
import AddressBtn from './address-btn';

interface ITodoMetaDataProps {
    todo: ITodo;
}

const TodoMetaData = ({ todo }: ITodoMetaDataProps) => {

    const nested = todo.nested?.length ?? 0;
    const completed = todo.nested?.filter(nestedTodo => !!nestedTodo.is_completed)?.length ?? 0;

    const showMetadata = shouldShowHabitSection(todo) ||
        todo.attachments?.length > 0 ||
        todo.todo_is_active === 1 ||
        !!todo.variant_count ||
        todo.todo_type != TodoType.Default ||
        nested > 0 ||
        (todo.todo_address ?? '').trim() !== '';

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_DESCRIPTION_EVENT, {
            detail: todo.todo_id,
        }));
    };

    return (
        <>
            {
                showMetadata &&
                <div className="todo-metadata flex items-center my-1 flex-wrap">
                    <TodoHabitActions todo={ todo } />

                    {
                        todo.attachments?.length > 0 &&
                        <div className="text-xs flex items-center mr-2">
                            <AttachmentIcon size={ 18 } classes="mr-[2px]" />
                            { todo.attachments.length }
                        </div>
                    }

                    {
                        (todo.todo_is_active === 1) &&
                        <button type="button" onClick={ sendEvent }>
                            <TagIcon
                                size={ 16 }
                                color={ '#ff0000' }
                                title="Active"
                                classes="flex mr-2"
                            />
                        </button>
                    }

                    {
                        !!todo.variant_count &&
                        <a
                            className="mr-2"
                            target="_blank"
                            rel="noreferrer"
                            href={ `/descartes-square/${ todo.todo_id }` }
                            title="Open Descartes Square/">
                            <SquareIcon size={ 10 } />
                        </a>
                    }

                    {
                        todo.todo_type != TodoType.Default &&
                        <div className="text-xs mr-2 whitespace-nowrap">{ getTodoTypeTextWithClass(todo.todo_type) }</div>
                    }

                    {
                        nested > 0 &&
                        <div className="text-xs flex items-center mr-2 whitespace-nowrap">
                            <NestedIcon classes="mr-[2px]" />
                            { completed } / { nested }
                        </div>
                    }

                    <AddressBtn todo={ todo } />

                </div>
            }
        </>
    )
};

export default TodoMetaData;