import TextBox from '../../../../../common/ui/forms/text-box';
import { ITodoAttachment } from '../../../planner-interfaces';
import { useState, MouseEvent } from 'react';
import { updateAttachmentDescription } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoAttachmentEditProps {
    attachment: ITodoAttachment;
    stopEditDescription: () => void;
}

const TodoAttachmentEdit = ({ attachment, stopEditDescription }: ITodoAttachmentEditProps) => {

    const [loading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const save = async (updatedText: string) => {
        setLoading(true);
        const response = await updateAttachmentDescription(attachment.attachment_id, updatedText.trim());
        setLoading(false);

        if(!response?.isValid) {
            showToast('Update attachment description error.');
            return;
        }

        stopEditDescription();

        setCombinedProject(combineProjectData(response));
    };

    const stopPropagation = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    return (
        <div className="my-2" onClick={ stopPropagation }>
            <TextBox
                isMultiLine={ false }
                text={ attachment.attachment_desc }
                cancel={ stopEditDescription }
                save={ save }
                isLoading={ loading }
            />
        </div>
    )
};

export default TodoAttachmentEdit;