import { PlusIcon } from '../../../../../../common/ui/icons';
import { ILibraryBook } from '../../../../interfaces';
import { useLibraryStore } from '../../../../data/library-store';

interface IAddTagBtn {
    book: ILibraryBook;
}

const AddTagBtn = ({ book }: IAddTagBtn) => {

    const setAddTagPopupShown = useLibraryStore(state => state.setAddTagPopupShown);
    const setAddTagPopupBook = useLibraryStore(state => state.setAddTagPopupBook);

    const onClick = async () => {
        setAddTagPopupBook(book);
        setAddTagPopupShown(true);
    };

    return (
        <button
            onClick={ onClick }
            className="flex items-center text-sm text-slate-400 mr-4 mb-2 md:mb-0 whitespace-nowrap"
            type="button">
            <div className="mr-2"><PlusIcon /></div>
            <div>Add Tag</div>
        </button>
    )
};

export default AddTagBtn;