import { PlusIcon, PreloaderIcon } from '../../../../common/ui/icons';
import {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
    KeyboardEvent as ReactKeyboardEvent,
    MutableRefObject
} from 'react';
import { handleWiziwig } from '../../../planner/domain/wiziwig-provider';
import { insertFolder } from '../../data/flashcards-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { FlashCardsListView } from '../../flashcards-interfaces';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

enum AddFolderMode {
    DEFAULT = 0,
    ADDING = 1,
}

const AddFolder = () => {

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const selectedDeck = useFlashCardsStore(state => state.selectedDeck);
    const selectedFolder = useFlashCardsStore(state => state.selectedFolder);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [addFolderMode, setAddFolderMode] = useState(AddFolderMode.DEFAULT);
    const [isLoading, setLoading] = useState(false);

    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);
    const [desc, setDesc] = useState('');

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const descRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [addFolderMode]);

    const startAddingFolder = () => {
        setAddFolderMode(AddFolderMode.ADDING);
    };

    const stopAddingFolder = () => {
        setAddFolderMode(AddFolderMode.DEFAULT);
    };

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onDescChange= (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDesc((evt.target as HTMLTextAreaElement).value);
    };

    const save = useCallback(async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const response = await insertFolder(
            flashCardsListType,
            selectedDeck?.deck_id || 0,
            title.trim(),
            desc.trim(),
            flashCardsListType === FlashCardsListView.ROOT ? 0 : selectedFolder.folder_id,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert folder error.');
            return;
        }

        stopAddingFolder();

        setFlashCardsTree(formatFlashCardsTree(response, selectedDeck?.deck_id || 0, selectedFolder?.folder_id || 0));
    }, [
        flashCardsListType,
        desc,
        title,
        selectedDeck?.deck_id,
        selectedFolder?.folder_id,
        setFlashCardsTree,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onKeyDownTitle = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

        if(evt.code === 'Escape') {
            stopAddingFolder();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onKeyDownDesc = async (evt: ReactKeyboardEvent<HTMLTextAreaElement>) => {
        if(handleWiziwig(
            evt as unknown as ReactKeyboardEvent<HTMLDivElement>,
            descRef as unknown as MutableRefObject<HTMLDivElement>,
            (newText) => {
                setDesc(newText);
            }
        )){
            return;
        }

        if(evt.code === 'Escape') {
            stopAddingFolder();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <>
            {
                addFolderMode === AddFolderMode.DEFAULT &&
                <button
                    onClick={ startAddingFolder }
                    type="button"
                    className="flex items-center text-sm my-4 text-slate-400">

                    <div className="pr-3">Add Folder</div>
                    <PlusIcon />

                </button>
            }

            {
                addFolderMode === AddFolderMode.ADDING &&
                <div className="my-4">
                    <input
                        ref={ titleRef }
                        onInput={ onTitleChange }
                        onKeyDown={ onKeyDownTitle }
                        className={ `bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none ${ isTitleValid ? '' : 'border-2 border-red-500' }` }
                        type="text"
                    />

                    {
                        !isTitleValid && <div className="text-red-500 text-xs mt-1">The title is required.</div>
                    }

                    <textarea
                        onInput={ onDescChange }
                        onKeyDown={ onKeyDownDesc }
                        className={ `bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none mt-4` }
                    />

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopAddingFolder }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default AddFolder;