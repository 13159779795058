import {
    ILibraryAuthor,
    ILibraryFolder,
    ILibraryData,
    ILibraryDataResponse,
    ILibraryGeneralAuthor,
    ILibraryBook,
    ILibraryBookFile,
    ILibraryTag,
    ILibraryTagView,
    ILibraryTagViewFormatted,
    ILibraryTagViewBook
} from '../interfaces';
import { getClosedSeries, getOpenedLibraryFolders } from './library-storage';

const setNestedFolders = (folder: ILibraryFolder, nestedFoldersMap: Map<number, ILibraryFolder[]>) => {
    folder.nestedFolders = nestedFoldersMap.get(folder.folder_id) || [];

    for(const nestedFolder of folder.nestedFolders) {
        setNestedFolders(nestedFolder, nestedFoldersMap);
    }
};

export const formatSelectedAuthor = (selectedAuthor: ILibraryGeneralAuthor|null) : ILibraryAuthor|null => {
    if(!selectedAuthor) return null;

    const author = selectedAuthor.author;

    const books = selectedAuthor.books || [];
    const bookFiles = selectedAuthor.bookFiles || [];
    const series = selectedAuthor.series || [];
    const booksWithoutSerie: ILibraryBook[] = [];
    const tags = selectedAuthor.tags || [];

    const serieBooksMap = new Map<number, ILibraryBook[]>(); // serie_id ---> serie books
    const bookFilesMap = new Map<number, ILibraryBookFile[]>(); // book_id ---> book files
    const bookTagsMap = new Map<number, ILibraryTag[]>(); // book_id ---> tags

    for(const bookFile of bookFiles) {
        const _bookFiles = bookFilesMap.get(bookFile.book_id) || [];
        _bookFiles.push(bookFile);
        bookFilesMap.set(bookFile.book_id, _bookFiles);
    }

    for(const tag of tags) {
        const _bookTags = bookTagsMap.get(tag.book_id) || [];
        _bookTags.push(tag);
        bookTagsMap.set(tag.book_id, _bookTags);
    }

    for(const book of books) {
        book.bookFiles = bookFilesMap.get(book.book_id) || [];
        book.tags = bookTagsMap.get(book.book_id) || [];

        if(!book.serie_id) {
            booksWithoutSerie.push(book);
            continue;
        }

        const serieBooks = serieBooksMap.get(book.serie_id) || [];
        serieBooks.push(book);
        serieBooksMap.set(book.serie_id, serieBooks);
    }

    const closedSeries = new Set(getClosedSeries());

    for(const serie of series) {
        serie.books = serieBooksMap.get(serie.serie_id) || [];
        serie.isClosed = closedSeries.has(serie.serie_id);
    }

    return {
        ...author,
        booksWithoutSerie,
        series: selectedAuthor.series || [],
        books,
        bookFiles,
    };
};

export const formatLibraryData = (response: ILibraryDataResponse) : ILibraryData => {

    const folders = response?.folders || [];
    const authors = response?.authors || [];
    const selectedAuthor = formatSelectedAuthor(response.selectedAuthor);

    const folderAuthors = new Map<number, ILibraryAuthor[]>(); // folder_id ---> authors list

    for(const author of authors) {
        if(!author.folder_id) continue;

        const _authors = folderAuthors.get(author.folder_id) || [];
        _authors.push(author);
        folderAuthors.set(author.folder_id, _authors);
    }

    const openedFolders = getOpenedLibraryFolders();

    const nestedFoldersMap = new Map<number, ILibraryFolder[]>(); // folder_id ---> nested folders

    for(const folder of folders) {
        folder.isOpened = openedFolders.has(folder.folder_id);
        folder.authors = folderAuthors.get(folder.folder_id) || [];

        if(!folder.parent_folder_id) {
            folder.isRootFolder = true;
            continue;
        }

        const nestedFolders = nestedFoldersMap.get(folder.parent_folder_id) || [];
        nestedFolders.push(folder);
        nestedFoldersMap.set(folder.parent_folder_id, nestedFolders);
    }

    const rootFolders = folders.filter(folder => !folder.parent_folder_id);

    for(const rootFolder of rootFolders) {
        setNestedFolders(rootFolder, nestedFoldersMap);
    }

    return {
        menu: rootFolders,
        folders,
        authors: response.authors || [],
        selectedAuthor,
    };
};

export const formatTagsData = (tagsView: ILibraryTagView) : ILibraryTagViewFormatted|null => {
    if(!tagsView) return null;

    const tags = tagsView.tags || [];
    const tagsBooks = tagsView.tagsBooks || [];

    const tagBooksMap = new Map<number, ILibraryTagViewBook[]>(); // tag_id ---> tag books

    for(const book of tagsBooks) {
        if(!book.tag_id) continue;

        const _books = tagBooksMap.get(book.tag_id) || [];
        _books.push(book);
        tagBooksMap.set(book.tag_id, _books);
    }

    for(const tag of tags) {
        tag.books = tagBooksMap.get(tag.tag_id);
    }

    return {
        tags,
    }
};