import { create } from 'zustand';
import { ILibraryBook, ILibraryData, ISelectedLibraryItem } from '../interfaces';

interface LibraryState {
    libraryData: ILibraryData|null;
    setLibraryData: (_libraryData: ILibraryData|null) => void;

    selectedLibraryItem: ISelectedLibraryItem|null;
    setSelectedLibraryItem: (_selectedLibraryItem: ISelectedLibraryItem|null) => void;

    addTagPopupShown: boolean;
    setAddTagPopupShown: (_addTagPopupShown: boolean) => void;

    addTagPopupBook: ILibraryBook|null;
    setAddTagPopupBook: (_addTagPopupBook: ILibraryBook|null) => void;
}

export const useLibraryStore = create<LibraryState>()((set) => ({
    libraryData: null,
    setLibraryData: (_libraryData: ILibraryData|null) => {
        return set((state: LibraryState) => {
            return {
                ...state,
                libraryData: _libraryData,
            };
        });
    },

    selectedLibraryItem: null,
    setSelectedLibraryItem: (_selectedLibraryItem: ISelectedLibraryItem|null) => {
        return set((state: LibraryState) => {
            return {
                ...state,
                selectedLibraryItem: _selectedLibraryItem,
            };
        });
    },

    addTagPopupShown: false,
    setAddTagPopupShown: (_addTagPopupShown: boolean) => {
        return set((state: LibraryState) => {
            return {
                ...state,
                addTagPopupShown: _addTagPopupShown,
            };
        });
    },

    addTagPopupBook: null,
    setAddTagPopupBook: (_addTagPopupBook: ILibraryBook|null) => {
        return set((state: LibraryState) => {
            return {
                ...state,
                addTagPopupBook: _addTagPopupBook,
            };
        });
    },
}))