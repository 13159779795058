import { PreloaderIcon, SearchIcon } from '../../../../common/ui/icons';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { IFlashCard } from '../../flashcards-interfaces';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { searchFlashCards } from '../../data/flashcards-data-service';
import { showToast } from '../../../../common/domain/toast-provider';

const FlashCardsSearchView = () => {

    const [term, setTerm] = useState('');
    const [searchResults, setSearchResults] = useState<IFlashCard[]>([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const startSearch = async () => {
        if(term.trim() === '') {
            setSearchResults([]);
            return;
        }

        setSearchLoading(true);
        const response = await searchFlashCards(term);
        setSearchLoading(false);

        if(!response) {
            showToast('Search error.');
            return;
        }

        setSearchResults(response);
    };

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTerm(evt.target.value);
    };

    const onKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Escape') {
            setTerm('');
        }

        if(evt.code === 'Enter') {
            startSearch();
        }
    };

    return (
        <div className="mb-6 w-full flex flex-col my-6">

            <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center">
                <SearchIcon classes="mr-4" size={ 35 } color={ '#2E766E' } />
                Search
            </h1>

            <div className="flex flex-col my-4">

                <div className="flex items-start">
                    <div className="flex flex-col flex-1 mr-4">
                        <input
                            className="flex-1 border rounded outline-none px-4 py-2 mb-2"
                            type="text"
                            placeholder="Search..."
                            value={ term }
                            onInput={ onChange }
                            onKeyUp={ onKeyUp }
                        />
                        <div className="text-sm text-right">{ searchResults.length } results</div>
                    </div>

                    <button
                        onClick={ startSearch }
                        className="flex bg-teal-600 rounded-xl justify-center items-center"
                        type="button">

                        {
                            !searchLoading &&
                            <SearchIcon color={ '#efefef' } size={ 40 } />
                        }

                        {
                            searchLoading &&
                            <PreloaderIcon color={ '#efefef' } size={ 40 } />
                        }
                    </button>
                </div>
            </div>

            <div className="flash-cards-search-results">
                {
                    searchResults.map(flashCard => {

                        console.log('flashCard.card_name', flashCard.card_name)
                        return (
                            <div
                                className={ `border-b pb-2 mb-2 markdown-editor` }
                                key={ flashCard.card_id }>

                                <a
                                    href={ `/flashcards/${ flashCard.deck_id }#card-${ flashCard.card_id }` }
                                    className="underline"
                                    dangerouslySetInnerHTML={{__html: flashCard.card_name }}
                                />

                                {
                                    flashCard.card_front &&
                                    <div
                                        className="text-sm"
                                        dangerouslySetInnerHTML={{__html: markdown2Html(flashCard.card_front) }}
                                    />
                                }

                                {
                                    flashCard.card_back &&
                                    <div
                                        className="text-sm"
                                        dangerouslySetInnerHTML={{__html: markdown2Html(flashCard.card_back) }}
                                    />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default FlashCardsSearchView;