import { useState, MouseEvent } from 'react';
import { deleteAttachment } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodoAttachment } from '../../../planner-interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoDeleteAttachmentButtonProps {
    attachment: ITodoAttachment;
}

const TodoDeleteAttachmentButton = (props: ITodoDeleteAttachmentButtonProps) => {

    const { attachment } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const deleteAttachmentHandler = async (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        setLoading(true);
        const response = await deleteAttachment(
            attachment.attachment_id,
            attachment.attachment_path,
        );
        setLoading(false);

        if(!response?.isValid) {
            showToast('Delete attachment error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <button
            onClick={ deleteAttachmentHandler }
            type="button"
            title="Delete Attachment"
            className="mr-2">

            {
                !isLoading &&
                <DeleteIcon size={ 20 } classes="pointer-events-none" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } />
            }
        </button>
    )
};

export default TodoDeleteAttachmentButton;