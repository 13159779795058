import {
    FlashCardsPerformance,
    IDeck,
    IFlashCard, IFlashCardFolder,
    IFlashCardsResponse,
    IFlashCardsTree
} from '../flashcards-interfaces';
import { addMilliseconds } from 'date-fns';
import { getOpenedFlashcardsFolders } from './flashcards-storage';
import { useFlashCardsStore } from '../data/flashcards-store';

/**
 * A spaced repetition algorithm based on the SuperMemo SM-2 algorithm,
 * which was originally developed in the late 1980s.
 * This algorithm is particularly effective for managing long-term retention of information.
 */

export const INITIAL_EASE_FACTOR = 0.25;
export const MIN_EASE_FACTOR = 0.1;

export const MIN_DEFAULT_INTERVAL = 24 * 60 * 60 * 1000; // 1 day in milliseconds
export const EASY_FACTOR = 1.5;
export const GOOD_FACTOR = 1;
export const HARD_FACTOR = 0.15;

export const FLASHCARDS_ITEM_DRAG_CLASS = 'flashcards-item-dragging';
export const FLASHCARDS_ITEM_DRAG_OVER_CLASS = 'flashcards-item-dragging__over';

export const calculateNextReviewDate = (
    performance: FlashCardsPerformance,
    card_ease_factor: number,
    card_interval: number, // milliseconds
    last_review_date: number // milliseconds
) => {
    const [newInterval, newEaseFactor] = calculateNextFactors(
        performance,
        card_ease_factor,
        card_interval
    );

    const lastReviewDate = last_review_date ? new Date(last_review_date) : new Date();
    const newReviewDate = addMilliseconds(lastReviewDate, newInterval);
    return [newReviewDate.getTime(), newEaseFactor];
};

const calculateNextFactors = (
    performance: FlashCardsPerformance,
    card_ease_factor: number,
    card_interval: number // milliseconds
) : [number, number] => {

    // By default, new interval and the ease factor
    // are the same as the previous values.
    let newInterval: number = card_interval;
    let newEaseFactor = card_ease_factor;

    switch (performance) {

        case FlashCardsPerformance.Again: {
            // Resets the interval to a default duration of 1 day IN MILLISECONDS.
            newInterval = MIN_DEFAULT_INTERVAL;

            // The "ease factor" returns to its default value.
            newEaseFactor = INITIAL_EASE_FACTOR;
            break;
        }

        case FlashCardsPerformance.Easy: {
            // Card is easier so can wait longer to review.
            newEaseFactor = Math.max(card_ease_factor * EASY_FACTOR);
            newInterval = Math.max(MIN_DEFAULT_INTERVAL, card_interval * newEaseFactor);
            break;
        }

        case FlashCardsPerformance.Good: {
            // Card is OK so can wait the same time before the next review.
            newEaseFactor = Math.max(MIN_EASE_FACTOR, card_ease_factor * GOOD_FACTOR);
            newInterval = Math.max(MIN_DEFAULT_INTERVAL, card_interval * newEaseFactor);
            break;
        }

        case FlashCardsPerformance.Hard: {
            // Card is hard, so we can wait less time before the next review.
            // The "ease factor" might be reduced.
            newEaseFactor = Math.max(MIN_EASE_FACTOR, card_ease_factor - HARD_FACTOR);
            newInterval = Math.max(MIN_DEFAULT_INTERVAL, card_interval * newEaseFactor);
            break;
        }
    }

    return [newInterval, newEaseFactor];
};

const createLearningFlow = (newCards: IFlashCard[], learningCards: IFlashCard[], reviewCards: IFlashCard[]) : IFlashCard[] => {
    return [...learningCards, ...newCards, ...reviewCards];
};

const generateFlashCardsTree = (decks: IDeck[], folders: IFlashCardFolder[]) : IFlashCardsTree => {

    const folderDecksMap = new Map<number, IDeck[]>(); // folder_id ---> nested decks

    for(const deck of decks) {
        const parent_folder_id = deck.folder_id || 0;
        const _decks = folderDecksMap.get(parent_folder_id) || [];
        _decks.push(deck);
        folderDecksMap.set(parent_folder_id, _decks);
    }

    const openedFolders = getOpenedFlashcardsFolders();
    const nestedFoldersMap = new Map<number, IFlashCardFolder[]>(); // folder_id ---> nested folders

    for(const folder of folders) {
        const parent_folder_id = folder.parent_folder_id || 0;
        const _nested = nestedFoldersMap.get(parent_folder_id) || [];
        _nested.push(folder);
        nestedFoldersMap.set(parent_folder_id, _nested);

        folder.decks = folderDecksMap.get(folder.folder_id) || [];
        folder.isOpened = openedFolders.has(folder.folder_id);
    }

    for(const folder of folders) {
        folder.folders = nestedFoldersMap.get(folder.folder_id) || [];
    }

    const rootFolders: IFlashCardFolder[] = nestedFoldersMap.get(0) || [];
    const rootDecks: IDeck[] = folderDecksMap.get(0) || [];

    return {
        rootDecks,
        rootFolders,
    };
};

const openSelectedFolder = (folders?: IFlashCardFolder[], selectedFolder?: IFlashCardFolder) => {
    if(!folders || !selectedFolder) return;

    selectedFolder.isOpened = true;

    const parentFolder = folders.find(folder => folder.folder_id === selectedFolder.parent_folder_id);
    if(!parentFolder) return;

    openSelectedFolder(folders, parentFolder);
};

export const formatFlashCardsTree = (response: IFlashCardsResponse, _deck_id?: number, _folder_id?: number) : IFlashCardsTree|null => {
    if(!response) return null;

    const state = useFlashCardsStore.getState();

    const decks = response?.decks || [];
    const folders = response?.folders || [];

    state.setFlashCardDecks(decks);
    state.setFlashCards(response?.cards);

    state.setLearningFlowCards(
        createLearningFlow(
            response?.newCards || [],
            response?.learningCards || [],
            response?.reviewCards || []
        )
    );

    let selectedDeck: IDeck|undefined = undefined;
    if(_deck_id !== undefined && decks.length > 0) {
        selectedDeck = decks.find(deck => deck.deck_id === _deck_id);
        state.setSelectedDeck(selectedDeck ?? undefined);
    }

    let selectedFolder: IFlashCardFolder|undefined = undefined;
    if(_folder_id !== undefined && folders.length > 0) {
        selectedFolder = folders.find(folder => folder.folder_id === _folder_id);
        state.setSelectedFolder(selectedFolder ?? undefined);
    }

    const tree = generateFlashCardsTree(decks, folders);

    // Open all thw chain of selected folder.
    if(selectedDeck || selectedFolder) {
        openSelectedFolder(folders, selectedDeck ?
            folders.find(folder => folder.folder_id === selectedDeck.folder_id) :
            selectedFolder
        );
    }

    return tree;
};

const setFlashCardsFolderOpenedClosedHelper = (folders: IFlashCardFolder[], openedFolders: Set<number>) => {
    for(const folder of folders) {
        folder.isOpened = openedFolders.has(folder.folder_id);

        if(folder.folders) {
            setFlashCardsFolderOpenedClosedHelper(folder.folders, openedFolders);
        }
    }
};

export const setFlashCardsFolderOpenedClosed = (flashCardsTree: IFlashCardsTree|null) : IFlashCardsTree => {
    if(!flashCardsTree) return null;

    const openedFolders = getOpenedFlashcardsFolders();

    const copy = { ...flashCardsTree };

    for(const folder of copy.rootFolders) {
        folder.isOpened = openedFolders.has(folder.folder_id);

        if(folder.folders) {
            setFlashCardsFolderOpenedClosedHelper(folder.folders, openedFolders);
        }
    }

    return copy;
};

export const getFlashCardIdFromHash = (hash: string) : number|null => {
    const _hash = (hash ?? '').replace('#', '').trim();
    if(!_hash) return null;
    const _id = _hash.replace('card-', '').trim();
    const id = Number(_id);
    return isNaN(id) ? null : id;
};