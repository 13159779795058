import { useState } from 'react';
import { deleteSection } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface IDeleteSectionProps {
    section_id: number;
    section_title: string;
    project_id: number;
}

const DeleteSectionButton = (props: IDeleteSectionProps) => {

    const { section_id, section_title, project_id } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const deleteSectionHandler = async () => {

        if(!confirm(`Are you sure you want to delete the section "${ section_title }" and all its tasks?`)) return;

        setLoading(true);
        const response = await deleteSection(
            project_id,
            section_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete section error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Section"
                    className="section__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteSectionHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteSectionButton;