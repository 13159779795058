import { DragEvent } from 'react';
import {
    FLASHCARDS_ITEM_DRAG_OVER_CLASS,
    formatFlashCardsTree,
} from '../../../domain/flashcards-provider';
import { showToast } from '../../../../../common/domain/toast-provider';
import { dragCard } from '../../../data/flashcards-data-service';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDragCardItemItemTarget {
    deck_id: number;
    targetCardId: number;
    isBefore: boolean;
}

const DragCardItemTarget = ({ deck_id, targetCardId, isBefore }: IDragCardItemItemTarget) => {

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(FLASHCARDS_ITEM_DRAG_OVER_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(FLASHCARDS_ITEM_DRAG_OVER_CLASS);
    };

    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data.trim()))) {
            showToast('Drop flashcard item error.');
            return;
        }

        const sourceCardId = Number(data);

        const response = await dragCard(
            sourceCardId,
            targetCardId,
            isBefore,
            deck_id,
            flashCardsListType,
        );

        if(!response?.isValid) {
            showToast('Drag card item error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, deck_id));

        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className={ `flashcards-drag-item__target w-full mt-2 h-[5px] bg-slate-500 rounded hidden` }
        />
    )
};

export default DragCardItemTarget;