import { IMetaData } from '../../diagram-interfaces';

interface IDiagramEdge {
    startMeta: IMetaData;
    endMeta: IMetaData;
}

const DiagramEdge = ({ startMeta, endMeta }: IDiagramEdge) => {

    return (
        <line
            x1={ startMeta.x + startMeta.w / 2 }
            y1={ startMeta.y + startMeta.h }
            x2={ endMeta.x + endMeta.w / 2 }
            y2={ endMeta.y }
            stroke={ '#d7d6d6' }
        />
    )
};
export default DiagramEdge;