import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { deleteFolder } from '../../../data/flashcards-data-service';
import { FlashCardsListView } from '../../../flashcards-interfaces';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IFlashCardsDeleteFolderButton {
    folder_id: number;
    folder_title: string;
}

const FlashCardsDeleteFolderButton = ({ folder_id, folder_title }: IFlashCardsDeleteFolderButton) => {

    const [isLoading, setLoading] = useState(false);

    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const navigate = useNavigate();

    const deleteFolderHandler = async () => {

        if(!confirm(`Are you sure you want to delete the folder "${ folder_title }"?`)) return;

        setLoading(true);
        const response = await deleteFolder(
            FlashCardsListView.FolderView,
            folder_id,
            0,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete folder error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, undefined, folder_id));

        navigate('/flashcards');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Folder"
                    className="flash-cards-folder__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteFolderHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default FlashCardsDeleteFolderButton;