import { SunIcon } from '../../../../common/ui/icons';
import { startOfMonth } from 'date-fns';
import { useHabitTrackerStore } from '../../data/habit-tracker-store';

const TodayButton = () => {

    const setHabitTrackerStartDate = useHabitTrackerStore(store => store.setHabitTrackerStartDate);

    const jumpToday = () => {
        setHabitTrackerStartDate(startOfMonth((new Date())));
    };

    return (
        <button
            onClick={ jumpToday }
            className="flex items-center"
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mr-1"
            />
            Today
        </button>
    )
};

export default TodayButton;