import { PlusIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { insertLibraryFolder } from '../../data/library-data-service';
import { formatLibraryData } from '../../domain/library-provider';
import { openLibraryFolder } from '../../domain/library-storage';
import { ESelectedLibraryItemType } from '../../interfaces';
import { useLibraryStore } from '../../data/library-store';

enum AddFolderMode {
    DEFAULT = 0,
    ADDING = 1,
}

const AddFolder = () => {

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(store => store.selectedLibraryItem);

    const [addFolderMode, setAddFolderMode] = useState(AddFolderMode.DEFAULT);
    const [isLoading, setLoading] = useState(false);

    const [folderTitle, setFolderTitle] = useState('');
    const [folderTitleTouched, setFolderTitleTouched] = useState(false);

    const isFolderTitleValid = !folderTitleTouched || folderTitle.trim().length > 0;
    const folderTitleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        folderTitleRef?.current?.focus();
    }, [addFolderMode]);

    const startAddingFolder = () => {
        setAddFolderMode(AddFolderMode.ADDING);
    };

    const stopAddingFolder = () => {
        setAddFolderMode(AddFolderMode.DEFAULT);
    };

    const onFolderTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setFolderTitle((evt.target as HTMLInputElement).value);
        setFolderTitleTouched(true);
    };

    const save = async () => {
        if(folderTitle.trim().length <= 0) {
            setFolderTitleTouched(true);
            return;
        }

        setLoading(true);

        const parentFolderId = selectedLibraryItem?.id || 0;

        const response = await insertLibraryFolder(
            folderTitle.trim(),
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
            '',
            parentFolderId,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert folder error.');
            return;
        }

        stopAddingFolder();

        if(parentFolderId) {
            openLibraryFolder(parentFolderId);
        }

        setLibraryData(formatLibraryData(response || []));
    };

    const onKeyDown = async (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Escape') {
            stopAddingFolder();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <>
            {
                addFolderMode === AddFolderMode.DEFAULT &&
                <button
                    onClick={ startAddingFolder }
                    type="button"
                    className="flex items-center text-sm mt-4 mb-4 text-slate-400">
                    <div className="mr-3"><PlusIcon /></div>
                    <div>Add Folder</div>
                </button>
            }

            {
                addFolderMode === AddFolderMode.ADDING &&
                <div className="mt-4 mb-4">
                    <input
                        ref={ folderTitleRef }
                        placeholder="Folder Title"
                        onInput={ onFolderTitleChange }
                        value={ folderTitle }
                        onKeyDown={ onKeyDown }
                        className={ `bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none ${ isFolderTitleValid ? '' : 'border-2 border-red-500' }` }
                        type="text"
                    />

                    {
                        !isFolderTitleValid && <div className="text-red-500 text-xs mt-1">The folder title is required.</div>
                    }

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopAddingFolder }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default AddFolder;