import { DragEvent } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { dragBookToSerie } from '../../../data/library-data-service';
import { useLibraryStore } from '../../../data/library-store';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType } from '../../../interfaces';

const DRAG_OVER_CLASS = 'section__drag-target__over';

interface IDragBookToSerieTarget {
    serie_id: number;
    author_id: number;
}

const DragBookToSerieTarget = ({ serie_id, author_id }: IDragBookToSerieTarget) => {

    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const targetOnDragOver = (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
    };

    const targetOnDragEnter = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.add(DRAG_OVER_CLASS);
    };

    const targetOnDragLeave = (evt: DragEvent<HTMLDivElement>) => {
        evt.currentTarget.classList.remove(DRAG_OVER_CLASS);
    };

    const targetOnDrop = async (evt: DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        const data = evt.dataTransfer.getData('text');

        if(data === null ||
            data === undefined ||
            data.trim() === '' ||
            isNaN(Number(data))) {
            showToast('Drag book to section error.');
            return;
        }

        const book_id = Number(data);

        const response = await dragBookToSerie(
            book_id,
            serie_id,
            ESelectedLibraryItemType.AUTHOR,
            author_id,
        );

        if(!response) {
            showToast('Drag book to section error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));

        sendCloseActionsEvent();
    };

    return (
        <div
            onDragOver={ targetOnDragOver }
            onDragEnter={ targetOnDragEnter }
            onDragLeave={ targetOnDragLeave }
            onDrop={ targetOnDrop }
            className="library__drag-book-to-serie__target w-full h-[5px] bg-stone-300 rounded my-4 hidden"
        ></div>
    )
};

export default DragBookToSerieTarget;