import { EditIcon } from '../../../../../common/ui/icons';
import { ADD_NEW_PROJECT_DESCRIPTION_EVENT } from '../description/project-description';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface IProjectEditButtonProps {
    project_id: number;
}

const ProjectEditButton = (props: IProjectEditButtonProps) => {

    const { project_id } = props;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_PROJECT_DESCRIPTION_EVENT, {
            detail: project_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Project"
            className="project__add-desc flex bg-slate-300 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default ProjectEditButton;