import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IFlashCard } from '../../../flashcards-interfaces';
import { deleteCard } from '../../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDeleteCardProps {
    card: IFlashCard;
}

const DeleteCardButton = ({ card }: IDeleteCardProps) => {

    const [isLoading, setLoading] = useState(false);

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const deleteCardHandler = async () => {

        if(!confirm(`Are you sure you want to delete card "${ card.card_name }"?`)) return;

        setLoading(true);
        const response = await deleteCard(
            flashCardsListType,
            card.card_id,
            card.deck_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete card error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, card.deck_id));

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Card"
                    className="card__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteCardHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteCardButton;