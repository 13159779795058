import { IPage } from '../../overview-interfaces';
import { useOverviewStore } from '../../data/overview-store';

interface IPageButton {
    page: IPage;
    title?: string;
}

const PageButton = (props: IPageButton) => {

    const { page } = props;
    const setCurrentPage = useOverviewStore(state => state.setCurrentPage);

    const changePage = () => {
        setCurrentPage(page.index);
    };

    return (
        <button
            className={ `mr-2 border bg-white rounded px-4 py-1 ${ page.isCurrent ? 'text-teal-600 border-teal-300' : 'border-slate-100' }` }
            key={ page.index }
            onClick={ changePage }
            type="button">
            { props.title ?? page.title }
        </button>
    )
};

export default PageButton;