import { SaveIcon } from '../../../../../common/ui/icons';
import { download } from '../../../../../common/domain/io-provider';

const ExportSvgBtn = () => {

    const downloadSvgAsPng = async () => {
        const $svg = document.getElementById('diagram-svg') as unknown as SVGSVGElement;
        if(!$svg) return;

        await download({
            $svg,
            outfileName: 'diagram',
            ext: 'png',
        });
    };

    return (
        <button
            onClick={ downloadSvgAsPng }
            className="ml-auto mr-10 xl:mr-0"
            type="button">
            <SaveIcon color={ '#fff' } />
        </button>
    )
};

export default ExportSvgBtn;