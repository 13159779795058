import { MouseEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { DeckDescriptionMode } from './deck-description';

interface IDeckFullDescriptionProps {
    parsedDesc: string;
    setMode: (mode: DeckDescriptionMode) => void;
}

const DeckFullDescription = (props: IDeckFullDescriptionProps) => {
    const { parsedDesc, setMode } = props;

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setDefaultMode = () => {
        setMode(DeckDescriptionMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(DeckDescriptionMode.EDIT);
    };

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setDefaultMode();
        }
    };

    return (
        <div className="flex flex-col mb-2">
            <div
                ref={ contentRef }
                tabIndex={ 0 }
                className="text-xs overflow-auto max-h-[500px] rounded my-1 outline-none"
                onClick={ onClick }
                onKeyDown={ onKeyDown }>
                <div
                    className="markdown whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{__html: parsedDesc }}
                />
            </div>
        </div>
    )
};

export default DeckFullDescription;