import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { BOOK_EDIT_EVENT } from '../book-edit';
import { ILibraryBook } from '../../../interfaces';

interface IBookEditButtonProps {
    book: ILibraryBook;
}

const BookEditButton = ({ book }: IBookEditButtonProps) => {

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(BOOK_EDIT_EVENT, {
            detail: book.book_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Book"
            className="book__edit flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default BookEditButton;