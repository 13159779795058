import { getJsonFromLocalStorage, saveJsonToLocalStorage } from '../../../common/data/storage-provider';
import { IOverviewStorage } from '../overview-interfaces';
import { CompletedFilterEnum } from '../ui/filters/completed-filter';
import { HasDateFilterEnum } from '../ui/filters/has-date-filter';
import { TodoType } from '../../planner/planner-interfaces';
import { RootFilterEnum } from '../ui/filters/root-filter';

const PLAN_OVERVIEW_KEY = 'PLAN_OVERVIEW_KEY';

export const saveOverviewToStorage = (
    completedFilter: CompletedFilterEnum,
    hasDateFilter: HasDateFilterEnum,
    todoTypeFilter: Set<TodoType>,
    rootFilter: RootFilterEnum
) => {
    const options: IOverviewStorage = {
        completedFilter,
        hasDateFilter,
        todoTypeFilter: Array.from(todoTypeFilter),
        rootFilter,
    };

    saveJsonToLocalStorage(PLAN_OVERVIEW_KEY, options);
};

export const getOverviewToFrom = (): IOverviewStorage|null => {
    const result = getJsonFromLocalStorage(PLAN_OVERVIEW_KEY);
    if(!result) return null;

    const completedFilter: CompletedFilterEnum = result.completedFilter || CompletedFilterEnum.NotCompleted;
    const hasDateFilter: HasDateFilterEnum = result.hasDateFilter || HasDateFilterEnum.Both;
    const todoTypeFilter = result.todoTypeFilter || [] as TodoType[];
    const rootFilter: RootFilterEnum = result.rootFilter || RootFilterEnum.Root;
    
    return {
        completedFilter,
        hasDateFilter,
        todoTypeFilter,
        rootFilter,
    };
};