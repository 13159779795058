import { create } from 'zustand';
import {
    FlashCardsListView,
    FlashCardsStudyMode,
    IDeck,
    IFlashCard,
    IFlashCardFolder,
    IFlashCardsTree
} from '../flashcards-interfaces';

interface FlashCardsState {
    /**
     * Left menu with folders and decks.
     */
    flashCardsTree: IFlashCardsTree|null;
    setFlashCardsTree: (_flashCardsTree: IFlashCardsTree|null) => void;

    flashCardDecks: IDeck[];
    setFlashCardDecks: (_flashCardDecks: IDeck[]) => void;

    flashCards: IFlashCard[];
    setFlashCards: (_flashCards: IFlashCard[]) => void;

    selectedDeck?: IDeck;
    setSelectedDeck: (_selectedDeck: IDeck) => void;

    selectedFolder?: IFlashCardFolder;
    setSelectedFolder: (_selectedFolder: IFlashCardFolder) => void;

    learningFlowCards?: IFlashCard[];
    setLearningFlowCards: (_learningFlowCards: IFlashCard[]) => void;

    learningFlowIndex: number;
    setLearningFlowIndex: (_learningFlowIndex: number) => void;

    flashCardsListType: FlashCardsListView;
    setFlashCardsListType: (_flashCardsListType: FlashCardsListView) => void;

    flashCardsStudyMode: FlashCardsStudyMode;
    setFlashCardsStudyMode: (_flashCardsStudyMode: FlashCardsStudyMode) => void;
}

export const useFlashCardsStore = create<FlashCardsState>()((set) => ({
    flashCardsTree: null,
    setFlashCardsTree: (_flashCardsTree: IFlashCardsTree|null) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                flashCardsTree: _flashCardsTree,
            };
        });
    },

    flashCardDecks: [],
    setFlashCardDecks: (_flashCardDecks: IDeck[]) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                flashCardDecks: _flashCardDecks,
            };
        });
    },

    flashCards: [],
    setFlashCards: (_flashCards: IFlashCard[]) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                flashCards: _flashCards,
            };
        });
    },

    selectedDeck: undefined,
    setSelectedDeck: (_selectedDeck: IDeck) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                selectedDeck: _selectedDeck,
            };
        });
    },

    selectedFolder: undefined,
    setSelectedFolder: (_selectedFolder: IFlashCardFolder) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                selectedFolder: _selectedFolder,
            };
        });
    },

    /*selectedCard: undefined,
    setSelectedCard: (_selectedCard: IFlashCard) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                selectedCard: _selectedCard,
            };
        });
    },

    selectedCardLatestReview: undefined,
    setSelectedCardLatestReview: (_selectedCardLatestReview: IFlashCardReview) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                selectedCardLatestReview: _selectedCardLatestReview,
            };
        });
    },*/

    learningFlowCards: [],
    setLearningFlowCards: (_learningFlowCards: IFlashCard[]) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                learningFlowCards: _learningFlowCards,
            };
        });
    },

    learningFlowIndex: 0,
    setLearningFlowIndex: (_learningFlowIndex: number) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                learningFlowIndex: _learningFlowIndex,
            };
        });
    },

    flashCardsListType: FlashCardsListView.DeckView,
    setFlashCardsListType: (_flashCardsListType: FlashCardsListView) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                flashCardsListType: _flashCardsListType,
            };
        });
    },

    flashCardsStudyMode: FlashCardsStudyMode.Question,
    setFlashCardsStudyMode: (_flashCardsStudyMode: FlashCardsStudyMode) => {
        return set((state: FlashCardsState) => {
            return {
                ...state,
                flashCardsStudyMode: _flashCardsStudyMode,
            };
        });
    },
}))