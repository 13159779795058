import { useOverviewStore } from '../../data/overview-store';

const PaginationLabel = () => {

    const currentPage = useOverviewStore(state => state.currentPage);
    const overviewData = useOverviewStore(state => state.overviewData);

    return (
        <div className="mr-10">
            { currentPage + 1 } of { overviewData.paging.pagesNumber }
        </div>
    )
};

export default PaginationLabel;