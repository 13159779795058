import { FolderIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { usePlannerStore } from '../../../data/planner-store';

const CloseAllProjectsBtn = () => {

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const closeAllSections = () => {
        if(!combinedProject) return;

        for(const project of combinedProject.projects) {
            project.isOpened = false;
        }

        setCombinedProject(JSON.parse(JSON.stringify(combinedProject)));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Close All Projects"
            className="flex bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ closeAllSections }
            type="button">
            <FolderIcon size={ 20 } />
        </button>
    )
};

export default CloseAllProjectsBtn;