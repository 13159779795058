import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import EditFolder from './folder-edit';
import { IFlashCardFolder } from '../../flashcards-interfaces';

export const FLASH_CARDS_FOLDER_DESCRIPTION_EVENT = 'flash-cards-folder-desc-event';

export enum FlashCardsFolderDescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IFlashCardsFolderDescription {
    flashCardsFolder: IFlashCardFolder;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const FlashCardsFolderDescription = ({ flashCardsFolder }: IFlashCardsFolderDescription) => {

    const [mode, setMode] = useState<FlashCardsFolderDescriptionMode>(FlashCardsFolderDescriptionMode.DEFAULT);

    const descExists = isDescExists(flashCardsFolder.folder_desc);

    const parsedDesc = descExists ? markdown2Html(flashCardsFolder.folder_desc) : '';

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const setDefaultMode = () => {
        setMode(FlashCardsFolderDescriptionMode.DEFAULT);
    };

    const startEdit = () => {
        setMode(FlashCardsFolderDescriptionMode.EDIT);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const folder_id = Number(evt?.detail) || 0;
            console.log('folder_id', folder_id)
            if(folder_id !== flashCardsFolder.folder_id) return;
            setMode(FlashCardsFolderDescriptionMode.EDIT);
        };

        document.addEventListener(FLASH_CARDS_FOLDER_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(FLASH_CARDS_FOLDER_DESCRIPTION_EVENT, handler);
        };
    }, [flashCardsFolder.folder_id]);

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            setDefaultMode();
        }
    };

    return (
        <>
            {
                (descExists && mode === FlashCardsFolderDescriptionMode.DEFAULT) &&
                <div className="flex flex-col">
                    <div
                        ref={ contentRef }
                        tabIndex={ 0 }
                        className="text-xs overflow-auto max-h-[500px] rounded my-1 outline-none"
                        onClick={ onClick }
                        onKeyDown={ onKeyDown }>
                        <div
                            className="markdown whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{__html: parsedDesc }}
                        />
                    </div>
                </div>
            }

            {
                (mode === FlashCardsFolderDescriptionMode.EDIT) &&
                <EditFolder
                    folder={ flashCardsFolder }
                    setMode={ setDefaultMode }
                />
            }
        </>
    )
};

export default FlashCardsFolderDescription;