import { PlusIcon } from '../../../../../common/ui/icons';
import { useState } from 'react';
import AddCardForm from '../add-card-form';

enum AddCardMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface IAddCardBtn {
    deck_id: number;
}

const AddCardBtn = ({ deck_id }: IAddCardBtn) => {

    const [addCardMode, setCardTodoMode] = useState(AddCardMode.DEFAULT);

    const startAddingCard = () => {
        setCardTodoMode(AddCardMode.ADDING);
    };

    const stopAddingCard = () => {
        setCardTodoMode(AddCardMode.DEFAULT);
    };

    return (
        <div>
            {
                (addCardMode === AddCardMode.DEFAULT) &&
                <button
                    className={ `card__add-card flex items-center text-sm text-slate-400 my-4` }
                    onClick={ startAddingCard }
                    type="button">
                    <div className="mr-2"><PlusIcon /></div>
                    <div>Add Card</div>
                </button>
            }

            {
                (addCardMode === AddCardMode.ADDING) &&
                <AddCardForm
                    deck_id={ deck_id }
                    cancel={ stopAddingCard }
                />
            }
        </div>
    )
};

export default AddCardBtn;