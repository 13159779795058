import { BracketsIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { ADD_NEW_TODO_BLOCKS_EVENT } from './todo-blocks';

interface ITodoEditBlocksButtonProps {
    todo: ITodo;
}

const TodoEditBlocksButton = (props: ITodoEditBlocksButtonProps) => {

    const { todo } = props;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_BLOCKS_EVENT, {
            detail: todo.todo_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Blocks"
            className="todo__edit-blocks flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <BracketsIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default TodoEditBlocksButton;