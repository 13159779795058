import { create } from 'zustand';
import { IOverview } from '../overview-interfaces';
import { CompletedFilterEnum } from '../ui/filters/completed-filter';
import { HasDateFilterEnum } from '../ui/filters/has-date-filter';
import { TodoType } from '../../planner/planner-interfaces';
import { RootFilterEnum } from '../ui/filters/root-filter';
import { getOverviewToFrom, saveOverviewToStorage } from '../domain/overview-storage-provider';

interface OverviewState {
    overviewData: IOverview|null;
    setOverviewData: (_overviewData: IOverview|null) => void;

    currentPage: number;
    setCurrentPage: (_setPage: number) => void;

    sortBy: string;
    setSortBy: (_sortBy: string) => void;

    sortDirection: string;
    setSortDirection: (_sortDirection: string) => void;

    completedFilter: CompletedFilterEnum;
    setCompletedFilter: (_completedFilter: CompletedFilterEnum) => void;

    hasDateFilter: HasDateFilterEnum;
    setHasDateFilter: (_hasDateFilter: HasDateFilterEnum) => void;

    todoTypeFilter: Set<TodoType>;
    setTodoTypeFilter: (_todoTypeFilter: Set<TodoType>) => void;

    rootFilter: RootFilterEnum;
    setRootFilter: (_hasDateFilter: RootFilterEnum) => void;
}

const restored = getOverviewToFrom();

export const useOverviewStore = create<OverviewState>()((set) => ({
    overviewData: null,
    setOverviewData: (_overviewData: IOverview|null) => {
        return set((state: OverviewState) => {
            return {
                ...state,
                overviewData: _overviewData,
            };
        });
    },

    currentPage: 0,
    setCurrentPage: (_currentPage: number) => {
        return set((state: OverviewState) => {
            return {
                ...state,
                currentPage: _currentPage,
            };
        });
    },

    sortBy: 'completeness_percent',
    setSortBy: (_sortBy: string) => {
        return set((state: OverviewState) => {
            return {
                ...state,
                sortBy: _sortBy,
            };
        });
    },

    sortDirection: 'DESC',
    setSortDirection: (_sortDirection: string) => {
        return set((state: OverviewState) => {
            return {
                ...state,
                sortDirection: _sortDirection,
            };
        });
    },

    completedFilter: restored?.completedFilter ?? CompletedFilterEnum.NotCompleted,
    setCompletedFilter: (_completedFilter: CompletedFilterEnum) => {
        return set((state: OverviewState) => {

            saveOverviewToStorage(
                _completedFilter,
                state.hasDateFilter,
                state.todoTypeFilter,
                state.rootFilter
            );

            return {
                ...state,
                completedFilter: _completedFilter,
            };
        });
    },

    hasDateFilter: restored?.hasDateFilter ?? HasDateFilterEnum.Both,
    setHasDateFilter: (_hasDateFilter: HasDateFilterEnum) => {
        return set((state: OverviewState) => {

            saveOverviewToStorage(
                state.completedFilter,
                _hasDateFilter,
                state.todoTypeFilter,
                state.rootFilter
            );

            return {
                ...state,
                hasDateFilter: _hasDateFilter,
            };
        });
    },

    todoTypeFilter: new Set<TodoType>(restored?.todoTypeFilter || []) ?? new Set<TodoType>(),
    setTodoTypeFilter: (_todoTypeFilter: Set<TodoType>) => {
        return set((state: OverviewState) => {

            saveOverviewToStorage(
                state.completedFilter,
                state.hasDateFilter,
                _todoTypeFilter,
                state.rootFilter
            );

            return {
                ...state,
                todoTypeFilter: _todoTypeFilter,
            };
        });
    },

    rootFilter: restored?.rootFilter ?? RootFilterEnum.Both,
    setRootFilter: (_rootFilter: RootFilterEnum) => {
        return set((state: OverviewState) => {

            saveOverviewToStorage(
                state.completedFilter,
                state.hasDateFilter,
                state.todoTypeFilter,
                _rootFilter
            );

            return {
                ...state,
                rootFilter: _rootFilter,
            };
        });
    },
}))