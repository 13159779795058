import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { loginDoubleOptIn } from '../../data/auth-data-service';
import { useNavigate } from 'react-router-dom';
import { LoginMode } from '../login';

interface IDoubleOptIn {
    setLoginMode: (loginMode: LoginMode) => void;
}

const DoubleOptIn = ({ setLoginMode }: IDoubleOptIn) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [emailCode, setEmailCode] = useState('');
    const [emailCodeTouched, setEmailCodeTouched] = useState(false);

    const isEmailCodeValid = !emailCodeTouched || emailCode.trim().length > 0;

    const clearError = () => {
        setError(false);
    };

    const verify = async () => {
        clearError();

        if(emailCode.trim().length <= 0) {
            setEmailCodeTouched(true);
            return;
        }

        setLoading(true);
        const res = await loginDoubleOptIn(emailCode.trim());
        setLoading(false);

        if(!res.isValid) {
            setError(true);
            return;
        }

        navigate('/planner/today');
    };

    const onEmailCodeChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setEmailCode(evt.target.value);
        setEmailCodeTouched(true);
    };

    const handleKeyUp = async (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await verify();
        }

        if(evt.code === 'Escape') {
            (evt.target as HTMLInputElement).value = '';
        }
    };

    const back = () => {
        setLoginMode(LoginMode.INIT);
    };

    return (
        <>
            {
                !loading &&
                <>
                    {
                        !error &&
                        <>
                            <div className="mb-4 flex flex-col">
                                <label htmlFor="username" className="block mb-2">Email Code</label>
                                <input
                                    type="text"
                                    id="email-code"
                                    autoComplete="off"
                                    value={ emailCode }
                                    onChange={ onEmailCodeChange }
                                    onKeyUp={ handleKeyUp }
                                    onClick={ clearError }
                                    className={ `border rounded px-4 py-2 block ${ isEmailCodeValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                                />

                                {
                                    !isEmailCodeValid && <div className="text-red-700 text-xs mt-1">Code is required.</div>
                                }
                            </div>

                            <button
                                className="bg-slate-500 text-slate-100 rounded px-10 py-3"
                                type="button"
                                onClick={ verify }>Verify</button>
                        </>
                    }

                    {
                        error &&
                        <>
                            <div className="bg-red-100 rounded py-3 px-6 my-4">
                                <p>Code is incorrect.</p>
                            </div>

                            <button
                                className="bg-slate-500 text-slate-100 rounded px-10 py-3"
                                type="button"
                                onClick={ back }>Back</button>
                        </>
                    }
                </>
            }

            {
                loading && <PreloaderIcon size={ 20 } color={ '#717985' } />
            }
        </>
    )
};

export default DoubleOptIn;