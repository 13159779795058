import { useCallback, useEffect, useState } from 'react';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import TextBox from '../../../../common/ui/forms/text-box';
import { ICombinedSection, IProject } from '../../planner-interfaces';
import { SectionMode } from './section';
import { updateSectionTitle } from '../../data/planner-data-service';
import { closeSection, openSection } from '../../domain/planner-storage';
import DeleteSectionButton from './actions/section-delete-btn';
import SectionEditButton from './actions/section-edit-btn';
import { showToast } from '../../../../common/domain/toast-provider';
import { ADD_NEW_SECTION_DESCRIPTION_EVENT } from './description/section-description';
import DragSectionBtn from './drag/drag-section-btn';
import Actions from '../../../../common/ui/actions';
import { combineProjectData, isSpecialProjectType } from '../../domain/planner-provider';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { usePlannerStore } from '../../data/planner-store';

interface ISectionTitleProps {
    combinedSection: ICombinedSection;
    project: IProject;
}

const SectionTitle = (props: ISectionTitleProps) => {

    const { combinedSection, project } = props;
    const { section } = combinedSection;

    const listType = usePlannerStore(state => state.listType);
    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<SectionMode>(SectionMode.DEFAULT);

    const isSpecial = isSpecialProjectType(listType);

    const startEdit = () => {
        setMode(SectionMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(SectionMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateSectionTitle(section.section_id, updatedText.trim());
        setUpdateTitleLoading(false);

        if(!response?.isValid) {
            showToast('Update section title error.');
            return;
        }

        cancelEdit();

        setCombinedProject(combineProjectData(response));
    };

    const toggleSection = useCallback(() => {
        const isOpened = combinedSection.isOpened;

        if(isOpened) {
            closeSection(section.section_id);
        }
        else{
            openSection(section.section_id);
        }

        const projectIndex = combinedProject.projects?.findIndex(pr => pr.project_id === project.project_id);
        if(projectIndex === -1) return;

        const sectionIndex = project.sections.findIndex(cs => cs.section.section_id === section.section_id);
        if(sectionIndex === -1) return;

        const sections = [...project.sections];
        sections[sectionIndex].isOpened = !isOpened;

        const copy = {...combinedProject};
        copy.projects[projectIndex].sections = sections;

        setCombinedProject(copy);
    }, [combinedProject, combinedSection.isOpened, project.project_id, project.sections, section.section_id, setCombinedProject]);

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const section_id = Number(evt?.detail) || 0;
            if(section_id !== section.section_id || combinedSection.isOpened) return;
            toggleSection();
        };

        document.addEventListener(ADD_NEW_SECTION_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_SECTION_DESCRIPTION_EVENT, handler);
        };
    }, [combinedSection.isOpened, section.section_id, toggleSection]);

    return (
        <h2 className={ `section__title pl-4 py-2 flex items-center mb-2 ${ isSpecial ? 'font-bold text-base border-b' : 'text-xl bg-slate-100 rounded' }` }>
            {
                mode === SectionMode.DEFAULT &&
                <>
                    <button
                        type="button"
                        onClick={ toggleSection }
                        className="w-[24px] h-[24px] hover:bg-white transition-colors rounded mr-4">
                        { combinedSection.isOpened && <ArrowDownIcon /> }
                        { !combinedSection.isOpened && <ArrowRightIcon /> }
                    </button>

                    <button
                        type="button"
                        onClick={ startEdit }
                        dangerouslySetInnerHTML={{__html: markdown2Html(section.section_title || 'Tasks without section') }}
                        className="mr-4 text-left"
                    />

                    <div className="text-xs mt-[3px] ml-auto md:ml-2 md:mr-auto">{ combinedSection.todos.length }</div>

                    <Actions classes="section__actions" ml="ml-0">
                        <SectionEditButton
                            section_id={ section.section_id }
                        />

                        <DeleteSectionButton
                            section_id={ section.section_id }
                            section_title={ section.section_title }
                            project_id={ project.project_id }
                        />

                        <DragSectionBtn section={ section } />
                    </Actions>
                </>
            }

            {
                mode === SectionMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ section.section_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h2>
    )
};

export default SectionTitle;