import { CalendarIcon } from '../../../../common/ui/icons';
import SectionTodos from '../section/section-todos';
import { INDEX_PROJECT_ID } from '../../domain/planner-provider';
import ProjectView from '../projects/project-view';
import ActiveTodos from '../active-todos/active-todos';
import TodayNote from './today-note';
import { useEffect } from 'react';
import HideProjectAndSectionTitlesCB from '../projects/actions/project-hide-titles-cb';
import Actions from '../../../../common/ui/actions';
import CloseAllProjectsBtn from './actions/close-all-projects-btn';
import { usePlannerStore } from '../../data/planner-store';
// import RTFEditor from '../../../../rtf-editor';

const TodayView = () => {

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const totalTodosCount = combinedProject?.projects?.reduce((acc, curr) => curr.todosCount + acc, 0);

    useEffect(() => {
        document.title = `Today (${ totalTodosCount ?? 0 }) | Productivity`;
    }, [totalTodosCount]);

    return (
        <>
            <div className="xl:w-[800px] max-w-full mx-auto">
                <div className="mb-4 w-full flex flex-col">

                    <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center">
                        <CalendarIcon classes="mr-4" size={ 35 } color={ '#2E766E' } />
                        Today

                        <span className="text-sm font-normal mx-4">{ totalTodosCount }</span>

                        <Actions mr="mr-0" gridSize={ 1 }>
                            <CloseAllProjectsBtn />
                        </Actions>
                    </h1>

                    {
                        /**
                         * Optional project description here.
                         */
                    }
                </div>

                {/*<RTFEditor />*/}

                <TodayNote note={ combinedProject?.todayNote || '' } />

                <ActiveTodos activeTodos={ combinedProject?.activeTodos || [] } />

                <div className="flex justify-end">
                    <HideProjectAndSectionTitlesCB />
                </div>

                <div className="mb-10">
                    <SectionTodos
                        combinedSection={ {
                            section: {
                                section_id: 0,
                                section_title: '',
                                section_order: 0,
                                section_project_id: INDEX_PROJECT_ID,
                            },
                            todos: [],
                            isOpened: true,
                        } }
                        project_id={ INDEX_PROJECT_ID }
                        titleVisible={ false }
                    />
                </div>

                {
                    combinedProject?.projects.map(project => {

                        return (
                            <ProjectView
                                key={`project-${ project.project_id }`}
                                project={ project}
                            />
                        )
                    })
                }

            </div>
        </>
    )
};

export default TodayView;