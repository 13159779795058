import { useEffect, useState } from 'react';
import { ILibraryFolder } from '../../interfaces';
import FolderTitle from './folder-title';
import FolderDescription, { ADD_NEW_FOLDER_DESCRIPTION_EVENT } from './folder-description';
import { FolderIcon } from '../../../../common/ui/icons';
import { useLibraryStore } from '../../data/library-store';

const FolderView = () => {

    const libraryData = useLibraryStore(store => store.libraryData);
    const selectedLibraryItem = useLibraryStore(store => store.selectedLibraryItem);

    const [folder, setFolder] = useState<ILibraryFolder|null>(null);

    useEffect(() => {
        const folder = libraryData?.folders.find(folder => folder.folder_id === selectedLibraryItem.id);
        if(!folder) return;

        setFolder(folder);
    }, [libraryData?.folders, selectedLibraryItem?.id]);

    useEffect(() => {
        document.title = `${ folder?.folder_title ?? 'Library' } | Productivity`;
    }, [folder?.folder_title]);

    const startEditDescription = () => {
        if(!folder) return;

        document.dispatchEvent(new CustomEvent(ADD_NEW_FOLDER_DESCRIPTION_EVENT, {
            detail: folder.folder_id,
        }));
    };

    return (
        <>
            {
                folder &&
                <div className="w-full xl:w-[800px] max-w-full mx-auto flex-1 flex flex-col">
                    <div className="mb-6 w-full flex flex-col flex-1">
                        <FolderTitle
                            folder={ folder }
                            startEditDesc={ startEditDescription }
                        />

                        <FolderDescription folder={ folder } />

                        <div className="flex justify-center items-center py-10 flex-1">
                            <FolderIcon
                                size={ 200 }
                                strokeWidth={ 1 }
                                color={ '#d9d9d9' }
                            />
                        </div>

                    </div>
                </div>
            }
        </>
    )
};

export default FolderView;