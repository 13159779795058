import { IProject } from '../../planner-interfaces';
import DeleteProjectButton from './actions/project-delete-btn';
import { useState, MouseEvent } from 'react';
import { getAllProjects, updateProjectTitle } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import TextBox from '../../../../common/ui/forms/text-box';
import ProjectEditButton from './actions/project-edit-btn';
import Actions from '../../../../common/ui/actions';
import { combineProjectData, isSpecialProjectType } from '../../domain/planner-provider';
import { ArrowDownIcon, ArrowRightIcon, ExternalLink } from '../../../../common/ui/icons';
import { closeProject, openProject } from '../../domain/planner-storage';
import CloseProjectSectionsBtn from './actions/project-close-sections-btn';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { usePlannerStore } from '../../data/planner-store';

export enum ProjectTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IProjectTitleProps {
    project: IProject;
}

const ProjectTitle = ({ project }: IProjectTitleProps) => {

    const listType = usePlannerStore(state => state.listType);

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<ProjectTitleMode>(ProjectTitleMode.DEFAULT);

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const setMenuProjects = usePlannerStore(state => state.setMenuProjects);
    const setStats = usePlannerStore(state => state.setStats);

    const isSpecial = isSpecialProjectType(listType);

    const startEdit = () => {
        setMode(ProjectTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(ProjectTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateProjectTitle(project.project_id, updatedText.trim());
        setUpdateTitleLoading(false);

        if(!response?.isValid) {
            showToast('Update project title error.');
            return;
        }

        cancelEdit();

        setCombinedProject(combineProjectData(response));

        const menuResponse = await getAllProjects();
        if(!menuResponse?.data) return;

        setMenuProjects(menuResponse.data);
        setStats(menuResponse.stats);
    };

    const toggle = () => {
        const isOpened = project.isOpened;

        if(isOpened) {
            closeProject(project.project_id);
        }
        else{
            openProject(project.project_id);
        }

        const projectIndex = combinedProject?.projects.findIndex(pr => pr.project_id === project.project_id);
        if(projectIndex === undefined || projectIndex === -1) return;

        const copy = JSON.parse(JSON.stringify(combinedProject));
        copy.projects[projectIndex].isOpened = !isOpened;
        setCombinedProject(copy);
    };

    const stopPropagation = (evt: MouseEvent<HTMLAnchorElement>) => {
        evt.stopPropagation();
    }

    return (
        <h1 className={ `project__title flex items-center ${ isSpecial ? 'bg-stone-100 text-lg font-semibold p-2 rounded' : 'text-xl xl:text-3xl border-b mb-2 pb-2' }` }>
            {
                mode === ProjectTitleMode.DEFAULT &&
                <>
                    {
                        isSpecial &&
                        <button
                            type="button"
                            onClick={ toggle }
                            className="w-[24px] h-[24px] hover:bg-white transition-colors rounded mr-4">
                            { project.isOpened && <ArrowDownIcon /> }
                            { !project.isOpened && <ArrowRightIcon /> }
                        </button>
                    }

                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(project.project_title) }} />

                        <span className="text-sm font-normal ml-4">{ project.todosCount }</span>

                        {
                            isSpecial &&
                            <a
                                onClick={ stopPropagation }
                                href={ `/planner/${ project.project_id }` }
                                className="mx-2">
                                <ExternalLink
                                    color={ '#1E293B' }
                                    size={ 16 }
                                />
                            </a>
                        }
                    </button>

                    {/*<ProjectViewModeDropdown />*/}

                    <Actions classes="project__actions">
                        <ProjectEditButton
                            project_id={ project.project_id }
                        />

                        <DeleteProjectButton
                            project_id={ project.project_id }
                            project_title={ project.project_title }
                        />

                        <CloseProjectSectionsBtn project={ project } />
                    </Actions>
                </>
            }

            {
                mode === ProjectTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ project.project_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h1>
    )
};

export default ProjectTitle;