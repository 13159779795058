import { ILibrarySerie } from '../../interfaces';
import Actions, { sendCloseActionsEvent } from '../../../../common/ui/actions';
import DeleteSerieButton from './actions/serie-delete-btn';
import EditSerieBtn from './actions/serie-edit-btn';
import { SERIE_EDIT_EVENT } from './serie-edit';
import { ArrowDownIcon, ArrowRightIcon } from '../../../../common/ui/icons';
import { closeSerie, openSerie } from '../../domain/library-storage';
import { useLibraryStore } from '../../data/library-store';

interface ISerieProps {
    serie: ILibrarySerie;
}

const SerieTitle = ({ serie }: ISerieProps) => {

    const libraryData = useLibraryStore(state => state.libraryData);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const toggleSerie = () => {
        if(serie.isClosed) {
            openSerie(serie.serie_id);
        }
        else{
            closeSerie(serie.serie_id);
        }

        serie.isClosed = !serie.isClosed;
        setLibraryData(JSON.parse(JSON.stringify(libraryData)))
    };

    const handleClick = () => {
        document.dispatchEvent(new CustomEvent(SERIE_EDIT_EVENT, {
            detail: serie.serie_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <div className="serie__title flex items-center bg-stone-100 text-md font-semibold py-2 pl-4 rounded">
            <div className="flex-1 flex items-center">

                <button
                    type="button"
                    onClick={ toggleSerie }
                    className="w-[24px] h-[24px] hover:bg-white transition-colors rounded mr-4">
                    { !serie.isClosed && <ArrowDownIcon /> }
                    { serie.isClosed && <ArrowRightIcon /> }
                </button>

                <button
                    type="button"
                    onClick={ handleClick }
                    className="mr-4 text-left leading-none">
                    { serie.serie_title }
                </button>
            </div>

            <Actions classes="serie__actions" gridSize={ 2 }>

                <EditSerieBtn
                    serie_id={ serie.serie_id }
                />

                <DeleteSerieButton
                    serie={ serie }
                />
            </Actions>
        </div>
    )
};

export default SerieTitle;