import { ChangeEvent, useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { uploadBookFile } from '../../../data/library-data-service';
import { AttachmentIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType } from '../../../interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { useLibraryStore } from '../../../data/library-store';

interface IAddBookFileBtnProps {
    book_id: number;
}

const AddBookFileBtn = ({ book_id }: IAddBookFileBtnProps) => {

    const [loading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    const uploadHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload book file error.');
            return;
        }

        setLoading(true);

        for(const file of evt.target.files) {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('book_id', book_id.toString());
            formData.append('selectedItemType', (selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT).toString());
            formData.append('selectedItemId', (selectedLibraryItem?.id || 0).toString());
            const response = await uploadBookFile(formData as FormData);

            if(!response) {
                showToast('Upload book file error.');
                setLoading(false);
                return;
            }

            setLibraryData(formatLibraryData(response || []));
        }

        setLoading(false);
        showToast('Upload book file OK.', false);

        sendCloseActionsEvent();
    };

    return (
        <label className="book-file-upload-btn flex bg-sky-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">
            {
                loading &&
                <PreloaderIcon size={ 20 } color={ '#204A6E' } />
            }

            {
                !loading &&
                <label className="m-5 flex-1 flex items-center justify-center cursor-pointer">
                    <input
                        onChange={ uploadHandler }
                        className="hidden"
                        type="file"
                        multiple={ true }
                    />

                    <AttachmentIcon
                        size={ 20 }
                        color="#204A6E"
                    />
                </label>
            }
        </label>
    )
};

export default AddBookFileBtn;