import { ChangeEvent } from 'react';
import { useOverviewStore } from '../../data/overview-store';

export enum CompletedFilterEnum {
    NotCompleted = 0,
    Completed = 1,
    Both = 2,
}

const CompletedFilter = () => {

    const completedFilter = useOverviewStore(state => state.completedFilter);
    const setCompletedFilter = useOverviewStore(state => state.setCompletedFilter);

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _completedFilter: CompletedFilterEnum = Number((evt.target as HTMLSelectElement).value) ?? CompletedFilterEnum.NotCompleted;
        setCompletedFilter(_completedFilter);
    };

    return (
        <label className="text-xs flex items-center mr-10">
            <select
                value={ completedFilter }
                onChange={ onChange }
                className="outline-none">
                <option value={ CompletedFilterEnum.NotCompleted }>Not Completed</option>
                <option value={ CompletedFilterEnum.Completed }>Completed</option>
                <option value={ CompletedFilterEnum.Both }>Both</option>
            </select>
        </label>
    )
};

export default CompletedFilter;