import AddDeck from './add-deck';
import FlashcardMenuItemDeck from './flashcard-menu-item-deck';
import { CardsIcon, SearchIcon } from '../../../../common/ui/icons';
import { NavLink } from 'react-router-dom';
import { FlashCardsListView } from '../../flashcards-interfaces';
import AddFolder from './add-folder';
import FlashcardMenuItemFolder from './flashcard-menu-item-folder';
import { useFlashCardsStore } from '../../data/flashcards-store';

const FlashCardsMenu = () => {

    const flashCardsTree = useFlashCardsStore(state => state.flashCardsTree);
    const setFlashCardsListType = useFlashCardsStore(state => state.setFlashCardsListType);
    const flashCardsListType = useFlashCardsStore(state => state.flashCardsListType);

    const setRoot = () => {
        setFlashCardsListType(FlashCardsListView.ROOT);
    };

    const setSearch = () => {
        setFlashCardsListType(FlashCardsListView.SearchView);
    };

    return (
        <div className="side-menu flex flex-col xl:w-[350px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3 text-sm">

                <div className="pb-3 pt-3 flex items-center text-slate-400">

                    <NavLink
                        className="flex"
                        onClick={ setRoot }
                        to="/flashcards"
                        end={ true }>

                        <span className="mr-3">Flash Cards</span>

                        <CardsIcon
                            size={ 20 }
                            color={ '#28baff' }
                        />
                    </NavLink>
                </div>

                <div>
                    <NavLink
                        className="flex items-center py-2"
                        onClick={ setSearch }
                        to="/flashcards/search"
                        end={ true }>

                        <SearchIcon
                            size={ 22 }
                            color={ '#f8f8f8' }
                            classes="ml-1 mr-2"
                        />

                        <span className="mr-3">Search</span>
                    </NavLink>
                </div>

                {
                    flashCardsTree?.rootFolders?.map((folder) => {
                        return (
                            <FlashcardMenuItemFolder
                                key={ folder.folder_id }
                                folder={ folder }
                            />
                        )
                    })
                }

                {
                    flashCardsTree?.rootDecks.map((deck) => {
                        return (
                            <FlashcardMenuItemDeck
                                key={ deck.deck_id }
                                deck={ deck }
                            />
                        )
                    })
                }

                {
                    (flashCardsListType === FlashCardsListView.ROOT || flashCardsListType === FlashCardsListView.FolderView) &&
                    <>
                        <AddFolder />
                        <AddDeck />
                    </>
                }

            </div>
        </div>
    )
};

export default FlashCardsMenu;