import { format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IBlock } from '../../../planner-interfaces';
import { DeleteIcon, PreloaderIcon, SaveIcon } from '../../../../../common/ui/icons';
import { deleteBlock, insertBlock, updateBlock } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

interface IEditItem {
    todo_id: number;
    block: IBlock|null;
    cancelNewBlock: () => void;
    close: () => void;
}

const EditItem = ({ todo_id, block, cancelNewBlock, close }: IEditItem) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [startDate, setStartDate] = useState<number|null>(block?.start_date ?? null);
    const [endDate, setEndDate] = useState<number|null>(block?.end_date ?? null);

    const [startDateTouched, setStartDateTouched] = useState(false);
    const [endDateTouched, setEndDateTouched] = useState(false);

    const isNewBlock = !block || block.block_id <= 0;
    const isStartDateValid = !startDateTouched || (!!startDate && startDate > 0);
    const isEndDateValid = !endDateTouched || (!!endDate && endDate > 0);

    const [isLoading, setLoading] = useState(false);

    const insertNewBlockHandler = useCallback(async () => {

        if(startDate === null || startDate <= 0) {
            setStartDateTouched(true);
            return;
        }

        if(endDate === null || endDate <= 0) {
            setEndDateTouched(true);
            return;
        }

        if(endDate < startDate) {
            showToast('End date should be > than start date.');
            return;
        }

        setLoading(true);

        const response = await insertBlock(
            todo_id,
            startDate,
            endDate
        );

        setLoading(false);

        if(!response) {
            showToast('Insert block error.');
            return;
        }

        cancelNewBlock();
        setCombinedProject(combineProjectData(response));
    },
        [
            cancelNewBlock,
            endDate,
            setCombinedProject,
            startDate,
            todo_id,
        ]
    );

    const updateBlockHandler = useCallback(async () => {

        if(startDate === null || startDate <= 0) {
            setStartDateTouched(true);
            return;
        }

        if(endDate === null || endDate <= 0) {
            setEndDateTouched(true);
            return;
        }

        if(endDate < startDate) {
            showToast('End date should be > than start date.');
            return;
        }

        setLoading(true);

        const response = await updateBlock(
            block.block_id,
            startDate,
            endDate
        );

        setLoading(false);

        if(!response) {
            showToast('Update block error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    }, [
        block?.block_id,
        endDate,
        setCombinedProject,
        startDate,
    ]);

    const saveHandler = useCallback(async () => {

        if(isNewBlock) {
            await insertNewBlockHandler();
        }
        else{
            await updateBlockHandler();
        }

        close();
    }, [
        insertNewBlockHandler,
        isNewBlock,
        updateBlockHandler,
        close,
    ]);

    const deleteHandler = async () => {
        if(isNewBlock) {
            cancelNewBlock();
            return;
        }

        setLoading(true);

        const response = await deleteBlock(block.block_id);

        setLoading(false);

        if(!response) {
            showToast('Delete block error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    const onStartDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setStartDateTouched(true);

        if(!evt.target.value) {
            setStartDate(null);
            return;
        }

        const _date = new Date(evt.target.value); // 2023-12-07T13:21
        setStartDate(_date.getTime());
    };

    const onEndDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setEndDateTouched(true);

        if(!evt.target.value) {
            setEndDate(null);
            return;
        }

        const _date = new Date(evt.target.value); // 2023-12-07T13:21
        setEndDate(_date.getTime());
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await saveHandler();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [saveHandler]);

    return (
        <div className="flex mb-2 flex-col md:flex-row mb-4 md:mb-0">
            <div className="flex flex-col mb-2 md:mb-0">
                <label className="text-xs font-bold mb-1 text-slate-400">Start Date</label>

                <input
                    type="date"
                    className={`border rounded text-slate-800 px-4 py-2 text-sm mr-2 ${ isStartDateValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }`}
                    onChange={ onStartDateChange }
                    value={ startDate ? format(startDate, `yyyy-MM-dd`) : '' }
                />

                {
                    !isStartDateValid && <div className="text-red-700 text-xs mt-1">Start date is required.</div>
                }
            </div>

            <div className="flex flex-col mb-2 md:mb-0">
                <label className="text-xs font-bold mb-1 text-slate-400">End Date</label>

                <input
                    type="date"
                    className={ `border rounded text-slate-800 px-4 py-2 text-sm mr-2 ${ isEndDateValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    onChange={ onEndDateChange }
                    value={ endDate ? format(endDate, `yyyy-MM-dd`) : '' }
                />

                {
                    !isEndDateValid && <div className="text-red-700 text-xs mt-1">End date is required.</div>
                }
            </div>

            <div className="flex items-start">
                {
                    isLoading &&
                    <div className="flex flex-col items-center justify-center">
                        <label className="text-xs mb-1 hidden md:block">&nbsp;</label>
                        <PreloaderIcon size={ 20 } />
                    </div>
                }

                {
                    !isLoading &&
                    <>
                        <div className="flex flex-col items-center justify-center">
                            <label className="text-xs mb-1 hidden md:block">&nbsp;</label>
                            <button
                                onClick={ deleteHandler }
                                className="bg-pink-100 text-slate-100 rounded px-3 py-2 mr-2"
                                type="button">
                                <DeleteIcon size={ 20 } />
                            </button>
                        </div>

                        <div className="flex flex-col items-center justify-center">
                            <label className="text-xs mb-1 hidden md:block">&nbsp;</label>
                            <button
                                onClick={ saveHandler }
                                className="text-slate-100 bg-slate-500 rounded px-3 py-2 mr-2"
                                type="button">
                                <SaveIcon size={ 20 } color={ '#fff' } />
                            </button>
                        </div>
                    </>
                }
            </div>

        </div>
    )
};

export default EditItem;