import { EditIcon } from '../../../../../common/ui/icons';
import { ADD_NEW_TODO_DESCRIPTION_EVENT } from '../description/todo-description';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface ITodoEditButtonProps {
    todo: ITodo;
}

const TodoEditButton = ({ todo }: ITodoEditButtonProps) => {

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_DESCRIPTION_EVENT, {
            detail: todo.todo_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Task"
            className="todo__add-desc flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default TodoEditButton;