import TableDivider from '../table-divider';
import TableHeaderBtn from './table-header-btn';

const TableHeader = () => {

    return (
        <>
            <>
                <TableHeaderBtn
                    title="Id"
                    fieldName="todo_id"
                    isCentered={ false }
                />

                <TableHeaderBtn
                    title="Completeness"
                    fieldName="completeness_percent"
                    isCentered={ true }
                />

                <TableHeaderBtn
                    title="Title"
                    fieldName="todo_title"
                    isCentered={ false }
                />

                <TableHeaderBtn
                    title="Date"
                    fieldName="todo_date"
                    isCentered={ true }
                />

                <TableHeaderBtn
                    title="Nested #"
                    fieldName="nested_count"
                    isCentered={ true }
                />

                <TableHeaderBtn
                    title="Completed #"
                    fieldName="nested_completed_count"
                    isCentered={ true }
                />

                <TableHeaderBtn
                    title="Type"
                    fieldName="todo_type"
                    isCentered={ true }
                />

                <TableHeaderBtn
                    title="Tags"
                    fieldName="todo_tags"
                    isCentered={ true }
                />
            </>

            <TableDivider />
        </>
    )
};

export default TableHeader;