import { addMonths, format } from 'date-fns';
import { ArrowLeftIcon, ArrowRightIcon, SunIcon } from '../../../common/ui/icons';
import { useBlocksOverviewStore } from '../data/blocks-overview-store';

const Title = () => {

    const currentMonth = useBlocksOverviewStore(store => store.currentMonth);
    const setCurrentMonth = useBlocksOverviewStore(store => store.setCurrentMonth);

    const currentYear = useBlocksOverviewStore(store => store.currentYear);
    const setCurrentYear = useBlocksOverviewStore(store => store.setCurrentYear);

    const date = new Date(currentYear, currentMonth, 1);

    const monthTitle = format(date, 'MMMM yyyy');

    const goToPrevMonth = () => {
        const prev = addMonths(date, -1);
        setCurrentMonth(prev.getMonth());
        setCurrentYear(prev.getFullYear());
    };

    const goToNextMonth = () => {
        const next = addMonths(date, 1);
        setCurrentMonth(next.getMonth());
        setCurrentYear(next.getFullYear());
    };

    const jumpToday = () => {
        const next = new Date();
        setCurrentMonth(next.getMonth());
        setCurrentYear(next.getFullYear());
    };

    return (
        <div className="font-bold mb-2 text-center text-slate-600 flex items-center justify-center">

            <button
                className="mr-4"
                type="button"
                onClick={ goToPrevMonth }>
                <ArrowLeftIcon />
            </button>

            <div>{ monthTitle }</div>

            <button
                className="mx-4"
                type="button"
                onClick={ goToNextMonth }>
                <ArrowRightIcon />
            </button>

            <button
                onClick={ jumpToday }
                className="flex items-center ml-auto"
                type="button">
                <SunIcon
                    color={ '#d99b00' }
                    classes="mr-1"
                />
                Today
            </button>
        </div>
    )
};

export default Title;