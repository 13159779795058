import { useEffect, useState } from 'react';
import TextBox from '../../../../common/ui/forms/text-box';
import Actions from '../../../../common/ui/actions';
import { getTextFromMarkdown, markdown2Html } from '../../../../common/markdown/markdown-provider';
import { showToast } from '../../../../common/domain/toast-provider';
import { FlashCardsListView, IFlashCardFolder } from '../../flashcards-interfaces';
import { updateFolderTitle } from '../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import FlashCardsDeleteFolderButton from './actions/folder-delete-btn';
import EditFlashCardsFolderDescriptionBtn from './actions/edit-folder-description-btn';
import { useFlashCardsStore } from '../../data/flashcards-store';

export enum FlashCardsFolderTitleMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IFlashCardsFolderTitleProps {
    flashCardsFolder: IFlashCardFolder;
}

const FlashCardsFolderTitle = ({ flashCardsFolder }: IFlashCardsFolderTitleProps) => {

    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);
    const [mode, setMode] = useState<FlashCardsFolderTitleMode>(FlashCardsFolderTitleMode.DEFAULT);

    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    useEffect(() => {
        document.title = getTextFromMarkdown(flashCardsFolder.folder_title);
    }, [flashCardsFolder.folder_title]);

    const startEdit = () => {
        setMode(FlashCardsFolderTitleMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(FlashCardsFolderTitleMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateFolderTitle(
            FlashCardsListView.FolderView,
            flashCardsFolder.folder_id,
            updatedText.trim(),
            0
        );

        setUpdateTitleLoading(false);

        if(!response) {
            showToast('Update folder title error.');
            return;
        }

        cancelEdit();

        setFlashCardsTree(formatFlashCardsTree(response, undefined, flashCardsFolder.folder_id));
    };

    return (
        <h1 className={ `flash-cards-folder__title flex items-center text-xl xl:text-3xl ${ mode === FlashCardsFolderTitleMode.DEFAULT ? ' border-b mb-2 pb-2 ' : '' }` }>
            {
                mode === FlashCardsFolderTitleMode.DEFAULT &&
                <>
                    <button
                        type="button"
                        onClick={ startEdit }
                        className="mr-4 flex items-center text-left flex-1">

                        <span dangerouslySetInnerHTML={{__html: markdown2Html(flashCardsFolder.folder_title) }} />

                        {/*<span className="text-sm font-normal ml-4">{ count }</span>*/}
                    </button>

                    <Actions classes="flash-cards-folder__actions" gridSize={ 2 }>

                        <FlashCardsDeleteFolderButton
                            folder_id={ flashCardsFolder.folder_id }
                            folder_title={ flashCardsFolder.folder_title }
                        />

                        <EditFlashCardsFolderDescriptionBtn
                            folder_id={ flashCardsFolder.folder_id }
                        />
                    </Actions>
                </>
            }

            {
                mode === FlashCardsFolderTitleMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ flashCardsFolder.folder_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </h1>
    )
};

export default FlashCardsFolderTitle;