import { ArrowRightIcon } from '../../../../../common/ui/icons';
import { NavLink } from 'react-router-dom';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IStartStudyBtn {
    classes?: string;
    deck_id: number;
}

const StartStudyBtn = (props: IStartStudyBtn) => {

    const flashCards = useFlashCardsStore(store => store.flashCards);

    return (
        <>
            {
                flashCards?.length > 0 &&
                <NavLink
                    className={ `bg-[#d6f2ff] text-sky-800 pr-4 pl-6 py-2 rounded-lg text-sm flex items-center whitespace-nowrap ${ props.classes ?? '' }` }
                    to={ `/flashcards/study/${ props.deck_id }` }>
                    <span className="mr-2">Study Now</span>
                    <ArrowRightIcon
                        color={ '#19719a' }
                        size={ 20 }
                        classes="mt-1"
                    />
                </NavLink>
            }
        </>
    )
};

export default StartStudyBtn;