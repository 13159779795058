import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { CopyIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { IFlashCard } from '../../../flashcards-interfaces';
import { duplicateCard } from '../../../data/flashcards-data-service';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDuplicateCardBtn {
    card: IFlashCard;
}

const DuplicateCardBtn = ({ card }: IDuplicateCardBtn) => {

    const [isLoading, setLoading] = useState(false);

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const duplicateItemHandler = async () => {

        setLoading(true);

        const response = await duplicateCard(card.card_id, card.deck_id, flashCardsListType);
        setLoading(false);

        if(!response) {
            showToast('Duplicate card error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, card.deck_id));

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Duplicate Card"
                    className="card__duplicate flex bg-green-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ duplicateItemHandler }
                    type="button">
                    <CopyIcon
                        size={ 20 }
                        color={ '#128573' }
                        strokeWidth={ 1 }
                    />
                </button>
            }
        </>
    )
};

export default DuplicateCardBtn;