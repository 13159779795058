import { TodoType } from '../../../../planner/planner-interfaces';
import { ChangeEvent } from 'react';
import { useOverviewStore } from '../../../data/overview-store';

interface ITodoTypeFilterCheckbox {
    todo_type: TodoType;
    title: string;
}

const TodoTypeFilterCheckbox = ({ todo_type, title }: ITodoTypeFilterCheckbox) => {

    const todoTypeFilter = useOverviewStore(state => state.todoTypeFilter);
    const setTodoTypeFilter = useOverviewStore(state => state.setTodoTypeFilter);

    const onChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        const $target = evt.target as HTMLInputElement;
        const todoType = Number($target.value) ?? TodoType.Default;

        const copy = new Set(todoTypeFilter);

        if($target.checked) {
            copy.add(todoType);
        }
        else{
            copy.delete(todoType);
        }

        setTodoTypeFilter(copy);
    };

    return (
        <label className="flex items-center mr-2">
            <div className="mb-1">&nbsp;</div>
            <input
                value={ todo_type }
                className="mr-2"
                type="checkbox"
                checked={ todoTypeFilter.has(todo_type) }
                onChange={ onChange }
            />
            { title }
        </label>
    )
};

export default TodoTypeFilterCheckbox;