import { ITodo } from '../../../planner/planner-interfaces';
import { IMetaData } from '../../diagram-interfaces';
import { LETTER_HEIGHT, LETTER_VERTICAL_PADDING } from '../../domain/diagram-provider';
import NestedTodosCount from './nested-todos-count';
import TodoTypeText from './event-type-text';
import { getTodoTypeColor } from '../../../planner/domain/todo-type-provider';

interface IDiagramNode {
    todo: ITodo;
    meta: IMetaData;
}

export const NODE_PADDING = 15;

export const DiagramNode = ({ todo, meta }: IDiagramNode) => {

    const texts = meta.texts;

    return (
        <g transform={ `translate(${ meta.x } ${ meta.y })` }>
            {
                meta &&
                <rect
                    fill={ meta.isRoot ? '#eddcf7' : '#efefef' }
                    stroke={ meta.isRoot ? '#b1a8b7' : getTodoTypeColor(todo.todo_type) }
                    rx="15"
                    x={ 1 }
                    y={ 1 }
                    width={ meta.w - 1 }
                    height={ meta.h - 1 }
                />
            }

            <text
                fill={ meta.isRoot ? '#442057' : (todo.is_completed ? '#6b6b6b' : '#000') }
                fontWeight={ meta.isRoot ? 'bold' : undefined }
                dominantBaseline="hanging"
                textAnchor="middle"
                textDecoration={ todo.is_completed ? 'line-through' : 'auto' }
                x={ 0 }
                y={ 0 }>
                {
                    texts.map((row, i) => {
                        const isDate = (i === texts.length - 1) && todo.todo_date;
                        const x = meta.w/2;
                        let y = (LETTER_HEIGHT + LETTER_VERTICAL_PADDING) * i + NODE_PADDING;

                        if(isDate) y += 5;

                        return (
                            <tspan
                                fontSize={ isDate ? 13 : 16 }
                                fill={ isDate ? meta.dateColor : undefined }
                                x={ x }
                                y={ y }
                                key={ i }>{ row }</tspan>
                        )
                    })
                }
            </text>

            {
                todo.nested?.length > 0 &&
                <NestedTodosCount
                    count={ todo.nested.length }
                    x={ meta.w }
                    y={ NODE_PADDING/2 + meta.h/2 }
                    todo_id={ todo.todo_id }
                />
            }

            <TodoTypeText
                todo_type={ todo.todo_type }
                x={ meta.w + 30 }
                y={ NODE_PADDING/2 + meta.h/2 }
            />
        </g>
    )
};

export default DiagramNode;