import { DescartesSquareType, IDescartesSquare, IDescartesSquareResponse } from '../descartes-square-interfaces';
import { ITodoVariant } from '../../planner/planner-interfaces';

export const formatDescartesSquareData = (response: IDescartesSquareResponse) : IDescartesSquare|null => {
    const map: Map<DescartesSquareType, ITodoVariant[]> = new Map();
    const variants = response.variants || [];

    for(const variant of variants) {
        const _variants = map.get(variant.descartes_square_type) || [];
        _variants.push(variant);
        map.set(variant.descartes_square_type, _variants);
    }

    return {
        variants,
        map,
        parentTodo: response.parentTodo,
    }
};