import { MagicIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { predictNextEvent } from '../../../domain/prediction-provider';
import { format } from 'date-fns';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';

interface IPredictNextEventBtn {
    todo: ITodo;
}

const PredictNextEventBtn = ({ todo }: IPredictNextEventBtn) => {

    const predictNextHandler = () => {
        const nextEventDate = predictNextEvent(todo);
        if(!nextEventDate) {
            alert('Not enough data.');
            return;
        }

        alert(format(nextEventDate, 'dd MMM, yyyy'));

        sendCloseActionsEvent();
    };

    return (
        <button
            className="todo__predict-next-event-btn flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
            onClick={ predictNextHandler }
            title="Predict Next Event"
            type="button">
            <MagicIcon color={ '#586180' } />
        </button>
    )
};

export default PredictNextEventBtn;