import { PreloaderIcon, SearchIcon } from '../../../../common/ui/icons';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { ILibraryBook } from '../../interfaces';
import { searchBook } from '../../data/library-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';

const LibrarySearchView = () => {

    const [term, setTerm] = useState('');
    const [searchResults, setSearchResults] = useState<ILibraryBook[]>([]);
    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        document.title = 'Search Books | Productivity';
    }, []);

    const startSearch = async () => {
        if(term.trim() === '') {
            setSearchResults([]);
            return;
        }

        setSearchLoading(true);

        const response = await searchBook(term);
        setSearchLoading(false);

        if(!response) {
            showToast('Search error.');
            return;
        }

        setSearchResults(response);
    };

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTerm(evt.target.value);
    };

    const onKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Escape') {
            setTerm('');
        }

        if(evt.code === 'Enter') {
            startSearch();
        }
    };

    return (
        <div className="w-full xl:w-[800px] max-w-full mx-auto flex-1 flex flex-col">
            <div className="mb-6 w-full flex flex-col flex-1">

                <h1 className={ `flex items-center text-xl xl:text-3xl border-b mb-4 pb-2` }>
                    <SearchIcon classes="mr-4" size={ 35 } color={ '#2E766E' } />
                    Search Books
                </h1>

                <div className="flex flex-col my-4">

                    <div className="flex items-start">
                        <div className="flex flex-col flex-1 mr-4">
                            <input
                                className="flex-1 border rounded outline-none px-4 py-2 mb-2"
                                type="text"
                                placeholder="Search..."
                                value={ term }
                                onInput={ onChange }
                                onKeyUp={ onKeyUp }
                            />
                            <div className="text-sm text-right">{ searchResults.length } results</div>
                        </div>

                        <button
                            onClick={ startSearch }
                            className="flex bg-teal-600 rounded-xl justify-center items-center"
                            type="button">

                            {
                                !searchLoading &&
                                <SearchIcon color={ '#efefef' } size={ 40 } />
                            }

                            {
                                searchLoading &&
                                <PreloaderIcon color={ '#efefef' } size={ 40 } />
                            }
                        </button>
                    </div>
                </div>

                <div className="books-search-results">
                    {
                        searchResults.map(book => {
                            return (
                                <div
                                    className={ `border-b pb-3 mb-3` }
                                    key={ book.book_id }>

                                    <a
                                        href={ `/library/a-${ book.author_id }` }
                                        className="underline text-sky-800"
                                        dangerouslySetInnerHTML={{__html: markdown2Html(book.book_title) }}
                                    />

                                    {
                                        book.book_desc && <div
                                            className="markdown-editor whitespace-wrap leading-6 mt-2"
                                            dangerouslySetInnerHTML={{__html: (book.book_desc || '').replaceAll('```', '') }}
                                        />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default LibrarySearchView;