/**
 * https://calendar.google.com/calendar/u/0/r/eventedit?sf=true&output=xml&text=sometext&location=somelocation&details=somedetails&dates=20220418T013000Z/20220416T020000Z
 */
import { formatISO } from 'date-fns';

export const createGoogleCalendarEventLink = (
    eventName: string,
    startDate: Date,
    endDate: Date,
    description?: string,
    location?: string
) => {

    const parts: string[] = [];

    parts.push(`sf=true`);
    parts.push(`output=xml`);

    parts.push(`text=${ eventName }`);

    const isoStartDate = formatISO(startDate, { format: 'basic' });
    const isoEndDate = formatISO(endDate, { format: 'basic' });

    parts.push(`dates=${ isoStartDate }/${ isoEndDate }`);

    if(description) {
        parts.push(`details=${ description }`);
    }

    if(location) {
        parts.push(`location=${ location }`);
    }

    const qs = parts.join('&');

    return `https://calendar.google.com/calendar/u/0/r/eventedit?${ qs }`;
};