import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType, ILibraryAuthor } from '../../../interfaces';
import { deleteAuthor } from '../../../data/library-data-service';
import { useLibraryStore } from '../../../data/library-store';

interface IDeleteAuthorProps {
    author: ILibraryAuthor;
}

const DeleteAuthorButton = ({ author }: IDeleteAuthorProps) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const navigate = useNavigate();

    const deleteAuthorHandler = async () => {

        if(!confirm(`Are you sure you want to delete the author "${ `${ author.author_first_name } ${ author.author_last_name }` }"?`)) return;

        setLoading(true);
        const response = await deleteAuthor(
            author.author_id,
            ESelectedLibraryItemType.FOLDER,
            author.folder_id
        );
        setLoading(false);

        if(!response) {
            showToast('Delete author error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.FOLDER,
            id: author.folder_id,
        });

        navigate(`/library/f-${ author.folder_id }`);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Author"
                    className="author__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteAuthorHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default DeleteAuthorButton;