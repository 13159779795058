import { KeyboardEvent, MouseEvent, useEffect, useRef } from 'react';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import { ILibraryAuthor } from '../../interfaces';

interface IAuthorDescriptionProps {
    author: ILibraryAuthor;
    close: () => void;
    startEdit: () => void;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const AuthorDescription = ({ author, close, startEdit }: IAuthorDescriptionProps) => {

    const descExists = isDescExists(author.author_description);
    const parsedDesc = descExists ? markdown2Html(author.author_description) : '';

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    const onClick = (evt: MouseEvent<HTMLDivElement>) => {
        contentRef?.current?.focus();

        // double-click
        if(evt.detail === 2) {
            startEdit();
        }
    };

    const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'Escape') {
            close();
        }
    };

    return (
        <>
            {
                descExists &&
                <div className="flex flex-col">
                    <div
                        ref={ contentRef }
                        tabIndex={ 0 }
                        className="text-sm overflow-auto max-h-[500px] rounded my-1 outline-none"
                        onClick={ onClick }
                        onKeyDown={ onKeyDown }>
                        <div
                            className="markdown whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{__html: parsedDesc }}
                        />
                    </div>
                </div>
            }


        </>
    )
};

export default AuthorDescription;