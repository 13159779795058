import DeckTitle from './deck-title';
import DeckDescription from './deck-description';
import AddCardBtn from '../cards/actions/add-card-btn';
import CardItem from '../cards/card-item';
import StartStudyBtn from './actions/start-study-btn';
import DragCardItemTarget from '../cards/drag/drag-card-item-target';
import { useEffect } from 'react';
import { getFlashCardIdFromHash } from '../../domain/flashcards-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

const DeckView = () => {
    const selectedDeck = useFlashCardsStore(store => store.selectedDeck);
    const flashCards = useFlashCardsStore(store => store.flashCards);

    useEffect(() => {

        const onhashchange = () => {
            const card_id = getFlashCardIdFromHash(window.location.hash);
            if(!card_id) return;

            const $card = document.getElementById(`card-${ card_id }`);
            if(!$card) return;

            $card.scrollIntoView();

            const url = new URL(window.location.href);
            url.hash = '';
            history.replaceState('', '', url.toString());
        };

        onhashchange();

        window.addEventListener('hashchange', onhashchange);

        return () => {
            window.removeEventListener('hashchange', onhashchange);
        }
    }, []);

    return (
        <>
            {
                selectedDeck &&
                <div>
                    <DeckTitle deck={ selectedDeck } />
                    <DeckDescription deck={ selectedDeck } />
                    <StartStudyBtn
                        deck_id={ selectedDeck.deck_id }
                        classes="md:hidden"
                    />

                    {
                        flashCards && flashCards.length > 0 &&
                        <div className="my-4">

                            <DragCardItemTarget
                                targetCardId={ 0 }
                                deck_id={ selectedDeck.deck_id }
                                isBefore={ true }
                            />

                            {
                                flashCards.map((card, i) => {
                                    return (
                                        <CardItem
                                            index={ i }
                                            key={ card.card_id }
                                            card={ card }
                                        />
                                    )
                                })
                            }
                        </div>
                    }

                    <AddCardBtn deck_id={ selectedDeck.deck_id } />
                </div>
            }
        </>
    )
};

export default DeckView;