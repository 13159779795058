import { useState } from 'react';
import Todo from '../todo/todo';
import { PlusIcon } from '../../../../common/ui/icons';
import NewTodo from '../todo/todo-add';
import { ICombinedSection, ListType } from '../../planner-interfaces';
import TodoDragTarget from '../todo/drag/todo-drag-target';
import { canAddTasks, isSpecialProjectType } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';

enum AddTodoMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface ISectionTodosProps {
    combinedSection: ICombinedSection;
    project_id: number;
    titleVisible: boolean;
}

const SectionTodos = (props: ISectionTodosProps) => {

    const listType = usePlannerStore(state => state.listType);
    const projectHideTitles = usePlannerStore(store => store.projectHideTitles) && listType !== ListType.SummaryView;
    const isSpecial = isSpecialProjectType(listType);

    const shouldHideProjectTitles = isSpecial && projectHideTitles;

    const { combinedSection, project_id, titleVisible } = props;
    const { section } = combinedSection;

    const [addTodoMode, setAddTodoMode] = useState(AddTodoMode.DEFAULT);

    const startAddingTodo = () => {
        setAddTodoMode(AddTodoMode.ADDING);
    };

    const stopAddingTodo = () => {
        setAddTodoMode(AddTodoMode.DEFAULT);
    };

    const isSectionEmpty = combinedSection.todos.length <= 0;

    return (
        <>
            {
                (combinedSection.isOpened || !titleVisible) &&
                <div className={ titleVisible ? 'ml-6' : '' }>
                    {
                        combinedSection.todos.map((todo, index) =>
                            <Todo
                                todo={ todo }
                                key={ `todo-${ todo.todo_id}` }
                                isFirst={ index === 0 }
                                isLast={ projectHideTitles || (index === combinedSection.todos.length - 1) }
                                nestLevel={ 0 }
                                showTodoLink={ isSpecial }
                            />
                        )
                    }

                    {
                        isSectionEmpty &&
                        <TodoDragTarget
                            targetProjectId={ project_id }
                            targetTodoId={ 0 }
                            targetOrder={ 0 }
                            isBefore={ false }
                            isFirstSectionTodo={ true }
                            section_id={ section.section_id }
                            targetAsParent={ false }
                        />
                    }

                    {
                        addTodoMode === AddTodoMode.DEFAULT && !shouldHideProjectTitles && canAddTasks(listType) &&
                        <button
                            className={ `section__add-task flex items-center text-sm text-slate-400 ${ isSectionEmpty ? 'mt-4' : '' }` }
                            onClick={ startAddingTodo }
                            type="button">
                            <div className="mr-2"><PlusIcon /></div>
                            <div>Add Task</div>
                        </button>
                    }

                    {
                        addTodoMode === AddTodoMode.ADDING &&
                        <NewTodo
                            project_id={ project_id }
                            section_id={ section.section_id }
                            order={
                                isSectionEmpty ?
                                    1 :
                                    combinedSection.todos[combinedSection.todos.length - 1].todo_order + 1 }
                            cancel={ stopAddingTodo }
                        />
                    }

                </div>
            }
        </>
    )
};

export default SectionTodos;