import TodayButton from './actions/today-button';
import PrevMonthButton from './actions/prev-month-button';
import NextMonthButton from './actions/next-month-button';
import { format } from 'date-fns';
import { useHabitTrackerStore } from '../data/habit-tracker-store';

const ActionsBar = () => {

    const habitTrackerStartDate = useHabitTrackerStore(store => store.habitTrackerStartDate);

    return (
        <div className="flex items-center w-full mb-4">

            <TodayButton />

            <div className="flex items-center mx-6">
                <PrevMonthButton />
                <NextMonthButton />
            </div>

            <div>
                { format(habitTrackerStartDate, 'MMMM yyyy') }
            </div>

        </div>
    )
};

export default ActionsBar;