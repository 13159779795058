import { ITodo } from '../../planner-interfaces';
import { MapPinIcon } from '../../../../common/ui/icons';

interface IAddressBtn {
    todo: ITodo;
}

/**
 * https://developers.google.com/maps/documentation/urls/guide
 */
const getGoogleMapsLink = (address: string) => {
    const origin = encodeURIComponent('חדרה חובבי ציון 9');
    const destination = encodeURIComponent(address);

    return `https://www.google.com/maps/dir/?api=1&origin=${ origin }&destination=${ destination }`;
};

const AddressBtn = ({ todo }: IAddressBtn) => {
    return (
        <>
            {
                (todo.todo_address ?? '').trim() &&
                <a
                    href={ getGoogleMapsLink(todo.todo_address) }
                    rel="noreferrer"
                    target="_blank">
                    <MapPinIcon
                        size={ 20 }
                    />
                </a>
            }
        </>
    )
};

export default AddressBtn;