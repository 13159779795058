import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { getAllProjects, updateProject } from '../../data/planner-data-service';
import { IProject } from '../../planner-interfaces';
import { ProjectDescriptionMode } from './description/project-description';
import { showToast } from '../../../../common/domain/toast-provider';
import 'toolcool-color-picker';
import { combineProjectData } from '../../domain/planner-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { usePlannerStore } from '../../data/planner-store';

declare global {
    // eslint-disable-next-line
    namespace JSX {
        interface IntrinsicElements {
            // eslint-disable-next-line
            'toolcool-color-picker': any;
        }
    }
}

interface IEditProjectProps {
    project: IProject;
    setMode: (mode: ProjectDescriptionMode) => void;
}

const EditProject = (props: IEditProjectProps) => {

    const { project, setMode } = props;

    const [isLoading, setLoading] = useState(false);
    const [updatedColor, setUpdatedColor] = useState(project.project_color);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const setMenuProjects = usePlannerStore(state => state.setMenuProjects);
    const setStats = usePlannerStore(state => state.setStats);

    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        editorRef?.current?.focus();
    }, []);

    const colorPickerRef = useRef<HTMLElement>();

    useEffect(() => {

        const colorPicker = colorPickerRef.current;

        const onColorChange = (evt: Event) => {
            const customEvent = evt as CustomEvent;
            setUpdatedColor(customEvent.detail.rgba);
        };

        colorPicker?.addEventListener('change', onColorChange);

        return () => {
            colorPicker?.removeEventListener('change', onColorChange);
        };
    }, []);

    useEffect(() => {
        editorRef.current?.setMarkdown(project.project_desc || '');
    }, [project.project_desc]);

    const setDefaultMode = useCallback(() => {
        setMode(ProjectDescriptionMode.DEFAULT);
    }, [setMode]);

    const save = useCallback(async () => {
        const updatedText = editorRef.current?.getMarkdown() || '';

        setLoading(true);
        const response = await updateProject(
            project.project_id,
            updatedText,
            updatedColor,
        );
        setLoading(false);

        if(!response) {
            showToast('Update project error.');
            return;
        }

        setDefaultMode();

        if(!response) {
            showToast('Project edit is failed.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        const menuResponse = await getAllProjects();
        if(!menuResponse?.data) return;

        setMenuProjects(menuResponse.data);
        setStats(menuResponse.stats);
    }, [
        project.project_id, setCombinedProject, setDefaultMode,
        setMenuProjects, setStats, updatedColor,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div className={ `flex flex-col` }>

            <div className="flex items-center">
                <label className="text-xs font-bold text-slate-400 mr-4">Project Color:</label>
                <toolcool-color-picker
                    ref={ colorPickerRef }
                    color={ project.project_color }
                />
            </div>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Project Description</label>
            <div className="markdown-editor border rounded-xl whitespace-pre-wrap text-sm text-slate-800 overflow-auto max-h-[500px] outline-stone-200 mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setDefaultMode }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default EditProject;