import { useState, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { insertSection } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { useParams } from 'react-router-dom';
import { combineProjectData } from '../../domain/planner-provider';
import { handleWiziwig } from '../../domain/wiziwig-provider';
import { usePlannerStore } from '../../data/planner-store';

enum AddSectionMode {
    DEFAULT = 0,
    ADDING = 1,
}

interface IAddSectionProps {
    section_order: number;
    project_id: number;
    isTodayProject: boolean;
}

const AddSection = (props: IAddSectionProps) => {

    const { section_order, project_id, isTodayProject } = props;

    const { project_id: project_id_url } = useParams();

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [mode, setMode] = useState(AddSectionMode.DEFAULT);
    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [titleTouched, setTitleTouched] = useState(false);
    const [desc, setDesc] = useState('');

    const isTitleValid = !titleTouched || title.trim().length > 0;

    const titleRef = useRef<HTMLInputElement>(null);
    const descRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        titleRef?.current?.focus();
    }, [mode]);

    const startAdd = () => {
        setMode(AddSectionMode.ADDING);
    };

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setTitle((evt.target as HTMLInputElement).value);
        setTitleTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLDivElement>) => {
        setDesc((evt.target as HTMLDivElement).textContent);
    };

    const cancel = () => {
        setMode(AddSectionMode.DEFAULT);
    };

    const save = async () => {
        if(title.trim().length <= 0) {
            setTitleTouched(true);
            return;
        }

        setLoading(true);

        const response = await insertSection(
            project_id,
            title.trim(),
            desc.trim(),
            section_order + 1,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert section error.');
            return;
        }

        cancel();

        setCombinedProject(combineProjectData(response));
    };

    const onKeyDown = async (evt: KeyboardEvent<HTMLInputElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Escape') {
            cancel();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }

        if(handleWiziwig(evt, titleRef, (newText) => {
            setTitle(newText);
            setTitleTouched(true);
        })){
            return;
        }

    };

    const onKeyDownDesc = async (evt: KeyboardEvent<HTMLDivElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Escape') {
            evt.stopPropagation();
            close();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }

        if(handleWiziwig(evt, descRef)) return;
    };

    return (
        <>
            {/* Keep on spacing in the 'Today view' */}
            {
                isTodayProject && <div className="invisible">&nbsp;</div>
            }

            {
                !isTodayProject && (mode === AddSectionMode.DEFAULT) &&
                <button
                    type="button"
                    onClick={ startAdd }
                    className="section__add-btn my-2 flex justify-center w-full relative">
                    <div className="bg-slate-300 absolute h-px w-full top-2/4 invisible" />

                    {
                        (project_id_url !== 'today') &&
                        <div className="text-slate-500 text-sm bg-white px-4 relative invisible">Add section</div>
                    }
                </button>
            }

            {
                (mode === AddSectionMode.ADDING) &&
                <div className="my-4 border border-slate-200 text-slate-800 rounded p-4 text-sm flex flex-col">
                    <label className="flex flex-col mb-4">
                        <div className="font-bold text-slate-400 mb-2">New Section Title</div>
                        <input
                            ref={ titleRef }
                            onInput={ onTitleChange }
                            onKeyDown={ onKeyDown }
                            className={ `border rounded px-4 py-2 ${ isTitleValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                            type="text"
                        />

                        {
                            !isTitleValid && <div className="text-red-700 text-xs mt-1">The title is required.</div>
                        }
                    </label>

                    <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
                    <div
                        ref={ descRef }
                        className="border rounded px-4 py-2 whitespace-pre-wrap overflow-auto max-h-[500px] min-h-[100px] outline-stone-200"
                        contentEditable="true"
                        role="textbox"
                        aria-readonly="false"
                        aria-multiline="true"
                        aria-label="Description"
                        translate="no"
                        onInput={ onDescChange }
                        onKeyDown={ onKeyDownDesc }
                    />

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ cancel }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default AddSection;