import { useState } from 'react';
import { duplicateTodo } from '../../../data/planner-data-service';
import { CopyIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { usePlannerStore } from '../../../data/planner-store';

interface IDuplicateTodoProps {
    todo: ITodo;
}

const DuplicateTodoButton = (props: IDuplicateTodoProps) => {

    const { todo } = props;

    const [isLoading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const duplicateTodoHandler = async () => {

        setLoading(true);
        const response = await duplicateTodo(
            todo.todo_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Duplicate task error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Duplicate Task"
                    className="todo__duplicate flex bg-green-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ duplicateTodoHandler }
                    type="button">
                    <CopyIcon
                        size={ 20 }
                        color={ '#128573' }
                        strokeWidth={ 1 }
                    />
                </button>
            }
        </>
    )
};

export default DuplicateTodoButton;