import { ChartIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
interface IShowHabitChartBtn {
    todo: ITodo;
}

const OpenHabitChartBtn = ({ todo }: IShowHabitChartBtn) => {

    return (
        <a
            className="todo__habit-tracker-btn flex rounded mr-2 justify-center items-center"
            target="_blank"
            rel="noreferrer"
            href={ `/habit-tracker/${ todo.todo_id }` }
            title="Open Habit Tracker/">
            <ChartIcon color={ '#586180' } fill={ '#E0E7FF' } />
        </a>
    )
};

export default OpenHabitChartBtn;