import { CardDescriptionMode } from './card-description';

interface IDescriptionPreviewProps {
    description: string;
    setMode: (mode: CardDescriptionMode) => void;
}

const CardDescriptionPreview = (props: IDescriptionPreviewProps) => {
    const { description, setMode } = props;

    const setEditMode = () => {
        setMode(CardDescriptionMode.EDIT);
    };

    return (
        <div
            className="text-sm leading-none overflow-hidden bg-slate-50 text-slate-600 rounded px-4 py-2 cursor-pointer h-[38px]"
            onClick={ setEditMode }>
            <div className="markdown whitespace-pre-wrap">
                <pre>
                    <code style={{
                        backgroundColor: 'transparent',
                    }}>{ description }</code>
                </pre>
            </div>
        </div>
    )
};

export default CardDescriptionPreview;