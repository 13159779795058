import { getJsonFromLocalStorage, saveJsonToLocalStorage } from '../../../common/data/storage-provider';

const LIBRARY_OPENED_FOLDERS_KEY = 'LIBRARY_OPENED_FOLDERS_KEY';
const LIBRARY_CLOSED_SERIES_KEY = 'LIBRARY_CLOSED_SERIES_KEY';

// ------------------ FOLDERS -------------------

/**
 * All folders are closed by default.
 * Only opened folders are stored in the local storage.
 */
export const getOpenedLibraryFolders = () : Set<number> => {
    const folder_ids = getJsonFromLocalStorage(LIBRARY_OPENED_FOLDERS_KEY) || [];
    return new Set<number>(folder_ids);
};

export const openLibraryFolder = (folder_id: number) : number[] => {
    const folders = getOpenedLibraryFolders();
    folders.add(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(LIBRARY_OPENED_FOLDERS_KEY, list);
    return list;
};

export const closeLibraryFolder = (folder_id: number) : number[] => {
    const folders = getOpenedLibraryFolders();
    folders.delete(folder_id);

    const list = Array.from(folders);
    saveJsonToLocalStorage(LIBRARY_OPENED_FOLDERS_KEY, list);
    return list;
};

// ------------------ SERIES -------------------

/**
 * All series are opened by default.
 * Only closed serie_id are stored in the local storage.
 */
export const getClosedSeries = () : number[] => {
    return getJsonFromLocalStorage(LIBRARY_CLOSED_SERIES_KEY) || [];
};

export const closeSerie = (serie_id: number) => {
    const list = getClosedSeries();
    if(serie_id === 0) return list;

    const set = new Set(list);
    set.add(serie_id);
    saveJsonToLocalStorage(LIBRARY_CLOSED_SERIES_KEY, Array.from(set));
    return list;
};

export const openSerie = (serie_id: number) => {
    const list = getClosedSeries();
    const set = new Set(list);
    set.delete(serie_id);
    saveJsonToLocalStorage(LIBRARY_CLOSED_SERIES_KEY, Array.from(set));
    return list;
};
