import { PlusIcon } from '../../../../common/ui/icons';
import { ReactNode, useState } from 'react';
import AddVariantForm from '../actions/add-variant-form';
import { DescartesSquareType } from '../../descartes-square-interfaces';
import TodoVariant from './todo-variant';
import { useDescartesSquareStore } from '../../data/descartes-square-store';

interface ISquareViewCell {
    title: ReactNode;
    classes?: string;
    descartes_square_type: DescartesSquareType;
}

enum SquareViewCellMode {
    Default = 0,
    AddVariant = 1,
}

const SquareViewCell = ({ title, classes, descartes_square_type }: ISquareViewCell) => {

    const descartesSquare = useDescartesSquareStore(store => store.descartesSquare);
    const variants = descartesSquare?.map.get(descartes_square_type) || [];

    const [mode, setMode] = useState<SquareViewCellMode>(SquareViewCellMode.Default);

    const startAddVariant = () => {
        setMode(SquareViewCellMode.AddVariant);
    };

    const stopAddVariant = () => {
        setMode(SquareViewCellMode.Default);
    };

    return (
        <div className={ `p-4 bg-white border-slate-200 ${ classes }` }>
            <div className="text-slate-500 text-sm flex items-center justify-between mb-4">
                { title }

                <button
                    onClick={ startAddVariant }
                    type="button">
                    <PlusIcon />
                </button>
            </div>

            {
                (mode === SquareViewCellMode.AddVariant) &&
                <AddVariantForm descartes_square_type={ descartes_square_type } cancel={ stopAddVariant } />
            }

            {
                variants && variants.map((variant, i) => {

                    return (
                        <TodoVariant
                            key={ variant.todo_variant_id }
                            variant={ variant }
                            isLast={ i === variants.length - 1 }
                        />
                    )
                })
            }
        </div>
    )
};

export default SquareViewCell;