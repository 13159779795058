import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { SERIE_EDIT_EVENT } from '../serie-edit';

interface IEditSerieBtnProps {
    serie_id: number;
}

const EditSerieBtn = ({ serie_id }: IEditSerieBtnProps) => {

    const handleClick = () => {
        document.dispatchEvent(new CustomEvent(SERIE_EDIT_EVENT, {
            detail: serie_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ handleClick }
            className="flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default EditSerieBtn;