import { TodoType } from '../../../planner-interfaces';
import { HabitTrackerIntervalType } from '../../../../habit-tracker/domain/habit-tracker-provider';
import { ChangeEvent } from 'react';
import WeekDays from './week-days';
import { WeekDaysEnum } from '../../../../../common/domain/date-time-provider';

interface ITodoHabitTrackerForm {
    type: TodoType;

    isHabitTrackerActive: boolean;
    setIsHabitTrackerActive: (value: (((prevState: boolean) => boolean) | boolean)) => void;

    habitInterval: HabitTrackerIntervalType;
    setHabitInterval: (value: (((prevState: HabitTrackerIntervalType) => HabitTrackerIntervalType) | HabitTrackerIntervalType)) => void;

    habitQtyPerInterval: TodoType;
    setHabitQtyPerInterval: (value: (((prevState: TodoType) => TodoType) | TodoType)) => void;

    habitWeekDays: Set<WeekDaysEnum>;
    setHabitWeekDays: (value: (((prevState: Set<WeekDaysEnum>) => Set<WeekDaysEnum>) | Set<WeekDaysEnum>)) => void;
}

const TodoHabitTrackerForm = ({
      type,
      isHabitTrackerActive,
      setIsHabitTrackerActive,
      habitInterval,
      setHabitInterval,
      habitQtyPerInterval,
      setHabitQtyPerInterval,
      habitWeekDays,
      setHabitWeekDays,
}: ITodoHabitTrackerForm) => {

    const onHabitTrackerActiveChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const _isActive = (evt.target as HTMLInputElement).checked;
        setIsHabitTrackerActive(_isActive);
    };

    const onHabitIntervalChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const interval: HabitTrackerIntervalType = Number(evt.target.value) || HabitTrackerIntervalType.Day;
        setHabitInterval(interval);
    };

    const onHabitQtyPerInterval = (evt: ChangeEvent<HTMLInputElement>) => {
        const qtyPerInterval = Number(evt.target.value) || 0;
        setHabitQtyPerInterval(qtyPerInterval);
    };

    return (
        <>
            {
                type === TodoType.RegularTask &&
                <div className="flex flex-col mb-4 text-xs">
                    <label className="font-bold mt-2 mb-1 text-slate-400">Habit Tracker</label>

                    <div className="flex flex-col md:flex-row">

                        <label className="flex items-center mr-4 mb-2 md:mb-0">
                            <input
                                checked={ isHabitTrackerActive }
                                onChange={ onHabitTrackerActiveChange }
                                type="checkbox"
                                className="mr-2"
                            />
                            <div>Enabled?</div>
                        </label>

                        <select
                            value={ habitInterval }
                            onChange={ onHabitIntervalChange }
                            className="outline-none mr-4 mb-2 md:mb-0 bg-slate-100 rounded px-4 py-2 w-full md:w-auto">
                            <option value={ HabitTrackerIntervalType.NotDefined }>Not Defined</option>
                            <option value={ HabitTrackerIntervalType.Day }>Day</option>
                            <option value={ HabitTrackerIntervalType.Week }>Week</option>
                            <option value={ HabitTrackerIntervalType.Month }>Month</option>
                            <option value={ HabitTrackerIntervalType.Year }>Year</option>
                        </select>

                        <input
                            type="number"
                            placeholder="qty per interval"
                            className="border rounded px-4 py-2 text-slate-800 outline-stone-200 mr-4 mb-2 md:mb-0"
                            value={ habitQtyPerInterval }
                            onChange={ onHabitQtyPerInterval }
                        />

                        <WeekDays
                            habitInterval={ habitInterval }
                            habitWeekDays={ habitWeekDays }
                            setHabitWeekDays={ setHabitWeekDays }
                        />
                    </div>

                </div>
            }
        </>
    )
};

export default TodoHabitTrackerForm;