import { useState } from 'react';
import { showToast } from '../../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../../common/ui/icons';
import { ILibraryTag, ILibraryTagViewFormatted } from '../../../../interfaces';
import { deleteTag } from '../../../../data/library-data-service';
import { formatTagsData } from '../../../../domain/library-provider';

interface IDeleteTagProps {
    tag: ILibraryTag;
    setTagsView: (formattedData: ILibraryTagViewFormatted) => void;
}

const DeleteTag = ({ tag, setTagsView }: IDeleteTagProps) => {

    const [isLoading, setLoading] = useState(false);

    const deleteTagHandler = async () => {

        if(!confirm(`Are you sure you want to delete the tag "${ tag.tag_title }"?`)) return;

        setLoading(true);
        const response = await deleteTag(tag.tag_id);
        setLoading(false);

        if(!response) {
            showToast('Delete tag error.');
            return;
        }

        setTagsView(formatTagsData(response));
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Tag"
                    className="tag__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteTagHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteTag;