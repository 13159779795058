import { ArrowDownIcon } from '../../../../../common/ui/icons';
import { useOverviewStore } from '../../../data/overview-store';

interface ITableHeaderBtn {
    title: string;
    fieldName: string;
    isCentered: boolean;
}

const TableHeaderBtn = ({ title, fieldName, isCentered }: ITableHeaderBtn) => {

    const sortBy = useOverviewStore(state => state.sortBy);
    const setSortBy = useOverviewStore(state => state.setSortBy);

    const sortDirection = useOverviewStore(state => state.sortDirection);
    const setSortDirection = useOverviewStore(state => state.setSortDirection);

    const isBtnActive = fieldName === sortBy;
    let className = `whitespace-nowrap px-4 py-2 font-bold flex items-center ${ isBtnActive ? 'text-teal-600' : '' } ${ fieldName ? 'cursor-pointer' : 'cursor-default' }`;

    if(isCentered) {
        className += isCentered ? ` justify-center text-center ${ fieldName ? 'ml-4' : '' } ` : 'text-left';
    }

    const handleSortBy = () => {
        if(!fieldName) return;

        if(sortBy === fieldName) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
            return;
        }

        setSortBy(fieldName);
        setSortDirection('ASC');
    };

    return (
        <button
            onClick={ handleSortBy }
            type="button"
            className={ className }>

            { title }

            {
                fieldName &&
                <ArrowDownIcon
                    style={{
                        transform: `${ sortBy === fieldName && sortDirection === 'DESC' ? 'rotate(180deg)' : '' }`,
                    }}
                />
            }
        </button>
    )
};

export default TableHeaderBtn;