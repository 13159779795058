import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { IDeck } from '../../flashcards-interfaces';
import { DeckDescriptionMode } from './deck-description';
import { updateDeck } from '../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../domain/flashcards-provider';
import { useFlashCardsStore } from '../../data/flashcards-store';

interface IEditDeckProps {
    deck: IDeck;
    setMode: (mode: DeckDescriptionMode) => void;
}

const EditDeck = (props: IEditDeckProps) => {

    const { deck, setMode } = props;

    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const [isLoading, setLoading] = useState(false);

    const editorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        editorRef?.current?.focus();
    }, []);

    useEffect(() => {
        editorRef.current?.setMarkdown(deck.deck_desc || '');
    }, [deck.deck_desc]);

    const setDefaultMode = useCallback(() => {
        setMode(DeckDescriptionMode.DEFAULT);
    }, [setMode]);

    const save = useCallback(async () => {
        const updatedText = editorRef.current?.getMarkdown() || '';

        setLoading(true);
        const response = await updateDeck(
            flashCardsListType,
            deck.deck_id,
            deck.deck_name,
            updatedText,
        );
        setLoading(false);

        if(!response) {
            showToast('Update deck error.');
            return;
        }

        setDefaultMode();

        if(!response) {
            showToast('Deck edit is failed.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response, deck.deck_id));
    }, [
        deck.deck_id,
        deck.deck_name,
        setDefaultMode,
        flashCardsListType,
        setFlashCardsTree,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                editorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div className={ `flex flex-col` }>

            {/*<label className="text-xs font-bold mt-2 mb-1 text-slate-400">Deck Description</label>*/}
            <div className="markdown-editor border rounded-xl whitespace-pre-wrap text-sm text-slate-800 overflow-auto max-h-[500px] outline-stone-200 mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ setDefaultMode }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default EditDeck;