import { create } from 'zustand';
import { IBlocksOverviewData } from '../blocks-overview-interfaces';

interface BlocksOverviewState {
    blocksOverviewData: IBlocksOverviewData|null;
    setBlocksOverviewData: (_blocksOverviewData: IBlocksOverviewData|null) => void;

    currentMonth: number;
    setCurrentMonth: (_currentMonth: number) => void;

    currentYear: number;
    setCurrentYear: (_currentYear: number) => void;
}

const now = new Date();

export const useBlocksOverviewStore = create<BlocksOverviewState>()((set) => ({
    blocksOverviewData: null,
    setBlocksOverviewData: (_blocksOverviewData: IBlocksOverviewData|null) => {
        return set((state: BlocksOverviewState) => {
            return {
                ...state,
                blocksOverviewData: _blocksOverviewData,
            };
        });
    },

    currentMonth: now.getMonth(),
    setCurrentMonth: (_currentMonth: number) => {
        return set((state: BlocksOverviewState) => {
            return {
                ...state,
                currentMonth: _currentMonth,
            };
        });
    },

    currentYear: now.getFullYear(),
    setCurrentYear: (_currentYear: number) => {
        return set((state: BlocksOverviewState) => {
            return {
                ...state,
                currentYear: _currentYear,
            };
        });
    },
}))