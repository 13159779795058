import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect } from 'react';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import BlocksTable from './ui/blocks-table';

const BlocksOverviewHome = () => {
    const { loading } = useAuthStatus();

    /**
     * Change title.
     */
    useEffect(() => {
        document.title = `Blocks | Productivity`;
    }, []);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden bg-white">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } hideSideBarToggle={ true } />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 w-screen">
                        <div className="overflow-auto">
                            <div className="max-w-full mx-auto">

                                <BlocksTable />

                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )

};

export default BlocksOverviewHome;