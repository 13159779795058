import SquareViewCell from './square-view-cell';
import { DescartesSquareType } from '../../descartes-square-interfaces';


const SquareView = () => {
    return (
        <div className="w-full grid md:grid-cols-2 md:grid-rows-2" style={{
            gridTemplateRows: 'repeat(2, min-content)',
        }}>

            <SquareViewCell
                title={ <p>Positive Outcome If Happens 👍</p> }
                classes="border"
                descartes_square_type={ DescartesSquareType.PositiveOutcomeIfHappens }
            />

            <SquareViewCell
                title={ <p>Positive Outcome If <span className="text-slate-800">Not</span> Happens 👍🏾</p> }
                classes="border-r md:border-t border-b border-l md:border-l-0"
                descartes_square_type={ DescartesSquareType.PositiveOutcomeIfNotHappens }
            />

            <SquareViewCell
                title={ <p>Negative Outcome If Happens 👎</p> }
                classes="border-r border-l border-b"
                descartes_square_type={ DescartesSquareType.NegativeOutcomeIfHappens }
            />

            <SquareViewCell
                title={ <p>Negative Outcome If <span className="text-slate-800">Not</span> Happens 👎🏾</p> }
                classes="border-r border-b border-l md:border-l-0"
                descartes_square_type={ DescartesSquareType.NegativeOutcomeIfNotHappens }
            />

            <SquareViewCell
                title={ <p>How To Increase Positive Outcomes?</p> }
                classes="border-r border-l border-b"
                descartes_square_type={ DescartesSquareType.HowToIncreasePositiveOutcome }
            />

            <SquareViewCell
                title={ <p>How To Reduce Negative Outcomes?</p> }
                classes="border-r border-b border-l md:border-l-0"
                descartes_square_type={ DescartesSquareType.HowToReduceNegativeOutcome }
            />

            <SquareViewCell
                title={ <p>Какой Идеальный Конечный Результат? ⭐️</p> }
                classes="border-r border-l border-b"
                descartes_square_type={ DescartesSquareType.WhatIsIdealFinalResult }
            />

            <SquareViewCell
                title={ <p>Сформулировать Противоречие</p> }
                classes="border-r border-b border-l md:border-l-0"
                descartes_square_type={ DescartesSquareType.FormulateContradiction }
            />

            <SquareViewCell
                title={ <p>What is the cause of the problem❓</p> }
                classes="border-r border-b border-l"
                descartes_square_type={ DescartesSquareType.WhatITheCauseOfTheProblem }
            />

            <SquareViewCell
                title={ <p>What small change would significantly improve the process?</p> }
                classes="border-r border-b border-l md:border-l-0"
                descartes_square_type={ DescartesSquareType.SmallChange }
            />

            <SquareViewCell
                title={ <p>Какое минимальное хорошее решение?</p> }
                classes="border-r border-b border-l"
                descartes_square_type={ DescartesSquareType.WhatIsTheMinimumGoodSolution }
            />

        </div>
    )
};

export default SquareView;