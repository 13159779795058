import { DragIcon } from '../../../../../common/ui/icons';
import { DragEvent } from 'react';
import { FLASHCARDS_ITEM_DRAG_CLASS } from '../../../domain/flashcards-provider';
import { IFlashCard } from '../../../flashcards-interfaces';

interface IDragCardItemBtn {
    card: IFlashCard;
}

const DragCardItemBtn = ({ card }: IDragCardItemBtn) => {

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setData
     */
    const sourceOnDragStart = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();

        // Clear the actions' data cache (for all formats/types)
        evt.dataTransfer.clearData();

        evt.dataTransfer.setData('text/plain', card.card_id.toString());

        // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
        ((_currentTarget) => {
            window.setTimeout(() => {
                document.body.classList.add(FLASHCARDS_ITEM_DRAG_CLASS);
            }, 0)
        })(evt.currentTarget);
    };

    const sourceOnDragEnd = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        document.body.classList.remove(FLASHCARDS_ITEM_DRAG_CLASS);
    };

    const sourceOnDrag = (evt: DragEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
    };

    return (
        <button
            type="button"
            draggable={ true }
            onDragStart={ sourceOnDragStart }
            onDragEnd={ sourceOnDragEnd }
            onDrag={ sourceOnDrag }
            className="flashcard-item-drag-btn cursor-move flex items-center justify-center mx-1 bg-purple-100 rounded action-btn">
            <DragIcon color={ '#6B22A8' } />
        </button>
    )
};

export default DragCardItemBtn;