import { EditIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { IFlashCard } from '../../../flashcards-interfaces';
import { EDIT_CARD_DESCRIPTION_EVENT } from '../description/card-description';

interface ICardEditButtonProps {
    card: IFlashCard;
}

const CardEditButton = (props: ICardEditButtonProps) => {

    const { card } = props;

    const sendEvent = () => {
        document.dispatchEvent(new CustomEvent(EDIT_CARD_DESCRIPTION_EVENT, {
            detail: card.card_id,
        }));

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Edit Card"
            className="card__add-desc flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ sendEvent }
            type="button">
            <EditIcon color={ '#6e7781' } size={ 20 } />
        </button>
    )
};

export default CardEditButton;