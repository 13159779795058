import { useEffect, useState } from 'react';
import { IProject } from '../../../planner-interfaces';
import ProjectFullDescription from './project-full-description';
import EditProject from '../project-edit';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';

export const ADD_NEW_PROJECT_DESCRIPTION_EVENT = 'add-new-project-desc';

export enum ProjectDescriptionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface IProjectDescriptionProps {
    project: IProject;
}

export const isDescExists = (desc: string) => {
    return desc !== undefined &&
        desc !== null &&
        desc.trim() !== '';
};

const ProjectDescription = ({ project }: IProjectDescriptionProps) => {

    const [mode, setMode] = useState<ProjectDescriptionMode>(ProjectDescriptionMode.DEFAULT);

    const descExists = isDescExists(project.project_desc);

    const parsedDesc = descExists ? markdown2Html(project.project_desc) : '';

    const updateMode = (_mode: ProjectDescriptionMode) => {
        setMode(_mode);
    };

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const project_id = Number(evt?.detail) || 0;
            if(project_id !== project.project_id) return;
            setMode(ProjectDescriptionMode.EDIT);
        };

        document.addEventListener(ADD_NEW_PROJECT_DESCRIPTION_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_PROJECT_DESCRIPTION_EVENT, handler);
        };
    }, [project.project_id]);

    return (
        <>
            {
                (descExists && mode === ProjectDescriptionMode.DEFAULT) &&
                <ProjectFullDescription
                    parsedDesc={ parsedDesc as string }
                    setMode={ updateMode }
                />
            }

            {
                (mode === ProjectDescriptionMode.EDIT) &&
                <EditProject
                    project={ project }
                    setMode={ updateMode }
                />
            }
        </>
    )
};

export default ProjectDescription;