import { create } from 'zustand';
import { IDiagram } from '../diagram-interfaces';

interface DiagramState {
    diagram: IDiagram|null;
    setDiagram: (_diagram: IDiagram|null) => void;
}

export const useDiagramStore = create<DiagramState>()((set) => ({
    diagram: null,
    setDiagram: (_diagram: IDiagram|null) => {
        return set((state: DiagramState) => {
            return {
                ...state,
                diagram: _diagram,
            };
        });
    },
}))