import { ChangeEvent } from 'react';
import { useOverviewStore } from '../../data/overview-store';

export enum RootFilterEnum {
    Root = 0,
    NonRoot = 1,
    Both = 2,
}

const RootFilter = () => {

    const rootFilter = useOverviewStore(state => state.rootFilter);
    const setRootFilter = useOverviewStore(state => state.setRootFilter);

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _hasDateFilter: RootFilterEnum = Number((evt.target as HTMLSelectElement).value) ?? RootFilterEnum.Both;
        setRootFilter(_hasDateFilter);
    };

    return (
        <label className="text-xs flex items-center mr-10">
            <select
                value={ rootFilter }
                onChange={ onChange }
                className="outline-none">
                <option value={ RootFilterEnum.Root }>Root</option>
                <option value={ RootFilterEnum.NonRoot }>Non Root</option>
                <option value={ RootFilterEnum.Both }>Both</option>
            </select>
        </label>
    )
};

export default RootFilter;