import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { formatLibraryData } from '../../../domain/library-provider';
import { ESelectedLibraryItemType, ILibrarySerie } from '../../../interfaces';
import { deleteSerie } from '../../../data/library-data-service';
import { useLibraryStore } from '../../../data/library-store';

interface IDeleteSerieProps {
    serie: ILibrarySerie;
}

const DeleteSerieButton = ({ serie }: IDeleteSerieProps) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const setSelectedLibraryItem = useLibraryStore(store => store.setSelectedLibraryItem);

    const deleteSerieHandler = async () => {

        if(!confirm(`Are you sure you want to delete the serie "${ `${ serie.serie_title }` }"?`)) return;

        setLoading(true);
        const response = await deleteSerie(
            serie.author_id,
            serie.serie_id,
            ESelectedLibraryItemType.AUTHOR,
            serie.author_id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete serie error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));
        setSelectedLibraryItem({
            type: ESelectedLibraryItemType.AUTHOR,
            id: serie.author_id,
        });
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Serie"
                    className="serie__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteSerieHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }
        </>
    )
};

export default DeleteSerieButton;