import { PlusIcon } from '../../../../../common/ui/icons';
import { useEffect, useState } from 'react';
import { ITodo } from '../../../planner-interfaces';
import EditItem from './edit-item';

interface ITodoBlocksEdit {
    todo: ITodo;
    close: () => void;
}

export enum TodoBlocksEditMode {
    DEFAULT = 0,
    START_ADD = 1
}

const TodoBlocksEdit = ({ todo, close }: ITodoBlocksEdit) => {

    const [mode, setMode] = useState<TodoBlocksEditMode>(TodoBlocksEditMode.DEFAULT);

    const startAdd = () => {
        setMode(TodoBlocksEditMode.START_ADD);
    };

    const stopAdd = () => {
        setMode(TodoBlocksEditMode.DEFAULT);
    };

    useEffect(() => {

        const onKeyDown = (evt: KeyboardEvent) => {
            if(evt.code === 'Escape') {
                close();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [close]);

    return (
        <div className="flex flex-col text-sm my-2">

            {
                todo.blocks.map(block => {
                    return (
                        <div
                            key={ block.block_id }>
                            <EditItem
                                todo_id={ todo.todo_id }
                                block={ block }
                                cancelNewBlock={ stopAdd }
                                close={ close }
                            />
                        </div>
                    )
                })
            }

            {
                (mode === TodoBlocksEditMode.START_ADD) &&
                <EditItem
                    todo_id={ todo.todo_id }
                    block={ null }
                    cancelNewBlock={ stopAdd }
                    close={ close }
                />
            }

            <div className="flex items-center mt-2">

                <button
                    onClick={ close }
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    type="button">
                    Close
                </button>

                {
                    (mode === TodoBlocksEditMode.DEFAULT) &&
                    <button
                        onClick={ startAdd }
                        className="flex bg-slate-500 text-slate-100 rounded justify-center items-center px-4 py-2 mr-2"
                        type="button">
                        <PlusIcon color={ '#fff' } classes="mr-2" /> Block
                    </button>
                }

                {/*{
                    (mode === TodoBlocksEditMode.START_ADD) &&
                    <>
                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                onClick={ save }
                                className="flex bg-slate-500 text-slate-100 rounded justify-center items-center px-3 py-[5px] mr-2"
                                type="button">
                                <SaveIcon
                                    size={ 20 }
                                    color={ '#fff' }
                                    classes="mr-2" /> Save
                            </button>
                        }
                    </>
                }*/}

            </div>
        </div>
    )
};

export default TodoBlocksEdit;