import { FolderIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { ILibraryAuthor } from '../../../interfaces';
import { closeSerie } from '../../../domain/library-storage';
import { useLibraryStore } from '../../../data/library-store';

interface IClosePAllSeriesBtnProps {
    author: ILibraryAuthor;
}

const CloseAllSeriesBtn = ({ author }: IClosePAllSeriesBtnProps) => {

    const libraryData = useLibraryStore(state => state.libraryData);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const closeAllSeries = () => {
        const series = author.series || [];
        for(const serie of series) {
            serie.isClosed = true;
            closeSerie(serie.serie_id);
        }

        setLibraryData(JSON.parse(JSON.stringify(libraryData)))

        sendCloseActionsEvent();
    };

    return (
        <button
            title="Close All Series"
            className="flex bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ closeAllSeries }
            type="button">
            <FolderIcon size={ 20 } />
        </button>
    )
};

export default CloseAllSeriesBtn;