import { get, postJson, putJson } from '../../../common/data/common-data-service';
import { DescartesSquareType, IDescartesSquareResponse } from '../descartes-square-interfaces';

export const getDescartesSquareData = async (todo_id: number) : Promise<IDescartesSquareResponse> => {
    return await get(`/api/descartes-square/get?todo_id=${ todo_id }`);
};

export const insertTodoVariant = async (
    todo_parent_id: number,
    todo_title: string,
    todo_desc: string,
    descartes_square_type: DescartesSquareType,
) : Promise<IDescartesSquareResponse> => {
    return await postJson(`/api/descartes-square/insert-variant`, {
        todo_parent_id,
        todo_title,
        todo_desc,
        descartes_square_type,
    });
};

export const updateTodoVariant = async (
    todo_parent_id: number,
    todo_variant_title: string,
    todo_variant_desc: string,
    todo_variant_id: number,
) : Promise<IDescartesSquareResponse> => {
    return await putJson(`/api/descartes-square/update-variant`, {
        todo_parent_id,
        todo_variant_title,
        todo_variant_desc,
        todo_variant_id,
    });
};

export const updateTodoVariantTitle = async (
    todo_parent_id: number,
    todo_variant_title: string,
    todo_variant_id: number,
) : Promise<IDescartesSquareResponse> => {
    return await putJson(`/api/descartes-square/update-variant-title`, {
        todo_parent_id,
        todo_variant_title,
        todo_variant_id,
    });
};

export const deleteTodoVariant = async (todo_variant_id: number, todo_parent_id: number) : Promise<IDescartesSquareResponse> => {
    return await postJson(`/api/descartes-square/delete-variant`, {
        todo_variant_id,
        todo_parent_id,
    });
};