import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../common/markdown/markdown-editor-provider';
import { ESelectedLibraryItemType, ILibraryAuthor } from '../../interfaces';
import { formatLibraryData } from '../../domain/library-provider';
import { updateAuthor } from '../../data/library-data-service';
import { useLibraryStore } from '../../data/library-store';

interface IEditAuthorProps {
    author: ILibraryAuthor;
    close: () => void;
}

const EditAuthor = ({ author, close }: IEditAuthorProps) => {

    const [isLoading, setLoading] = useState(false);

    const setLibraryData = useLibraryStore(state => state.setLibraryData);
    const selectedLibraryItem = useLibraryStore(state => state.selectedLibraryItem);

    const [firstName, setFirstName] = useState(author.author_first_name || '');
    const [lastName, setLastName] = useState(author.author_last_name || '');

    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [lastNameTouched, setLastNameTouched] = useState(false);

    const isFirstNameValid = !firstNameTouched || firstName.trim().length > 0;
    const isLastNameValid = !lastNameTouched || lastName.trim().length > 0;

    const firstNameRef = useRef<HTMLInputElement>(null);
    const descriptionEditorRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        firstNameRef?.current?.focus();
    }, []);

    useEffect(() => {
        descriptionEditorRef.current?.setMarkdown(author.author_description || '');
    }, [author.author_description]);

    const save = useCallback(async () => {
        if(firstName.trim().length <= 0) {
            setFirstNameTouched(true);
            return;
        }

        if(lastName.trim().length <= 0) {
            setLastNameTouched(true);
            return;
        }

        const description = descriptionEditorRef.current?.getMarkdown() || '';

        setLoading(true);
        const response = await updateAuthor(
            author.author_id,
            firstName,
            lastName,
            selectedLibraryItem?.type || ESelectedLibraryItemType.ROOT,
            selectedLibraryItem?.id || 0,
            description,
        );
        setLoading(false);

        if(!response) {
            showToast('Update author error.');
            return;
        }

        setLibraryData(formatLibraryData(response || []));

        close();
    }, [
        author.author_id, setLibraryData,
        firstName, lastName, close,
        selectedLibraryItem?.id,
        selectedLibraryItem?.type,
    ]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                descriptionEditorRef.current?.setMarkdown('');
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onFirstNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setFirstName((evt.target as HTMLInputElement).value);
        setFirstNameTouched(true);
    };

    const onLastNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setLastName((evt.target as HTMLInputElement).value);
        setLastNameTouched(true);
    };

    return (
        <div className="flex flex-col">

            <label className="flex flex-col mb-4">
                <div className="text-xs font-bold mt-2 mb-1 text-slate-400">First Name</div>
                <input
                    ref={ firstNameRef }
                    value={ firstName }
                    onInput={ onFirstNameChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isFirstNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isFirstNameValid && <div className="text-red-700 text-xs mt-1">The first name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="text-xs font-bold mt-2 mb-1 text-slate-400">Last Name</div>
                <input
                    value={ lastName }
                    onInput={ onLastNameChange }
                    onKeyDown={ onKeyDown }
                    className={ `border rounded px-4 py-2 ${ isLastNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isLastNameValid && <div className="text-red-700 text-xs mt-1">The last name is required.</div>
                }
            </label>

            <label className="text-xs font-bold mt-2 mb-1 text-slate-400">Description</label>
            <div className="markdown-editor border rounded-xl whitespace-pre-wrap text-sm text-slate-800 overflow-auto max-h-[500px] outline-stone-200 mb-4">
                <MDXEditor
                    ref={ descriptionEditorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">
                <button
                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                    onClick={ close }
                    type="button">Cancel</button>

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>
        </div>
    )
};

export default EditAuthor;