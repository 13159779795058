import { PlusIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { insertAuthor } from '../../data/library-data-service';
import { formatLibraryData } from '../../domain/library-provider';
import { useLibraryStore } from '../../data/library-store';

enum AddAuthorMode {
    DEFAULT = 0,
    ADDING = 1,
}

const AddAuthor = () => {

    const selectedLibraryItem = useLibraryStore(store => store.selectedLibraryItem);
    const setLibraryData = useLibraryStore(state => state.setLibraryData);

    const [addAuthorMode, setAddAuthorMode] = useState(AddAuthorMode.DEFAULT);
    const [isLoading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [firstNameTouched, setFirstNameTouched] = useState(false);

    const isFirstNameValid = !firstNameTouched || firstName.trim().length > 0;
    const firstNameRef = useRef<HTMLInputElement>(null);

    const [lastName, setLastName] = useState('');

    useEffect(() => {
        firstNameRef?.current?.focus();
    }, [addAuthorMode]);

    const startAddingAuthor = () => {
        setAddAuthorMode(AddAuthorMode.ADDING);
    };

    const stopAddingAuthor = () => {
        setAddAuthorMode(AddAuthorMode.DEFAULT);
        setFirstNameTouched(false);
        setFirstName('');
        setLastName('');
    };

    const onFirstNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setFirstName((evt.target as HTMLInputElement).value);
        setFirstNameTouched(true);
    };

    const onLastNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setLastName((evt.target as HTMLInputElement).value);
    };

    const save = useCallback(async () => {
        if(firstName.trim().length <= 0) {
            setFirstNameTouched(true);
            return;
        }

        setLoading(true);

        const folder_id = selectedLibraryItem?.id || 0;
        if(folder_id === 0) {
            showToast('A folder should be selected.');
            return;
        }

        const response = await insertAuthor(
            folder_id,
            firstName.trim(),
            lastName.trim(),
        );

        setLoading(false);

        if(!response) {
            showToast('Insert author error.');
            return;
        }

        stopAddingAuthor();

        setLibraryData(formatLibraryData(response || []));
    }, [
        firstName, lastName,
        selectedLibraryItem?.id,
        setLibraryData,
    ]);

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Escape') {
            stopAddingAuthor();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <>
            {
                addAuthorMode === AddAuthorMode.DEFAULT &&
                <button
                    onClick={ startAddingAuthor }
                    type="button"
                    className="flex items-center text-sm mt-4 mb-10 text-slate-400">
                    <div className="mr-3"><PlusIcon /></div>
                    <div>Add Author</div>
                </button>
            }

            {
                addAuthorMode === AddAuthorMode.ADDING &&
                <div className="mt-4 mb-10">
                    <input
                        ref={ firstNameRef }
                        placeholder="First Name"
                        onInput={ onFirstNameChange }
                        value={ firstName }
                        onKeyDown={ onKeyDown }
                        className={ `bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none ${ isFirstNameValid ? '' : 'border-2 border-red-500' }` }
                        type="text"
                    />

                    {
                        !isFirstNameValid && <div className="text-red-500 text-xs mt-1">The first name is required.</div>
                    }

                    <input
                        placeholder="Last Name"
                        value={ lastName }
                        onInput={ onLastNameChange }
                        onKeyDown={ onKeyDown }
                        className={ `mt-2 bg-slate-100 text-slate-900 w-full rounded px-2 py-1 outline-none ${ isFirstNameValid ? '' : 'border-2 border-red-500' }` }
                        type="text"
                    />

                    <div className="flex items-center justify-end text-sm mt-4">
                        <button
                            className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                            onClick={ stopAddingAuthor }
                            type="button">Cancel</button>

                        {
                            isLoading &&
                            <PreloaderIcon size={ 24 } color={ '#717985' } />
                        }

                        {
                            !isLoading &&
                            <button
                                className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                onClick={ save }
                                type="button">Save</button>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default AddAuthor;