import { ITodo } from '../planner-interfaces';
import { addDays } from 'date-fns';

const collectTodosDates = (todo: ITodo, set: Set<number>) => {

    if(!todo) return;

    if(todo.todo_date) {
        set.add(todo.todo_date);
    }

    if(!todo.nested) return;

    for(const nestedTodo of todo.nested) {
        collectTodosDates(nestedTodo, set);
    }
};

export const predictNextEvent = (todo: ITodo) : Date|null => {
    if(!todo) return null;

    const dates = new Set<number>();
    collectTodosDates(todo, dates);

    const sorted = Array.from(dates).sort((date1, date2) => date1 - date2);
    if(sorted.length <= 0) return null;

    let sum = 0;
    for(let i=1; i<sorted.length; i++) {
        const days = (sorted[i] - sorted[i-1]) / (24*60*60*1000);
        sum += days;
    }

    const daysMean = sorted.length === 0 ? 0 : (sum / (sorted.length - 1));
    const lastDate = sorted[sorted.length - 1];

    return addDays(new Date(lastDate), daysMean);
};